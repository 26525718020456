/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext } from 'react';
import { Row, Col, Button, Card } from 'antd';
import ExperimentLayout from '../components/experimentLayout';
import ReactPlayer from 'react-player';
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
//const { Content } = Layout;

/*----====|| COMPONENT START || ====----*/
const Play = (props) => {
    const objUser = useContext(UserContext);
    //const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/

/*----====|| STATES || ====----*/
    //const [objTmp, fnUpdate] = useState({});
/*----====|| LOGIC || ====----*/

/*----====|| HOOKS || ====----*/
//useEffect(() => {},[]);
/*----====|| RENDER || ====----*/
return(
              <>
              {objUser.experiment &&
                  <ExperimentLayout history={props.history} step="play" 
                  next={ true} >
                      <Card
                        title={'Video From Space'}
                        extra={objUser.experiment.results[0].path}
                        actions={[<a href={ './processed/video/'+objUser.experiment.results[0].path }> <Button> Download Video</Button> </a>]}
                      >

                      <Row>
                        <Col span={24} align="center">
                        { objUser.experiment && objUser.experiment.results && objUser.experiment.results[0] &&
                        <>
                        
                            <ReactPlayer url={ './processed/video/'+objUser.experiment.results[0].path } controls={ true } loop={ true } width="787px" height="450px" playing />
                        
                        </>
                        // eslint-disable-next-line
                        }{ !objUser.experiment || !objUser.experiment.results || !objUser.experiment.results[0] &&
                        <center><h1>No results have returned yet</h1></center>
                        }
                        </Col>
                    </Row>
                    </Card>
                  </ExperimentLayout>
              }
              </>
);

};

export default Play;