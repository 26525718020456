import React, { useEffect, useState } from 'react';
import { Row, Col, Layout,message,notification,Modal, Form, Input, Button } from 'antd';
import axios from 'axios';
//import {UserContext} from '../context.js';
import queryString from 'query-string'
import {LockOutlined} from '@ant-design/icons';
import NotNavMenu from '../components/notnavmenu.js';
import {Config} from '../config.js';
/*----====|| INSTANCE CONSTANTS || ====----*/
const { Content, Header } = Layout;

const Email =function(props){
  //const context = useContext(UserContext);
  const [objTmp, fnUpdate] = useState({"modalPassword":false});
  const [objForm] = useState({
       "pw":''
      ,"confirm":''
  });

  const fnSetPassword=function(objForm){
    //e.preventDefault();
    //console.log(objForm.email, objForm.pw, objForm.confirm);
    if(objForm.pw.length > 3 && objForm.pw === objForm.confirm){
      //do it
      var objQuery=queryString.parse(props.history.location.search);
      axios.post(Config.api+'/api/setpw',{"email":objQuery.email, "code":objQuery.code, "pw":objForm.pw}).then(
        objResults=>{
          //console.log(objResults);
          if(objResults.data === 'password reset'){
            notification.open({
                message: 'Password reset successfully',
                description:
                  'The password was reset, activation codes removed and user set to active'
              });
            props.history.push('/login?verify=true');
          }
        }
      );
    }else{
      message.info('The password must be longer than 5 characters and be confirmed');
    }
  }
  const fnValidate=function(strField,strValue){
    var strError=false;
      if( ['first','last','email','pw','confirm'].indexOf(strField) > -1 && strValue.length === 0){ strError='Please fill in value'; }
      else if( strField==='pw' && strValue.length < 5){ strError='A password needs to be longer than 5 characters'; }
      else if( strField==='confirm' && strValue !== objForm.pw){ strError='Password confirmation doesn\'t match' ; }
      
      if(strError){ 
        //console.log('field error');
        fnUpdate({...objTmp,strField:strError});
        console.log("fnValidate",strField,strValue,strError);
        return strError;
      }else{ 
        console.log('field ok');
        objForm[strField]=strValue;
        fnUpdate({...objTmp,strField:false});
        return false;
      }
  }

  useEffect(() => {
    var objQuery=queryString.parse(props.history.location.search);
    //console.log('get Code:',objQuery);
    if(objQuery.code){
      let strType="users";
      if(objQuery.type==='promo'){strType=objQuery.type;}
      axios.post(Config.api+'/api/verify',{
        "email":objQuery.email, 
        "code":objQuery.code,
        "type":strType
      })
        .then(objResponse => {        
          console.log(objResponse);
          if(objQuery.action === 'setpw'){
            fnUpdate({...objTmp,"modalPassword":true});
          }else{ 
            if(objQuery.type==='promo'){
              props.history.push('/bmo');
            }else{
              props.history.push('/login?verify=true');
            }
          }
          
        }).catch(err=>{
          //console.log(err);
        });
    }
    
  },[objTmp, props.history]);
    return (
      <Layout>
      <Header>
        <NotNavMenu history={props.history} />
      </Header>
        <Content className="contentPane">
            <Row>
              <Col span={6} offset={9} className="container">
                <h2>Email verified</h2>
              </Col>
            </Row>
        </Content>
        <Modal
            title="Set Password"
            visible={objTmp.modalPassword}
            onCancel={ () => fnUpdate({...objTmp,"modalPassword":false}) }
            footer={null}
          >
          <Form onFinish={fnSetPassword} className="login-form">
              <Form.Item name="pw" label="Create Password*">
                  <Input prefix={ <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} /> } type="password" placeholder="" 
                  onBlur={e =>fnValidate('pw',e.target.value) } />
              </Form.Item>
              <Form.Item name="confirm" label="Confirm Password*">
                  <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="" 
                  onBlur={e =>fnValidate('confirm',e.target.value) } />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="fullWidth b" span={6}>
                  Set Password
                </Button>
              </Form.Item>
            </Form>
        </Modal>
        </Layout>
    );
}

export default Email;
