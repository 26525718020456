/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext,useEffect,useState } from 'react';
//import { Link } from 'react-router-dom'
import { Modal, Row, Col, Input, List, notification } from 'antd';
import exp from '../experimentHelper';
import axios from 'axios';
import _ from 'lodash';
import {Config} from '../config.js';
import SVG from 'react-inlinesvg';
import '../css/newExperiment.css'
/*----====|| CONTEXT || ====----*/
import {UserContext,ConfigContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
//const { Content } = Layout;

/*----====|| COMPONENT START || ====----*/
const NewExperiment = (props) => {
    const objUser = useContext(UserContext);
    const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/
const objNewExperiment={
    "show":true
    ,"steps":[]
    ,"lcd":'message'
    ,"sequence":[]
    ,"data":{
         "raw":[]
        ,"states":[]
        ,"stats":[]
    }
    ,"progress":{}
};
/*----====|| STATES || ====----*/
    const [objTmp, fnUpdate] = useState({
        name:'',
        type:'',
        location:''
    });
/*----====|| LOGIC || ====----*/
    const fnCreateExperiment=function(){
        
        let objNew={...objNewExperiment};
			objNew.name = objTmp.name;
			objNew.type = objTmp.type;
			objNew.location = objTmp.location;
			//look for steps by location
			//console.log(objNew);
            let objDecorated=exp.fnNextStep(objNew,'new',objConfig);
            axios.post(Config.api+'/api/expadd',{"d":{
                name: objTmp.name
               ,type: objTmp.type
               ,location: objTmp.location
               ,json:{
                   progress: objDecorated.experiment.progress,
                   sequence: objDecorated.experiment.sequence,
                   steps:[],
                   data:{...objNew.data}
                }
           }, token:objUser.jwt})
               .then(objResponse => {        
                //console.log(objResponse) 
                notification.open({message:'New experiment created and saved successfully'});
                objDecorated.experiment.id=objResponse.data.insertId;
                console.log(objDecorated);
                objUser.fnUpdate({
                     ...objUser,
                     experiment:{ ...objDecorated.experiment }
                 });
                 props.history.push(objDecorated.next);
               }).catch(err=>{});
    }
/*----====|| HOOKS || ====----*/
useEffect(() => {
    //console.log(objUser.experiment);
},[objUser.experiment]);
/*----====|| RENDER || ====----*/
return(
    < Modal 
        title="Create New Experiment"
        width="800px"
        okButtonProps={{
            disabled: !(objTmp.name && objTmp.location && objTmp.type)
        }}
        onOk={fnCreateExperiment}
        okText="Create Experiment"
        {...props.modal}
    >
        <Row>
            <Col span={12}>1. Give your experiment a name</Col>
            <Col span={12}>
                <Input
                    placeholder="Experiment Name"
                    defaultValue={objTmp.name}
                    onChange={ e=>{ fnUpdate({...objTmp, name:e.target.value}); }}
                    />
            </Col>
        </Row>
            <List 
                key="type"
                header="2. Choose an experiment type"
                dataSource={_.filter(objConfig.experiments,{"active":true})}
                grid={{column: 2}}
                rowKey="key"
				renderItem={item => (
					<List.Item
                        className={"expOptions " + (item.key === objTmp.type ? 'selected' : '') }
                        onClick={ ()=>fnUpdate({...objTmp,type:item.key}) }
                    >
                        <img style={{"height":'56px',"width":'56px', "minWidth":'56px'}} alt="" src={item.icon}></img>
						<div className="expOptDescription" >
							<div className="expOptTitle"> {item.title} </div>
							<div className="expOptDesc"> {item.desc} </div>
						</div>
                        {item.active && item.key !== objTmp.type && 
									        <SVG className="icon circleInactive" src="./img/icons/round-radio_button_unchecked-24px-2.svg"></SVG>
									    }
									    {item.key === objTmp.type &&
									    	<SVG className="icon circleInactive" src="./img/icons/round-radio_button_checked-24px.svg"></SVG>
									    }
					</List.Item>
				)}
            />
            <List 
                key="location"
                dataSource={objConfig.locations}
                grid={{column: 2}}
                header="3. Choose a location"
                rowKey="key"
				renderItem={item => (
					<List.Item 
                        className={"expOptions " + (item.key === objTmp.location ? 'selected' : '') + ( objTmp.type && _.find(objConfig.experiments,{key:objTmp.type}).locations.indexOf(item.key) > -1 ? '' : ' inactive')}
                        onClick={ ()=>fnUpdate({...objTmp,location:item.key}) }
                        >
									<img alt="" src={item.icon}></img>
									<div>
										<div className="expOptTitle"> {item.title} </div>
										<div className="expOptDesc"> {item.desc} </div>
									</div>
									{!item.disabled && item.key !== objTmp.location && 
								        <SVG className="icon circleInactive" src="./img/icons/round-radio_button_unchecked-24px-2.svg"></SVG>
								    }
								    {item.key === objTmp.location &&
								    	<SVG className="icon circleInactive" src="./img/icons/round-radio_button_checked-24px.svg"></SVG>
								    }
								</List.Item>
				)}
            />
    </Modal>
);

};

export default NewExperiment;