/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext } from 'react';
import { Row,Col, Card, Upload, Button, notification} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ExperimentLayout from '../components/experimentLayout';
//import RunGround from '../components/runGround';
//import RunSpace from '../components/runSpace';
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';


/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
const Papa = require("papaparse/papaparse.min.js")

/*----====|| COMPONENT START || ====----*/
const Data = (props) => {
    const objUser = useContext(UserContext);
    //const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/
const  uploadProps = {
    beforeUpload: objFile=>{ 
        if(objFile){
            Papa.parse(objFile, {
              "header":true, "skipEmptyLines":true, "trimHeaders":true,
              "complete": function(objResults) {
                if(objResults.data && objResults.data.length > 0){
                  var objExperiment = objUser.experiment;
                    if(!objExperiment.data ){ objExperiment.data={"states":[],"stats":[],"raw":objResults.data}; }
                      objExperiment.data.raw=objResults.data;
                      objUser.fnUpdate({...objUser,experiment:objExperiment});
                      notification.open({message:"data loaded"}); 
                  }
                }
            });
          }
    }
  };
/*----====|| STATES || ====----*/
    //const [objTmp, fnUpdate] = useState({});
/*----====|| LOGIC || ====----*/


/*----====|| HOOKS || ====----*/

/*----====|| RENDER || ====----*/
return(
    <ExperimentLayout history={props.history} step="data" next={objUser.experiment.data.raw.length > 0 || objUser.experiment.results.length > 0}>
        <Row>
            { objUser.experiment && objUser.experiment.location === 'Ground' &&
                <Col span={12} offset={6} align="center">
                    <Card 
                        title="Upload Experiment Data" 
                        style={{textAlign:'left'}}
                        extra={`${objUser.experiment.data.raw.length} records found`}
                        actions={[
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined />} >Upload Data File</Button>
                            </Upload>
                        ]}
                    >
                        { objUser.experiment.data.raw.length > 0 && 
                            <>
                                records are already stored for the experiment. You can override them by uploading another file<br/><br/>
                            </>
                        }
                        { !objUser.experiment.data.raw.length && 
                            <>
                                no records found for this experiment. <br/>Upload the files from the SD card to Quest for Space for analysis<br/><br/>
                            </>
                        }
                        
                    </Card>
                </Col>
            }{ objUser.experiment && objUser.experiment.location !== 'Ground' &&
                <Col span={12} offset={6} align="center">
                    <Card
                        title="Data from Space"
                        style={{textAlign:'left'}}
                    >
                        {!objUser.experiment.results.length &&
                            <h3>
                                The data hasn't come back in yet.<br/> This will update when it's ready
                            </h3>
                        }{objUser.experiment.results.length &&
                            <h3>
                                Data is loaded for this experiment and ready to be analyzed!<br/>
                                Check it out by clicking on the Next button.
                            </h3>
                        }
                    </Card>      
                </Col> 
            }
        </Row>
    </ExperimentLayout>
);

};

export default Data;