/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext,useEffect,useState } from 'react';
import { Layout, Input, Select, DatePicker, Button,Row,Col, Card, Table, Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import Nav from '../components/nav.js';
import {Config} from '../config.js';
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
const { Content, Header } = Layout;
const { Option } = Select;
/*----====|| COMPONENT START || ====----*/
const AdminPromo = (props) => {
    const objUser = useContext(UserContext);
/*----====|| DEFAULTS || ====----*/
const arrColumns=[
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title'
    },{
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },{
      title: 'Delivery',
      dataIndex: 'delivery',
      key: 'delivery'
    },{
      title: 'Expires',
      dataIndex: 'expires',
      key: 'expires'
    },{
      title: 'Experiments',
      dataIndex: 'count',
      key: 'count'
    }
    ,{
        title: 'act',
        key: 'act',
        render: (text, record) => {
            return(<Button icon={<DeleteOutlined/>} onClick={()=>{deletePromo(record.code)}} />);
        }
      }
];
/*----====|| STATES || ====----*/
const [objTmp, setTmp] = useState({
    promo_codes:[]
});
/*----====|| LOGIC || ====----*/
const getPromos = function(){
    axios.post(Config.api+'/api/promoList',{"token":objUser.jwt})
        .then(objResults => {        	          
          if(objResults !== null){
              setTmp( {
                promo_codes:objResults.data.promo_codes
            } );
          }
        }).catch(err=>{});
}

const deletePromo = function(code){
    axios.post(Config.api+'/api/promoDelete',{"token":objUser.jwt,"code":code})
        .then(objResults => {        	          
            getPromos();
        }).catch(err=>{});
}

const savePromo = function(v){
    if (!v.delivery){
        v.delivery="email";
    }
    axios.post(Config.api+'/api/promoAdd',{
        token:objUser.jwt,
        expires:v.expires.format("YYYY-MM-DD 00:00:00"),
        delivery:v.delivery,
        code:v.code,
        title:v.title,
        description:v.description
    }).then(objResults => {        	          
        getPromos();
    }).catch(err=>{});
}
/*----====|| HOOKS || ====----*/
useEffect(() => {
    if(objUser){
        if(objUser.jwt){
            getPromos();
        }else{
            console.log('no login?',objUser);
            props.history.push('/adminPromo');
        }
    }
},[objUser.jwt]);
/*----====|| RENDER || ====----*/
return(
    <Layout>
        <Header>
            <Nav/>
        </Header>
        <Content style={{padding:"12px"}}>
            <Row>
                <Col span={8}>
            <Card title="New Promo Code">
                    <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        name="promoForm"
                        onFinish={savePromo}
                    >
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: false, message: 'optional code title' }]}
                        >
                            <Input placeholder="Title" />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: false, message: 'optional' }]}
                        >
                            <Input placeholder="description" />
                        </Form.Item>
                        <Form.Item
                            label="Code"
                            name="code"
                            rules={[{ required: true, message: 'Input code' }]}
                        >
                            <Input placeholder="Code" />
                        </Form.Item>
                        <Form.Item
                            label="Delivery"
                            name="delivery"
                            rules={[{ required: false, message: 'Select delivery' }]}
                        >
                            <Select defaultValue={"email"} >
                                <Option value="email">email</Option>
                                <Option value="vimeo">vimeo</Option>
                                <Option value="both">both</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Expires"
                            name="expires"
                            rules={[{ required: true, message: 'Select date' }]}
                        >
                            <DatePicker /> 
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Save Promo
                            </Button>
                        </Form.Item>
                    </Form>
                    </Card>
                    </Col>
                    <Col span={16}>
                        <Card title="Existing Promo Codes">
                            <Table rowKey="code" dataSource={objTmp.promo_codes} columns={arrColumns} />
                        </Card>
                    </Col>
                    </Row>
        </Content>
    </Layout>
);

};

export default AdminPromo;