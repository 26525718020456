/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext,useEffect,useState } from 'react';
import { Button, Row, Col, Input, Select, message, Card, Tooltip, Modal } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { DeleteFilled } from '@ant-design/icons';
import SVG from 'react-inlinesvg';
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/
import "../css/codeConfig.css";

/*----====|| INSTANCE CONSTANTS || ====----*/
const Option = Select.Option;
const InputGroup = Input.Group;
const arrActions=[
    {"value":'delay',"label":'Delay'}
   ,{"value":'on',"label":'Turn on'}
   ,{"value":'off',"label":'Turn off'}
];
const DragHandle = sortableHandle(() => <div className="handleMe">::</div>);

/*----====|| COMPONENT START || ====----*/
const CodeConfig = (props) => {

    const objUser = useContext(UserContext);
   // const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/
const objDefaultStep={
    action:'delay',
    detail:0,
    showTemplates:false
}

/*----====|| STATES || ====----*/
    const [objTmp, fnUpdate] = useState({
        steps:[],
        seconds:0
    });
/*----====|| LOGIC || ====----*/
const fnRemoveStep=function(intIndex){
	let arrSteps=objTmp.steps;
	arrSteps.splice(intIndex,1);
	const arrSeconds=fnCountSeconds(arrSteps);
    fnUpdate({
        ...objTmp,
        steps:arrSteps,
        seconds:arrSeconds[0]
    });
    fnCallback(arrSteps);
};
const fnResort=function({oldIndex,newIndex}){
    let arrSteps=objTmp.steps;
	let objStep = arrSteps[oldIndex];
    arrSteps.splice(oldIndex,1);
    arrSteps.splice(newIndex,0,objStep);
	fnUpdate({...objTmp,steps:arrSteps});
    fnCallback(arrSteps);
}
const fnAddStep=function(){
        const arrSteps=[
            ...objTmp.steps,
            {...objDefaultStep}
        ];
        const arrSeconds=fnCountSeconds(arrSteps);
        fnUpdate({
            ...objTmp,
            steps:arrSteps,
            seconds:arrSeconds[0]
        });
        fnCallback(arrSteps);
    }
    const fnUpdateStep=function(strField,strValue,intIndex){
        //console.log(strField,strValue,intIndex);
        var arrSteps=objTmp.steps;
        var objStep=arrSteps[intIndex];
        if( strField === 'action'){
            if(strValue === 'delay'){ objStep.detail=1; }
            if( ['on','off'].indexOf(strValue) > -1 && objUser.experiment.type==='Radiation'){ objStep.detail='bulb'; }
            if( ['on','off'].indexOf(strValue) > -1 && ['Conduction','Convection'].indexOf(objUser.experiment.type) > -1){ objStep.detail='resistor'; }
        }

        objStep[strField]=strValue;
        arrSteps[intIndex]=objStep;
        var arrSeconds=fnCountSeconds(arrSteps);
        if( arrSeconds[2].length > 0 ){
            for(var i=0;i<arrSeconds[2].length;i++){
                message.error(arrSeconds[2][i]);
            }
        }else{
            fnUpdate({...objTmp, "steps":arrSteps,"seconds":arrSeconds[0],"heating":arrSeconds[1]});
            fnCallback(arrSteps);
        }
    };
    const fnCountSeconds=function(arrSteps){
        var intSeconds=0;
        var intHeating=0;
        var intSubHeating=0;
        var intSubCooling=0;
        var torfHeating=false;
        var arrErrors=[];
        for(var i=0; i < arrSteps.length; i++){
            if(arrSteps[i].action==='delay'){ 
                var intDetail=parseInt(arrSteps[i].detail);
                intSeconds=intSeconds+ intDetail;}
            if(arrSteps[i].action==='delay' && torfHeating===true){ 
                intHeating=intHeating+ intDetail; 
                intSubHeating=intSubHeating+ intDetail;
                //any heating period over 120 seconds total?
                if( intSubHeating > 120 ){arrErrors.push('No heating period can be over 120 seconds total')}	
            }else{
                intSubCooling=intSubCooling+intDetail;
            }
            if(arrSteps[i].action==='on' && ['bulb','resistor'].indexOf(arrSteps[i].detail) > -1 ){ 
                //cooling period must be 50% of previous heating period
                if( intSubHeating > 0 && intSubCooling < intSubHeating*0.5){
                    arrErrors.push('Each cooling period must be at least 50% of the time of the previous heating period');
                }
                if(torfHeating===false){ intSubHeating=0; }
                torfHeating=true; 
    
            }
            if(arrSteps[i].action==='off' && ['bulb','resistor'].indexOf(arrSteps[i].detail) > -1){ 
                if(torfHeating===true){ intSubCooling=0; }
                torfHeating=false;
            }
        }
        //console.log(intSeconds,intHeating,arrErrors);
        return [intSeconds,intHeating,arrErrors];
    }
    const SortableContainer = sortableContainer(({children}) => {
        return <div>{children}</div>;
      });
    const SortableItem = sortableElement(({value:objStep, sortIndex:intIndex}) => (
        <Row className="stepRow">
            <Col span={1} className="stepHandle" align="center">
                <DragHandle />
            </Col>
            <Col span={1} className="stepIndex" align="center">
                {intIndex+1}
            </Col>
            <Col span={17}>
            <InputGroup compact>
                { objStep &&
                    <>
                        <Select 
                            defaultValue={objStep.action} 
                            style={{ width:100 }} 
                            onChange={ v => fnUpdateStep('action',v,intIndex) } 
                            options={arrActions}
                        />
                        { objStep.action !== 'delay' &&
                            <Select defaultValue={objStep.detail} style={{ width:150 }} onChange={ v => fnUpdateStep('detail',v,intIndex) } >
                                { objUser.experiment.type === 'Radiation' &&
                                    <Option key="bulb" value="bulb">Bulb</Option>
                                }{ objUser.experiment.type !== 'Radiation' &&
                                    <Option key="resistor" value="resistor">Resistor</Option>
                                }
                                <Option key="fan" value="fan">Fan</Option>
                            </Select>
                        }{ objStep.action === 'delay' &&
                            <Input defaultValue={objStep.detail} style={{ width:150 }} type="number" onBlur={ e => fnUpdateStep('detail',e.target.value,intIndex)} />
                        }
                    </>
                }
            </InputGroup>
        </Col>
        <Col span={1} align="right" className="stepActions">
            <Tooltip title="remove step">
                <DeleteFilled onClick={ ()=>fnRemoveStep(intIndex) } />
            </Tooltip>
        </Col>
    </Row>        
      ));

const fnCallback=function(arrSteps){
    if(props.callback){
        props.callback(arrSteps);
    }
}

const fnGetTemplates=function(){
	//var strComponent = 'bulb';
	return(
		<div className="templates">
		<div className={"expOptions " + (objTmp.template === 'long' ? 'selected' : '')} onClick={ () => fnUpdate({...objTmp,template:'long'}) } >
			<div className="expOptTitle">{objUser.experiment.type} with intense heat</div>
			<div className="expOptDesc">Keep the heat source on for a longer period of time</div>
			{objTmp.template !== 'long' && 
				<SVG className="icon circleInactive" src="../img/icons/round-radio_button_unchecked-24px-2.svg"></SVG>
				    }
			{objTmp.template === 'long' &&
				<SVG className="icon circleInactive" src="../img/icons/round-radio_button_checked-24px.svg"></SVG>
			}
		</div>
		<div className={"expOptions " + (objTmp.template === 'short' ? 'selected' : '')} onClick={ () => fnUpdate({...objTmp,template:'short'}) } >
			<div className="expOptTitle">{objUser.experiment.type} with mild heat</div>
			<div className="expOptDesc">Keep the heat source on for a shorter period of time</div>
			{objTmp.template !== 'short' && 
				<SVG className="icon circleInactive" src="../img/icons/round-radio_button_unchecked-24px-2.svg"></SVG>
				    }
			{objTmp.template === 'short' &&
				<SVG className="icon circleInactive" src="../img/icons/round-radio_button_checked-24px.svg"></SVG>
			}
		</div>
		<div className={"expOptions " + (objTmp.template === 'interval' ? 'selected' : '')} onClick={ () => fnUpdate({...objTmp,template:'interval'}) }>
			<div className="expOptTitle">{objUser.experiment.type} with intervals</div>
			<div className="expOptDesc">Turn the heat source on and off multiple times</div>
			{objTmp.template !== 'interval' && 
				<SVG className="icon circleInactive" src="../img/icons/round-radio_button_unchecked-24px-2.svg"></SVG>
				    }
			{objTmp.template === 'interval' &&
				<SVG className="icon circleInactive" src="../img/icons/round-radio_button_checked-24px.svg"></SVG>
			}
		</div>
		</div>
	);
};
const fnApplyTemplate=function(){
	console.log('go')
    var strComponent='resistor';
	if(objUser.experiment.type === 'Radiation'){ strComponent='bulb'; }
	var intDelay=30; var intCool=60;
	if( objTmp.template === 'long'){ intDelay=60; }
	if( objTmp.template === 'interval'){ intDelay=10;intCool=10; }
	var arrSteps=[{
		 "action":'on'
		,"detail":strComponent
	},{
		 "action":'delay'
		,"detail":intDelay
	},{
		 "action":'off'
		,"detail":strComponent
	},{
		 "action":'delay'
		,"detail":intCool
	}];	
	if( objTmp.template === 'interval'){
		arrSteps=[{
			 "action":'on'
			,"detail":strComponent
		},{
			 "action":'delay'
			,"detail":intDelay
		},{
			 "action":'off'
			,"detail":strComponent
		},{
			 "action":'delay'
			,"detail":intCool
		},{
			 "action":'on'
			,"detail":strComponent
		},{
			 "action":'delay'
			,"detail":intDelay
		},{
			 "action":'off'
			,"detail":strComponent
		},{
			 "action":'delay'
			,"detail":intCool
		},{
			 "action":'on'
			,"detail":strComponent
		},{
			 "action":'delay'
			,"detail":intDelay
		},{
			 "action":'off'
			,"detail":strComponent
		},{
			 "action":'delay'
			,"detail":intCool
		}];
	};
	for(var i=0;i < arrSteps.length;i++){
//react complains without unique IDs
		arrSteps[i].id=objTmp.template+'_'+i;
	}
	var arrSeconds=fnCountSeconds(arrSteps);
	fnUpdate({ ...objTmp, "steps": arrSteps, "newExperiment":false, "modalTemplate":false, "modalSure":false, "seconds":arrSeconds[0],"heating":arrSeconds[1], showTemplates:false});
    props.callback(arrSteps);
};
/*----====|| HOOKS || ====----*/
useEffect( ()=>{
    fnUpdate({
        ...props.data,
        steps:props.data.steps?props.data.steps:[]
    })
    // eslint-disable-next-line
},[] );

/*----====|| RENDER || ====----*/
return(
    <Card 
        size="small"
        title="configure experiment"
        extra={<Button onClick={ fnAddStep } size="small">Add Step</Button>}
        actions={[
            <div className={ (objTmp.seconds < 301 ? 'blueboxSubText' : 'codeHighlight') }> {objTmp.seconds} of 300 seconds total </div>,
            <Button size="small" type="primary" onClick={ () => fnAddStep()  } >Add step</Button>
        ]}
    >
        <SortableContainer 
            onSortEnd={fnResort} 
            useDragHandle
        >
            { objTmp.steps && objTmp.steps.length > 0 && objTmp.steps.map( (objStep,intIndex)=>(
                <>
                <SortableItem 
                    key={`item-${objStep.action}:${objStep.detail}`} 
                    index={intIndex} 
                    sortIndex={intIndex}
                    value={objStep} 
                /> 
                </>
            ))}{ !objTmp.steps.length &&

                 <Row>
                    <Col span={12}>
                    You can get started with pre configured templates, or start adding steps:
                    </Col>
                    <Col span={12} align="center">
                    <Button onClick={ ()=>fnUpdate({...objTmp,showTemplates:true}) }>See Templates</Button>
                    </Col>
                 </Row>

            }
        </SortableContainer>
        <Modal 
            title="Experiment Templates" 
            visible={objTmp.showTemplates} 
            onCancel={()=>fnUpdate({...objTmp,showTemplates:false})} 
            onOk={ ()=>{ fnApplyTemplate() } }
            >
        <div>Add and customize any template: </div>
	    	{fnGetTemplates()}
        </Modal>
    </Card>
);

};

export default CodeConfig;