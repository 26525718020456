/*----====|| LIBRARY IMPORTS || ====----*/
//import React, { useContext,useEffect,useState } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import Highlighter from "react-highlight-words";

/*----====|| CONTEXT || ====----*/
//import {UserContext,ConfigContext} from '../context.js';

/*----====|| STYLE IMPORTS || ====----*/
import "../css/codePreview.css";
/*----====|| INSTANCE CONSTANTS || ====----*/
//const { Content } = Layout;

/*----====|| COMPONENT START || ====----*/
const CodePreview = (props) => {
    //const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/

/*----====|| STATES || ====----*/
    //const [objTmp, fnUpdate] = useState({});
/*----====|| LOGIC || ====----*/
const fnStr2Clipboard=function(text){
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value=text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}
const fnGetCodeBlock=function(strBlock, strMode){
    let arrInit=props.codeMap[strBlock];
    if( props.codeMap[props.data.type.toLowerCase()] && props.codeMap[props.data.type.toLowerCase()][strBlock] ){ arrInit=props.codeMap[props.data.type.toLowerCase()][strBlock]; }
    if(arrInit){
        if(strMode === 'copy'){
            let strCopy='';
            arrInit.forEach((objCode)=>{
                if(objCode.comment){ strCopy=strCopy+`// ${objCode.comment} \n`; }
                if(objCode.code){ strCopy=strCopy+`${objCode.code} \n`; }
            })
            return strCopy;
        }else{
            return arrInit.map((objCode)=>( 
                <>
                    { objCode.comment &&
                        <div className="codeComments">{`// ${objCode.comment}`}</div>
                    }{ objCode.code &&
                        <div className={objCode.className?objCode.className:'codePreview'}>
                        {objCode.code} <br/>
                        </div>
                    }
                </>
            ))
        }
    }
    
}

const fnGetStep=function(objStep,intIndex, strMode){
    if (objStep){
        let objCode={};
        let arrReturn=[];
        let strCode= '';
        let arrLowLights=[];
        var strCopy='';
        //action level code/comments , detail is not needed, probably a param
        if(props.codeMap[objStep.action].code || props.codeMap[objStep.action].comment){
            objCode=props.codeMap[objStep.action];
            if(props.codeMap[props.data.type.toLowerCase()] && props.codeMap[props.data.type.toLowerCase()][objStep.action] && props.codeMap[props.data.type.toLowerCase()][objStep.action].code){
                objCode=props.codeMap[props.data.type.toLowerCase()][objStep.action];
            }
        }else{
            //code defined by action+detail
            objCode=props.codeMap[objStep.action][objStep.detail];
            if( props.codeMap[props.data.type.toLowerCase()] && props.codeMap[props.data.type.toLowerCase()][objStep.action] && props.codeMap[props.data.type.toLowerCase()][objStep.action][objStep.detail] ){ objCode=props.codeMap[props.data.type.toLowerCase()][objStep.action][objStep.detail]; }
        }
        if(!objCode){ console.log('no code found', objStep); }
        let strComment = `// step  ${intIndex+1}`;
        if(objCode.comment){ strComment=`${strComment} : ${objCode.comment}`; }
        arrReturn.push( <div className="codeComments">{strComment}</div> );
        strCopy=strCopy + `\t` + strComment + `\n`;
        //get the code string to display
        if(objCode.code && typeof objCode.code === 'function' ){
            strCode=objCode.code(objStep)
        }else if(objCode.code && typeof objCode.code !== 'function'){
            strCode=objCode.code;
        }
        if(strCode){
            if(props.codeMap.lowLights && props.codeMap.lowLights.length > 0){ arrLowLights=props.codeMap.lowLights; }
            if(props.codeMap[props.data.type.toLowerCase()]?.lowLights && props.codeMap[props.data.type.toLowerCase()].lowLights.length > 0){ arrLowLights=props.codeMap[props.data.type.toLowerCase()].lowLights; }
            arrReturn.push(<div className={objCode.className?objCode.className:'codePreview'}>
                <Highlighter
                    highlightClassName="codeLowLight"
                    searchWords={arrLowLights}
                    autoEscape={true}
                    textToHighlight={strCode}
                />
            </div>)
            strCopy=strCopy + `\t` + strCode + `\n`;
        }
        if(strMode==='copy'){ return strCopy;}
        else{ return arrReturn; }        
    }
}
const fnGetCode=function(strMode){
    if(strMode==='copy'){
        let strCode = fnGetCodeBlock('init',strMode);
        props.data.steps.forEach((objStep,intIndex)=>{
            strCode = strCode + fnGetStep(objStep,intIndex,strMode);
        })
        strCode = strCode + fnGetCodeBlock('end',strMode);
        return strCode;
    }else{
        return(
            <>
                {fnGetCodeBlock('init',strMode)}
                <div className="codeBlock">
                { props.data.steps && props.data.steps.map((objStep,intIndex)=>(
                    <>
                        {fnGetStep(objStep,intIndex,strMode)}
                    </>
                ))}
                </div>
                {fnGetCodeBlock('end',strMode)}
            </>
        )
    }
}

/*----====|| HOOKS || ====----*/
//console.log(props.codeMap,props.data.type)
/*----====|| RENDER || ====----*/
return(
    <Card 
        size="small"
        title="code preview"
        className="codePreview"
        extra={<>
            copy code to clipboard <CopyOutlined 
                onClick={ ()=>{ fnStr2Clipboard( fnGetCode('copy') ); } }
            />
        </>}
    >
        {fnGetCode('preview')}
    </Card>
);

};

export default CodePreview;