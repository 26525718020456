import _ from 'lodash';
import axios from 'axios';
import {Config} from './config.js';
const exp={ 
	"fnNextStep":(objExperimentIn,strCompleteStep,objConfig)=>{
        let objPreviousStep=null;
		let objExperiment={...objExperimentIn};
		if(objExperiment.next && objExperiment.experiment){ objExperiment=objExperiment.experiment; }
		if(strCompleteStep==='new' || !objExperiment.sequence.phases){ objExperiment=exp.fnGetSequence(objExperiment,objConfig); }
        for(let i=0;i<objExperiment.sequence.phases.length;i++){
            let objPhase=objExperiment.sequence.phases[i];
            for(let ii=0;ii<objPhase.steps.length;ii++){
                let objStep=objPhase.steps[ii];
				//first we need to see if this is the step that is needing to be set as complete
				if(strCompleteStep && strCompleteStep === objStep.id ){
					objStep.complete=Date.now();
					//if this is the last step in a phase set progress to complete
					if(ii === objPhase.steps.length-1 ){
						console.log("complete progress",objPhase.name);
						objExperiment.progress[objPhase.name]=Date.now();
						//at the end of everything it's all complete, so go to mission control
						if(i === objExperiment.sequence.phases.length-1){
							//last phase
							return { next:'/mission',experiment:objExperiment }
						}
					}
				}
				//set current step as the previous step if complete cuz we'll look at the next one
                if(!objPreviousStep || objStep.complete){ objPreviousStep=objStep; }
				//found the first step that isn;t complete
                if(!objStep.complete){ return { next:objStep.path,experiment:objExperiment } }
				//this is complete, send to first step
				if(i===objExperiment.sequence.phases.length-1 && ii===objPhase.steps.length-1 && objStep.complete){
					return { 
						next:objExperiment.sequence.phases[0].steps[0].path,
						experiment:objExperiment }
				}
            }
        }
    },
    "fnGetSequence":(objExperiment,Config)=>{
		let objConfig=exp.fnGetConfig(objExperiment,Config);
		let arrSequence=[];
		if(objConfig.sequence.default){ arrSequence = [...objConfig.sequence.default]; }
		else{ arrSequence = [...objConfig.sequence[Object.keys(objConfig.sequence)[0]]]; }
		if( objConfig.sequence[objExperiment.location] ){ arrSequence=[...objConfig.sequence[objExperiment.location]]; }
		for(let i=0;i<arrSequence.length;i++){
			//phases loop
			for(let ii=0;ii<arrSequence[i].steps.length;ii++){
				//steps look, replace strings with objects
				let strStepId=arrSequence[i].steps[ii];
				if(Config.steps[ strStepId ]){
					arrSequence[i].steps[ii]={...Config.steps[ strStepId ]};
					arrSequence[i].steps[ii].id=strStepId;
				}
			}
		}
		let objExperimentOut={ 
			...objExperiment,
			sequence:{
				phases: arrSequence
			}
		};
		//add progress if it doesnt exist, helpful in the new experiment
		if(!objExperimentOut.progress || !Object.keys(objExperimentOut.progress).length){
			objExperimentOut.sequence.phases.forEach( (objPhase)=>{
				objExperimentOut.progress[objPhase.name]=false;
			} )
		}
		objExperimentOut.sequence.phases.forEach( (objPhase)=>{
			objPhase.steps.forEach( (objStep)=>{
				objStep.complete=false;
			} );
		} );
		console.log('sequence created',objExperimentOut);
		return objExperimentOut;
    },
	"fnGetConfig":(objExperiment,Config)=>{
		if(objExperiment.next && objExperiment.experiment){objExperiment=objExperiment.experiment;}
		//get the base object
		let objConfig=_.find(Config.experiments,{"key":objExperiment.type});
		//if it references a template use that first
		if(objConfig.template){
			let objTemplate = Config.experimentTemplates[objConfig.template];
			//stepCopy needs a deeper reference
			let objCopy={};
			if(objConfig.stepCopy){ objCopy=objConfig.stepCopy; }
			if(objTemplate.stepCopy){ objCopy={...objCopy,...objTemplate.stepCopy};}
			objConfig={
				...objTemplate,
				...objConfig,
				stepCopy:objCopy
			};
		}
		return objConfig;
	},
	"fnLocalStore":(objSaveMe)=>{
		if(objSaveMe && objSaveMe.jwt){
			localStorage.clear();
			localStorage.setItem('user', JSON.stringify(objSaveMe));
		}
	},"fnSave":(objUser)=>{
		if(objUser){
		var objStore={
		   "id":objUser.experiment.id
		  ,"name":objUser.experiment.name
		  ,"json":{
			 "progress": objUser.experiment.progress
			,"data": objUser.experiment.data
			,"steps": objUser.experiment.steps
			,"questions": objUser.experiment.questions
			,"sequence": objUser.experiment.sequence
		  }
		  ,"status":'In Progress'
		  ,"phase":'Build'
		  ,"lcd":objUser.experiment.lcd
		};
		axios.post(Config.api+'/api/expupdate',{"d":objStore, "token":objUser.jwt})
		  .then(objResponse => {        
			//console.log('experiment updates saved to the database');
		  }).catch(err=>{
			//console.log(err);
		  });
	  }
	}
};
export default exp;