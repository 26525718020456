import React, { useContext } from "react";
import {UserContext} from '../context.js';
//import { useMsal } from "@azure/msal-react";
import { Menu, Dropdown, Tag, Button } from 'antd';
import { Link } from 'react-router-dom';
import {DownOutlined} from '@ant-design/icons';

const NavProfile = (props) => {
  //const { instance, accounts } = useMsal();
  const objUser = useContext(UserContext);
  //const objConfig = useContext(ConfigContext);
  
  const fnLogout = () => {
    //instance.logout()
    localStorage.clear();
    objUser.fnUpdate({});
    if(props.history){ props.history.push('/'); }
    else{ console.log('cant find history object')}
    
  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to="/profile">Account settings</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/credits">Add credits</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3"><Link to="/" onClick={fnLogout} >Logout</Link></Menu.Item>
    </Menu>
  );

  return <>
    { objUser.username &&
      <>
      <Link to="/credits" > <Tag color="#657AE1" >{objUser.user.credits} credits available </Tag> </Link>
      <Dropdown overlay={menu}>
        <span className="ant-dropdown-link b"  >
          {objUser.username} <DownOutlined />
        </span>
      </Dropdown>
      </>
    }{ !objUser.username &&
      <div>  Already have an account? <Link to="/register" className="linkText" > Sign up here</Link> or <Button type="primary" onClick={fnLogout}> Login </Button></div>
    }
  </>;
};

export default NavProfile;