/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext,useEffect,useState } from 'react';
import { Button, Modal, Form, Input, Row, Col, Layout, message } from 'antd';
import {UserOutlined,LockOutlined} from '@ant-design/icons';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import {Config,SSO, authContext} from '../config.js';
import { runWithAdal } from 'react-adal';
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
const { Content, Header } = Layout;
/*----====|| COMPONENT START || ====----*/
const SSOPage = (props) => {
  const objUser = useContext(UserContext);
  //const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/

/*----====|| STATES || ====----*/
  const [objTmp, fnUpdate] = useState({
    "showLogin":false
  });
/*----====|| SSO || ====----*/
const { instance, accounts } = useMsal();
const SignInSignOutButton = () => {
  return (
      <>
          <AuthenticatedTemplate>
            <Button variant="secondary" onClick={() => instance.logout()} className="ml-auto">Sign Out</Button>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Button onClick={() => instance.loginPopup({scopes: ["User.Read"]})}>Azure AD Single Sign On</Button>
          </UnauthenticatedTemplate>
      </>
  );
};

let location = useLocation();
console.log(location.pathname)
// SSO
useEffect(() => {
  console.log('ssoaccounts',accounts);
  if(accounts.length > 0 && objUser.jwt && !objUser.username){
    axios.post(Config.api+'/api/ssoLogin',{"d":accounts[0],"token":objUser.jwt})
      .then(objResponse => {        
        fnAfterLogin(objResponse)
      });
  }
  //teams login
  if(objUser.jwt && !objUser.username){
    runWithAdal(authContext, () => {
      // TODO : continue your process
      let user = authContext.getCachedUser();
      if (user) {        
        console.log(user);
        axios.post(Config.api+'/api/ssoLogin',{"d":{
          username:user.userName,
          name:user.profile.name
        },"token":objUser.jwt})
        .then(objResponse => {        
          fnAfterLogin(objResponse)
        });    
      }
      else {
          // Initiate login
          // authContext.login();        
        console.log('getCachedUser() error');
      }
  
      var token = authContext.getCachedToken(SSO.teams.clientId)
      if (token) {        
        console.log(token);
      }
      else {        
        console.log('getCachedToken() error');       
      }
     }, false);
  }
  // eslint-disable-next-line
},[accounts, objUser.jwt]);
/*----====|| LOGIC || ====----*/
  const fnAfterLogin=function(objResponse){
    let arrBadges = ["welcome"];
        if(objResponse.data.user.json){ var objJson=JSON.parse(objResponse.data.user.json); }
        if(objJson && objJson.badges && objJson.badges.length > 1){ arrBadges=objJson.badges; }
        let objUserData={"username":objResponse.data.user.first+' '+objResponse.data.user.last,"user":objResponse.data.user, "badges":arrBadges ,"jwt":objResponse.data.token,"role":objResponse.data.user.role};
        objUser.fnUpdate(objUserData);
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(objUserData));
        props.history.push('/mission');
  }
  const fnLogin=function(objForm){
    if(objForm.un.length > 0 && objForm.pw.length > 0){
      axios.post(Config.api+'/api/login',objForm)
      .then(objResponse => fnAfterLogin(objResponse)).catch(err=>{
        message.error('Something is wrong with your credentials, please try again or click "forgot password"');
      });
    }
  }
/*----====|| HOOKS || ====----*/
useEffect(() => {
  if(objUser && !objUser.jwt){
    axios.get(Config.api+'/api/getToken')
      .then(objResponse => {        
        objUser.fnUpdate({...objUser,"jwt":objResponse.data.token})
      }).catch(err=>{
        console.log(err);
      });
  }
},[objUser]);

useEffect(() => {
  if(objUser && objUser.username){
    props.history.push('/mission');
  }
},[objUser, objUser.username, props.history]);
/*----====|| RENDER || ====----*/
return(
  <>
    <Header 
      className="notnavhead" style={{ position: 'fixed', zIndex: 1, width: '100%' }}
    >
      <Row>
          <Col span={12}>
          	<Link to="/"><img id="logo"alt="Quest for Space" src="./img/QuestForSpaceWhite.svg"></img></Link>
          </Col>
          { !objUser.username &&
            <Col span={12} className="rightContent">
              <Link to="/schools"><Button className="notnav-btn" type="link" > Schools </Button></Link>
              <Button className="notnav-btn" type="link"  onClick={ ()=>fnUpdate({...objTmp,"showLogin":true})  }> Login </Button>
              <Button href="/register" type="primary" style={{"fontWeight":'bold'}}> Signup</Button>
            </Col>
          }{ objUser.username &&
            <Col span={12} className="rightContent">
              <Link to="/schools"><Button className="notnav-btn" type="link"  > Schools </Button></Link>
              <Button className="notnav-btn" type="link"  onClick={ ()=>fnUpdate({...objTmp,"showLogin":true})  }> Logout </Button>
              <Button href="/mission" type="primary" style={{"fontWeight":'bold'}}> Mission Control</Button>
            </Col>
          }
        </Row>
    </Header>
    <Content>
      <Row className="hero" align="bottom" type="flex" style={{background: "url('/img/Header.svg')" }}>
        <Col className="hero-msg" span={12}> 
          <h1>Inspiring future changemakers through space exploration</h1>
          <br/><br/>
          <Button href="/register" type="primary"> Signup</Button>
        </Col>
        <Col span={12} align="right"> 
          <img alt="girls in tech" src="./img/Girl.png" />
        </Col>
      </Row>
      <Row type="flex" justify="space-around" align="middle">
        <Col className="blueRow" span={24}>
          <Row type="flex" justify="space-around" align="middle" className='landingLarge'>
            <Col>Create, launch and run science experiments on earth and <br/>in micro-gravity aboard the International Space Station (ISS).</Col>
          </Row><Row type="flex" justify="space-around" align="middle">
            <Col span={8} align="middle">
              <img alt="hand" className="landingFeature" src="../img/hand.png" />
              <div className="landingFeatureLabel">Hands on science</div>
              <div>Create and build custom science experiments.</div>
            </Col>
            <Col span={8} align="middle">
              <img alt="bulb" className="landingFeature" src="../img/bulb.png" />
              <div className="landingFeatureLabel">Project based learning</div>
              <div>Develop critical thinking and problem solving skills.</div>
            </Col>
            <Col span={8} align="middle">
              <img alt="blocks" className="landingFeature" src="../img/blocks.png" />
              <div className="landingFeatureLabel">Buildable skills</div>
              <div>Learn a foundation of STEM basics to build upon.</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='landingWhite' type="flex" justify="space-around" align="middle" >
        <h1>Learning real world STEM skills</h1>
      </Row>
      <Row className='landingWhite' type="flex" justify="space-around" align="middle" >
        <Col span={12} className="pad40">
          <h1>Research physical science</h1>

          Use the scientific method to investigate the thermodynamics <br/>of heat transfer in gravity (earth) VS microgravity (space).

          <ul>
            <li>Learn to think critically</li>
            <li>Conduct 3 experiments: radiation, convection & conduction</li>
          </ul>
        </Col>
        <Col span={12} align="middle"> <img alt="science" src="../img/landingScience.png" /> </Col>
      </Row>
      <Row className='landingWhite' type="flex" justify="space-around" align="middle" >
        <Col span={12} align="middle"> <img alt="build" src="../img/landingBuild.png" /> </Col>
        <Col span={12} className="pad40">
          <h1>Build hardware</h1>

          Assemble entry level hardware and electronics for each experiment type. <br/> Easy to follow instructions allow kids to <br/>take charge and build confidently.

          <ul>
            <li>Develop motor skills</li>
            <li>Practice following instructions</li>
          </ul>
        </Col>
      </Row>
      <Row className='landingWhite' type="flex" justify="space-around" align="middle" >
        <Col span={12} className="pad40">
          <h1>Create code</h1>

          Learn to read and write code by programing experiment steps <br/> to be run on your hardware kit <br/>or launched into space to be run on the ISS. 

          <ul>
            <li>Learn computer programming basics</li>
            <li>Customize all your experiments</li>
          </ul>
        </Col>
        <Col span={12} align="middle"> <img alt="code" src="../img/landingCode.png" /> </Col>
      </Row>
      <Row className="darkBlue" type="flex" justify="space-around" align="middle">
        <Col span={12} align="middle">
          <h1>Welcome to space!</h1>
          <h2>Explore core science concepts <br/>in the International Space Station.</h2>
        </Col>
        <Col span={12} align="right">
          <img alt="astronaut" className="landingImg" src="../img/landingAstronaut.png" />
        </Col>
      </Row>
      <Row className="darkBlue" type="flex" justify="space-around" align="middle">
        <Col span={8}>
          <img alt="landing rocket" className="landingFeature" src="../img/landingRocket.png" />
          <div className="landingFeatureLabel">Launch your experiment to the ISS</div>
          Run science experiments using <br/>actual hardware located on the ISS.
        </Col>
        <Col span={8}>
          <img alt="chart" className="landingFeature" src="../img/landingChart.png" />
          <div className="landingFeatureLabel">Collect real data from space</div>
          Analyze real data sets and observe micro-gravity's impact<br/> on radiation, conduction and convection.
        </Col>
        <Col span={8}>
          <img alt="action" className="landingFeature" src="../img/landingAction.png" />
          <div className="landingFeatureLabel">See real footage of the ISS</div>
          Create a personal message, send it to space <br/>and see real footage of the ISS with your message.
        </Col>
      </Row>
      <Row type="flex" justify="space-around" align="middle">
        <Col span={12} align="middle" className="blueRow">
          <img alt="badges" src="../img/landingBadges.png" />
        </Col>
        <Col span={12} className="pad40">
          <h1>How it works</h1>
          <ol>
            <li>
              <h2>Prep & build experiment</h2>
              Following the step-by-step manual to construct your kit with electrical engineering components <br/>and program 1 of the 5 offered experiments in the LEGO Mindstorms software. 
            </li>
            <li>
              <h2>Run experiment</h2>
              After conducting 1 of 5 of the radiation, convection, <br/>or conduction experiments, run the experiment on your ground kit unit. 
            </li>
            <li>
              <h2>Analyse results</h2>
              Analyze the data patterns or anomalies you receive from your ground kit unit. 
            </li>
          </ol>
        </Col>
      </Row>
        <Row className="starBack" style={{background: "url('/img/starbg.svg')"}}>
          <Col className="landingPanelContent" align="middle">
            <h1>Start your first mision!</h1>
            <div className="txt">
            signup today to start exploting space.
            </div>
            <br/><br/>
            <Button href="/register" type="primary"> Signup</Button>
          </Col>
        </Row>
        <Row id="contact">
          <Col span={12} className="landingContent">
          Contact us: <a href="mailto:Info@questforspace.com">Info@questforspace.com</a>
          </Col>
          <Col span={12} className="landingContent">
          Partnerships: <a href="/schools">School partnerships</a>
          </Col>
        </Row>
    </Content>
    <Modal
      title="Login"
      visible={objTmp.showLogin}
      footer={null}
      onCancel={ ()=>{ fnUpdate({...objTmp,"showLogin":false}) } }
    >
      <Form 
        name="login"
        initialValues={{ remember: true }}
        onFinish={fnLogin}
        onFinishFailed={fnLogin}
      >
        <Form.Item label="Username" name="un">
            <Input 
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} 
              placeholder="email"
              rules={[{ required: true, message: 'Please input your username/email' }]} 
            />
        </Form.Item>
        <Form.Item label="Password" name="pw">
            <Input 
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} 
              type="password" 
              placeholder="password" 
              rules={[{ required: true, message: 'Please input your password' }]}
            />
        </Form.Item>
        <Form.Item>
          <Row style={{width:'100%'}}>
            <Col span={12}>
              <SignInSignOutButton />
            </Col>
            <Col span={12} align="right">
              <Button 
                type="primary" 
                htmlType="submit" 
              >
                Log in
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  </>
);

};

export default SSOPage;