import React, { useContext,useState, useEffect } from 'react';
import { Input, Row, Col, Button ,Card,Select,message,InputNumber, Tooltip, Upload, Space } from 'antd';
import { DeleteFilled, EyeFilled, UploadOutlined } from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import ReactPlayer from 'react-player';
import exp from '../experimentHelper';
import {Config} from '../config.js';
import { v4 as uuidv4 } from 'uuid';
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';

const Option = Select.Option;
const InputGroup = Input.Group;
const { TextArea } = Input;
const DragHandle = sortableHandle(() => <div className="handleMe">::</div>);
const SortableContainer = sortableContainer(({children}) => {
    return <ul>{children}</ul>;
});

const BuildBMO = (props) => {
	const objUser = useContext(UserContext);
	const [objTmp, fnUpdate] = useState({
		 "modalTemplate":false
		,"modalSure":false
		,"modalCode":false
		,"newExperiment":false
		,"steps":[]
		,"template":''
		,"seconds":0
		,"heating":0
		,"lastAction":''
		,"lastDetail":''
		,"lastIndex":null
		,"topLED":0
		,"leftLED":0
		,"rightLED":0
		,"validated":false
		,"images":0
	});

/*
	4. In the rule builder, we have the following Actions and Components:
	Action:Components
	Wave:Both Arms, Left Arm, Right Arm
	Play Video: boy.mp4,cn2.mp4,wet.mp4 (*note: these are the actual .mp4 files. we prob need to rename these to be more descriptive)
	Show Message:<we need a multi-line text box--maybe use right handside instead of code window. maybe on the rule line we show a read-only 1st 40 chars so users have a sense of what the message is and it makes sense to see the whole program. then if they select that line, it shows the full message on the right. think about it. may be better ways>
	Show Face: smile
	Wait: <#secs input by user> (*note: same as Heat Exp Wait)
*/

const arrActions=[
	 {"id":'moveBoth',"name":'Move Both Arms'}
	,{"id":'moveLeft',"name":'Move Left Arm'}
	,{"id":'moveRight',"name":'Move Right Arm'}
	,{"id":'playVideo',"name":'Play Video'}
	,{"id":'playAudio',"name":'Play Audio'}
	,{"id":'showMessage',"name":'Show Message'}
	,{"id":'showFace',"name":'Show Face'}
	,{"id":'showImage',"name":'Show Image'}
	,{"id":'setLEDs',"name":'Set LED Brightness'}
	,{"id":'fan',"name":'Fan Off'}
	,{"id":'lasers',"name":'Set Lasers'}
	,{"id":'vibrate',"name":'Vibrate Motor On'}
	// ,{"id":'magnets',"name":'Control Magnets'}
	,{"id":'sense',"name":'Sense Objects'}
	,{"id":'delay',"name":'Wait'}
];

const arrAudios=[
	 {"id":'AllYourBase',"name":'All Your Base'}
	,{"id":'AreWeThereYet',"name":'Are We There Yet'}
	,{"id":'Astronaut',"name":'Astronaut'}
	,{"id":'Bathroom',"name":'Bathroom'}
	,{"id":'ComeBackDown',"name":'ComeBackDown'}
	,{"id":'Daisy',"name":'Daisy'}
	,{"id":'Floating',"name":'Floating'}
	,{"id":'GiantLeap',"name":'Giant Leap'}
	,{"id":'GreetingsCramped',"name":'Greetings Cramped'}
	,{"id":'Incoming',"name":'Incoming'}
	,{"id":'LateToDinner',"name":'Late To Dinner'}
	,{"id":'NotInOoo',"name":'Not In Ooo'}
	,{"id":'Parade',"name":'Parade'}
	,{"id":'RepairMo',"name":'Repair Mo'}
	,{"id":'SkyLimit',"name":'Sky Limit'}
	,{"id":'Sleep',"name":'Sleep'}
	,{"id":'SorryDave',"name":'Sorry Dave'}
	,{"id":'SoSmall',"name":'So Small'}
	,{"id":'SpaceScream',"name":'Space Scream'}
	,{"id":'Treehouse',"name":'Treehouse'}
	,{"id":'TurnOnLight',"name":'Turn On Light'}
	,{"id":'WelcomeISS2',"name":'Welcome ISS2'}
	,{"id":'WelcomeToSpace',"name":'Welcome To Space'}
];

const arrVideos=[
	{"seconds":3,"id":'adventureous',"name":'adventureous'},
	{"seconds":2,"id":'ah',"name":'ah'},
	{"seconds":2,"id":'beep1',"name":'beep1'},
	{"seconds":2,"id":'beep2',"name":'beep2'},
	{"seconds":2,"id":'birsday',"name":'birsday'},
	{"seconds":47,"id":'bmoboot',"name":'bmoboot'},
	{"seconds":4,"id":'boy',"name":'boy'},
	{"seconds":6,"id":'butreal',"name":'butreal'},
	{"seconds":4,"id":'button',"name":'button'},
	//{"seconds":3,"id":'help',"name":'help'},
	{"seconds":3,"id":'camera',"name":'camera'},
	{"seconds":2,"id":'checkplease',"name":'checkplease'},
	{"seconds":3,"id":'cn',"name":'cn'},
	{"seconds":3,"id":'cn2',"name":'cn2'},
	{"seconds":3,"id":'cn3',"name":'cn3'},
	{"seconds":3,"id":'compute',"name":'compute'},
	{"seconds":4,"id":'dangerous',"name":'dangerous'},
	{"seconds":4,"id":'dreams',"name":'dreams'},
	{"seconds":6,"id":'emotion',"name":'emotion'},
	{"seconds":2,"id":'gameover',"name":'gameover'},
	{"seconds":8,"id":'ghost',"name":'ghost'},
	{"seconds":3,"id":'hahaha',"name":'hahaha'},
	{"seconds":2,"id":'hello',"name":'hello'},
	{"seconds":3,"id":'hellofamily',"name":'hellofamily'},
	{"seconds":2,"id":'hellothere',"name":'hellothere'},
	{"seconds":3,"id":'heroes',"name":'heroes'},
	{"seconds":5,"id":'homies',"name":'homies'},
	{"seconds":5,"id":'ipad2',"name":'ipad'},
	{"seconds":3,"id":'littleliving',"name":'littleliving'},
	{"seconds":5,"id":'loveyou',"name":'loveyou'},
	{"seconds":9,"id":'mainbrain',"name":'mainbrain'},
	{"seconds":4,"id":'nicepictures',"name":'nicepictures'},
	{"seconds":3,"id":'nothing',"name":'nothing'},
	{"seconds":8,"id":'paradewhat',"name":'paradewhat'},
	{"seconds":6,"id":'power',"name":'power'},
	{"seconds":6,"id":'response',"name":'response'},
	{"seconds":6,"id":'rpi2',"name":'rpi2'},
	{"seconds":15,"id":'shortboot',"name":'shortboot'},
	{"seconds":5,"id":'sitonfloor',"name":'sitonfloor'},
	{"seconds":2,"id":'smack',"name":'smack'},
	{"seconds":3,"id":'spaderinvaders',"name":'spaceinvaders'},
	{"seconds":5,"id":'story',"name":'story'},
	{"seconds":5,"id":'switchplaces',"name":'switchplaces'},
	{"seconds":14,"id":'tears',"name":'tears'},
	{"seconds":6,"id":'thatsfinal',"name":'thatsfinal'},
	{"seconds":26,"id":'theme',"name":'theme'},
	{"seconds":13,"id":'themeshort',"name":'themeshort'},
	{"seconds":3,"id":'uhuhuh',"name":'uhuhuh'},
	{"seconds":5,"id":'videogames',"name":'videogames'},
	{"seconds":3,"id":'weloveyou',"name":'weloveyou'},
	{"seconds":5,"id":'wet',"name":'wet'},
	{"seconds":3,"id":'whyamitalking',"name":'whyamitalking'},
	{"seconds":3,"id":'yippee',"name":'yippee'}
];

const arrFaces=[
	 {"id":'0',"name":'SmileOpen'}
	,{"id":'1',"name":'Smile'}
	,{"id":'2',"name":'Meh'}
	,{"id":'3',"name":'Wow'}
	,{"id":'4',"name":'Relaxed'}
	,{"id":'5',"name":'Oh'}
	,{"id":'6',"name":'No'}
	,{"id":'7',"name":'Friendly'}
	,{"id":'8',"name":'Scared'}
	,{"id":'9',"name":'MightCry'}
	,{"id":'q',"name":'SadFace'}
	,{"id":'w',"name":'Frown'}
	,{"id":'e',"name":'Happy'}
	,{"id":'r',"name":'Embarrassed'}
	,{"id":'t',"name":'CubeFace'}
	,{"id":'u',"name":'Sleepy'}
	,{"id":'i',"name":'GoodMood'}
	,{"id":'o',"name":'HappyFace'}
	,{"id":'a',"name":'Blank'}
];


const fnAddStep=function(){
	var intId=1
	if(objTmp.steps.length > 0){ intId=objTmp.steps.length+1; }
	var objStep={"action":'delay',"detail":1,"id":intId, "uuid":uuidv4()};
	var arrSteps=[];
	if(objTmp.steps.length < 1){ 
		arrSteps=[objStep];  
	}else{ 
		arrSteps=objTmp.steps;
		arrSteps.push(objStep);
	}
	fnUpdate({ ...objTmp, "steps": arrSteps, "seconds": fnCountSeconds(arrSteps) ,"newExperiment":false} );
	props.callback(arrSteps);
};

const fnCountSeconds=function(arrSteps){
	//console.log(arrSteps);
	var intSeconds=0;
	for(var i=0;i<arrSteps.length;i++){
			if( ['delay','fan','sense','vibrate'].indexOf(arrSteps[i].action) > -1){ intSeconds = intSeconds + parseInt(arrSteps[i].detail); }
			if(arrSteps[i].action==='magnets'){ intSeconds = intSeconds + parseInt(arrSteps[i].duration); }
			if(arrSteps[i].action==='showMessage'){ intSeconds = intSeconds + parseInt(arrSteps[i].duration); }
			if(arrSteps[i].action==='showImage'){ intSeconds = intSeconds + parseInt(arrSteps[i].duration); }
			if(arrSteps[i].action==='playVideo'){
				let objVideo=arrVideos[arrSteps[i].detail];
				if(objVideo){ intSeconds = intSeconds + parseInt(objVideo.seconds); }
			}
			if(arrSteps[i].action==='playAudio'){
				let objVideo=arrAudios[arrSteps[i].detail];
				if(objVideo){ intSeconds = intSeconds + parseInt(objVideo.seconds); }
			}
			
		}
	return intSeconds;
}

const fnCleanMessage=function(strText){
  var arrFun=['cock', 'pussy', 'fuck', 'fucker', 'motherfucker', 'shit', 'dick', 'cunt', 'bitch', 'slut', 'cuck'];
  //first remove any back slashes
  strText = strText.replace(String.fromCharCode(92), "");
  var arrWords=strText.split(' ');
  for(var i=0;i<arrWords.length;i++){
    for(var ii=0; ii< arrFun.length; ii++){
      if(arrWords[i].toUpperCase() === arrFun[ii].toUpperCase()){ arrWords[i]='***'; }
    }
  }
  return arrWords.join(' ');
}

const fnUpdateStep=function(strField,strValue,intIndex){
	//console.log(strField,strValue,intIndex);
	let arrSteps=objTmp.steps;
	let objStep=arrSteps[intIndex];
	if(strField==='action'){ 
		objStep.detail=''; 
		objStep.duration=3;
		if(strValue==='setLEDs'){ objStep.detail="Medium"; }
		if( ['fan','vibrate','sense','delay'].indexOf(strValue) > -1 ){ objStep.detail=1; }
		if( ['moveBoth','moveLeft','moveRight'].indexOf(strValue) > -1 ){ objStep.detail="u"; }
		if(strValue==='magnets'){ objStep.left="0"; objStep.right="0"; }
		if(strValue==='lasers'){ objStep.detail="Off"; }
		if(strValue==='showFace'){ objStep.detail="0"; }
		if(strValue==='showFace'){ objStep.detail=""; }
		if(strValue==='playAudio'){ objStep.detail="WelcomeToSpace"; }
		if(strValue==='playVideo'){ objStep.detail="spaderinvaders"; }
	}
	objStep[strField]=strValue;
	arrSteps[intIndex]=objStep;
	let strAction=objTmp.lastAction;
	let strDetail=objStep.detail?objStep.detail:'';
	if(strField==='action'){ strAction=strValue;}
	else{ strAction=objStep.action; }
	if( strField==='detail' && strAction!=='showMessage' ){ strDetail=strValue; }
	//clean meesages
	if( strField==='detail' && strAction==='showMessage' ){ 
		if(strValue.length > 174){ message.warning(' Your message is too long, please reduce to less that 175 characters'); }
		else{ strDetail=fnCleanMessage(strValue); }
	}
	if( strField==='duration'){ objStep.duration=strValue; }
	const intSeconds = fnCountSeconds(arrSteps);
	//console.log(intSeconds);
	const ok = fnValidateSteps();
	fnUpdate({
		...objTmp, 
		steps:arrSteps,
		seconds:intSeconds,
		lastAction:strAction, 
		lastIndex:intIndex  ,
		lastDetail:strDetail,
		validated:ok 
	});
	props.callback( arrSteps );
};

const fnResort=function({oldIndex,newIndex}){
    let arrSteps=objTmp.steps;
	let objStep = arrSteps[oldIndex];
    arrSteps.splice(oldIndex,1);
    arrSteps.splice(newIndex,0,objStep);
	fnUpdate({...objTmp,steps:arrSteps});
    props.callback( arrSteps )
}

const fnPreviewStep=function(intIndex){
	let arrSteps=objTmp.steps;
	fnUpdate({
		...objTmp,
		lastAction:arrSteps[intIndex].action,
		lastDetail:arrSteps[intIndex].detail,
	});
};

const fnRemoveStep=function(intIndex){
	let arrSteps=objTmp.steps;
	arrSteps.splice(intIndex,1);
	const intSeconds =fnCountSeconds(arrSteps)
	fnUpdate({
		...objTmp,
		steps:arrSteps,
		seconds:intSeconds,
		lastAction:null
	});
};


const SortableItem = sortableElement(({value:objStep, sortIndex:intIndex}) => (
	<Row className={objStep.action === 'showMessage' ? 'stepRowMsg' : 'stepRow'} key={objStep.uuid}>
		<Col span={1} className="stepHandle" align="center">
                <DragHandle />
            </Col>
            <Col span={1} className="stepIndex" align="center">
                {intIndex+1}
            </Col>
		<Col span={21}>
			{fnGetStep(objStep,intIndex)}
		</Col><Col span={1} align="right" className="stepActions">
			<Space>
			<Tooltip title="preview step">
                <EyeFilled onClick={ ()=>fnPreviewStep(intIndex) } />
            </Tooltip>
			<Tooltip title="remove step">
                <DeleteFilled onClick={ ()=>fnRemoveStep(intIndex) } />
            </Tooltip>
			</Space>
        </Col>
	</Row>
));

const getImageName=function(strFilename, uuid){
	console.log(strFilename, uuid)
	const arrFileName = strFilename.split('.');
	const strExtension = arrFileName[arrFileName.length-1]
	strFilename = `EXP_${objUser.experiment.id}_${uuid}.${strExtension}`;
	return strFilename;
}

var fnGetStep=function(objStep,intIndex){
	//console.log(objStep,intIndex);
	if(objStep){
		const jsxBefore = (
			<Select defaultValue={objStep.action} style={{ width:200 }} onChange={ v => fnUpdateStep('action',v,intIndex) } >
				{ arrActions.map( (v,k) => (
					<Option key={v.id} value={v.id} >{v.name}</Option>
				))}
			</Select>
		);
			var jsxAfter = (
				<>
				<Input defaultValue={objStep.detail} id={`step${intIndex}Detail`} style={{ width:80 }} min={0} max={300} type="number" onBlur={ e => fnUpdateStep('detail',e.target.value,intIndex)} />
					<br/>
					<div className="codeFormLabel">
						<div style={{marginLeft:"200px",width:'80px'}}> seconds </div>
					</div>
				</>
			);
		if( objStep.action === 'lasers' ){
			jsxAfter = (
				<>
				<Select defaultValue={objStep.detail} style={{ width:100 }} onChange={ v => fnUpdateStep('detail',v,intIndex) } >
					<Option key='off' value='0'>Off</Option>
					<Option key='low' value='25' >Low</Option>
					<Option key='medium' value='50' >Medium</Option>
					<Option key='high' value='75' >High</Option>
					<Option key='full' value='100' >Full</Option>
				</Select>
				<br/>
					<div className="codeFormLabel">
						<div style={{"width":'200px'}}> &nbsp;</div>
						<div style={{"width":'100px'}}> power </div>
					</div>
				</>
			);
		}
		if( objStep.action === 'vibrate' ){
			jsxAfter = (
				<>
				<InputNumber style={{ "width":'80px' }} key="duration" min={1} max={15} defaultValue={objStep.detail} onBlur={ e => fnUpdateStep('detail',e.target.value,intIndex) } />
				<br/>
					<div className="codeFormLabel">
						<div style={{"width":'200px'}}> &nbsp;</div>
						<div style={{"width":'80px'}}> seconds </div>
					</div>
				</>
			);
		}
		if( objStep.action === 'magnets' ){
			jsxAfter = (
				<>
					<Select defaultValue={objStep.left} style={{ width:100 }} onChange={ v => fnUpdateStep('left',v,intIndex) } >
						<Option key='off' value='0'>Off</Option>
						<Option key='low' value='25' >Low</Option>
						<Option key='medium' value='50' >Medium</Option>
						<Option key='high' value='75' >High</Option>
						<Option key='full' value='100' >Full</Option>
					</Select>
					<Select defaultValue={objStep.right} style={{ width:100 }} onChange={ v => fnUpdateStep('right',v,intIndex) } >
						<Option key='off' value='0'>Off</Option>
						<Option key='low' value='25' >Low</Option>
						<Option key='medium' value='50' >Medium</Option>
						<Option key='high' value='75' >High</Option>
						<Option key='full' value='100' >Full</Option>
					</Select>
					<InputNumber style={{ "width":'80px' }} key="duration" min={0} max={300} defaultValue={objStep.duration} onChange={ v => fnUpdateStep('duration',v,intIndex) } />
					<br/>
					<div className="codeFormLabel">
						<div style={{"width":'200px'}}> &nbsp;</div>
						<div style={{"width":'100px'}}> left </div>
						<div style={{"width":'100px'}}> right </div>
						<div style={{"width":'80px'}}> seconds </div>
					</div>
				</>
			);
		}
		if( objStep.action === 'showMessage' ){
			jsxAfter = (
				<>
					<TextArea 
						id={`step${intIndex}Detail`} 
						maxlength="175" rows={4} 
						style={{ width:150 }} defaultValue={objStep.detail} 
						onBlur={ v => fnUpdateStep('detail',v.target.value,intIndex) } />
					<InputNumber min={1} max={15} defaultValue={objStep.duration} onBlur={ e => fnUpdateStep('duration',e.target.value,intIndex) } />
					<br/>
					<div className="codeFormLabel" style={{top:"-116px"}}>
						<div style={{marginLeft:"350px",width:'80px'}}> seconds </div>
					</div>
				</>
			);
		}
		if( ['moveLeft','moveRight','moveBoth'].indexOf(objStep.action) > -1 ){
			jsxAfter = (
				<Select defaultValue={objStep.detail} style={{ width:150 }} onChange={ v => fnUpdateStep('detail',v,intIndex) } >
					<Option key='up' value='u'>Up</Option>
					<Option key='down' value='d' >Down</Option>
					<Option key='wave' value='w' >Wave</Option>
				</Select>
			);
		}
		if( objStep.action === 'playVideo' ){
			jsxAfter = (
				<Select defaultValue={objStep.detail} style={{ width:150 }} onChange={ v => fnUpdateStep('detail',v,intIndex) } >
					{ arrVideos.map( (v,k) => (
					<Option key={v.id} value={v.id} >{v.name}</Option>
					))}
				</Select>
			);
		}
		if( objStep.action === 'playAudio' ){
			jsxAfter = (
				<Select defaultValue={objStep.detail} style={{ width:150 }} onChange={ v => fnUpdateStep('detail',v,intIndex) } >
					{ arrAudios.map( (v,k) => (
					<Option key={v.id} value={v.id} >{v.name}</Option>
					))}
				</Select>
			);
		}
		if( objStep.action === 'setLEDs' ){
			jsxAfter = (
				<Select defaultValue={objStep.detail} onChange={ v => fnUpdateStep('detail',v,intIndex) }>
					<Option key="off" value="0 0 0">Off</Option>
					<Option key="medium" value="50 50 50">Medium</Option>
					<Option key="high" value="100 100 100">High</Option>
				</Select>
			);
		}
		if( objStep.action === 'showFace' ){
			jsxAfter = (
				<Select defaultValue={objStep.detail} style={{ width:150 }} onChange={ v => fnUpdateStep('detail',v,intIndex) } >
					{ arrFaces.map( (v,k) => (
					<Option key={v.id} value={v.id}>{v.name}</Option>
					))}
				</Select>
			);
		}
		if( objStep.action === 'showImage' ){
			jsxAfter = (
				<>
				<InputNumber min={1} max={15} style={{maxWidth:"80px"}} defaultValue={objStep.duration} onBlur={ e => fnUpdateStep('duration',e.target.value,intIndex) } />
				{ objStep.detail &&
					<div style={{ marginLeft:'6px', width:'250px', textOverflow:'ellipsis', height:'30px'}}> {objStep.detail} </div>
				}{ !objStep.detail &&
					<Upload style={{width:'1900px', }}
					data={{...objUser, experiment:props.data.id, step:getImageCount(), uuid:objStep.uuid}}
					beforeUpload={objFile => {
                        if (objFile.type === 'image/png' ||
                            objFile.type === 'image/jpeg') {
						  return true;
                        } else {
						  message.error(`${objFile.name} is not a png or jpg file`);
						  return Upload.LIST_IGNORE;
						}
					  }}
					action={`${Config.api}/api/uploadImage`}
					onChange={ (objResponse)=>{
						console.log(objResponse);
						if(objResponse.event?.percent===100){
							message.info('Image uploaded successfully');
                            fnUpdateStep('detail', getImageName(objResponse.file.name,objStep.uuid), intIndex);
                            fnSaveSteps(objTmp.steps);
						}
					} }
					showUploadList={false}
				>
					<Button icon={<UploadOutlined />}>Upload 320x240 png or jpg</Button>
				</Upload>
				}
				
				<div className="codeFormLabel">
						<div style={{marginLeft:"200px",width:'80px'}}> seconds </div>
					</div>
				</>
			)
		}
		
			return (
				<InputGroup compact>
					{jsxBefore}
					{jsxAfter}
				</InputGroup>
			);
	}
}

const fnSaveSteps=function(arrSteps){
    objUser.fnUpdate({
        ...objUser,
        experiment:{...objUser.experiment,steps:arrSteps}
    });
    exp.fnSave({
        ...objUser,
        experiment:{...objUser.experiment,steps:arrSteps}
    });
}

const fnValidateSteps=function(){
	var ok=false;
	if(objTmp.steps && objTmp.steps.length > 0 && objTmp.steps[0].detail !== ''){ ok=true; }
	else{ message.warn('You need to configure some steps to continue'); return false;}
	if(ok){
		for(let i=0;i<objTmp.steps.length;i++){
			if(objTmp.steps[i].action === 'showMessage' && !objTmp.steps[i].detail.trim() ){
				message.warn('You cant have any empty messages configured');
				return false;
			}
		}
	}
	const intSeconds=fnCountSeconds(objTmp.steps);
	if(intSeconds > 300){ message.warn('Your experiment is too long. It needs to be shorter than 300 seconds.'); return false;}
	if(!ok){console.log('validation failed',objTmp.steps); }
	return ok;
}

const getImageCount=function(){
	// update image sequence number before sending back ok
	let intImages = 0;
	for(let i=0;i<objTmp.steps.length;i++){
			if(objTmp.steps[i].action === 'showImage' && objTmp.steps[i].detail.trim() ){
				intImages++;
			}
		}
	return intImages++;
}

useEffect( ()=>{
	if(props.data.steps.length && !objTmp.steps.length){
		fnUpdate({...objTmp,steps:props.data.steps});
	}
	// eslint-disable-next-line
},[props.data] )
  return (
  <Row>
      	<Col span={12} style={{paddingRight:"6px"}}>
		  <Card 
				size="small"
				title="Configure Experiment"
				extra={<Button onClick={ fnAddStep } size="small">Add Step</Button>}
				actions={[
					<div className={ (objTmp.seconds < 301 ? 'blueboxSubText' : 'codeHighlight') }> {objTmp.seconds} of 300 seconds total </div>,
					<Button size="small" type="primary" onClick={ () => fnAddStep()  } >Add step</Button>
				]}
			>
			  { objTmp.steps.length === 0 &&
      			<div className="centerContent codeStep">
      				{/*<div className="addStepIcon" onClick={ () => fnAddStep() } >+</div>*/}
      				Add steps to create your <br/>
      				experiment code
      			</div>
      		}
			<div style={{marginTop:"24px"}}>
			<SortableContainer 
				
				onSortEnd={fnResort} 
				useDragHandle
			>
				{ objTmp.steps && objTmp.steps.length > 0 && objTmp.steps.map( (objStep,intIndex)=>(
					<>
					<SortableItem 
						key={`item-${objStep.action}:${objStep.detail}`} 
						index={intIndex} 
						sortIndex={intIndex}
						value={objStep} 
					/> 
					</>
				))}
			</SortableContainer></div>
      		  
			</Card>
			</Col>
      	<Col span={12} style={{paddingLeft:"6px"}}>
			  <Card title="Preview Action Details" size="small" >
      		{ objTmp.lastAction === 'showFace' && objTmp.lastDetail &&
      			<img alt="BMO face preview"  style={{"maxHeight":'360px'}}
      				src= {'./img/bmo/faces/'+objTmp.lastDetail+'.jpg'} />
      		}
			{ objTmp.lastAction === 'showImage' && objTmp.lastDetail &&
      			<img alt="BMO preview"  style={{"maxHeight":'360px'}}
      				src= {`./uploads/${objTmp.lastDetail}`} />
      		}
      		{ objTmp.lastAction === 'playVideo' && objTmp.lastDetail &&
      			<ReactPlayer url={ './img/bmo/videos/'+objTmp.lastDetail+'.mp4' } width="50%" controls={ true } loop={ false } playing />
      		}
      		{ objTmp.lastAction === 'playAudio' && objTmp.lastDetail &&
      			<ReactPlayer url={ './img/bmo/mp3/'+objTmp.lastDetail+'.mp3' } height="56px" controls={ true } loop={ false } playing />
      		}
      		{ objTmp.lastAction === 'showMessage' &&
      			<div id="videoPreviewMessage">{objTmp.lastDetail}</div>
      		}{ objTmp.steps.length === 0 &&
      			<ReactPlayer url={ './img/BMO_Hits_Bits.mp4' } controls={ true } width="100%" loop={ true } volume={ 0.5} muted={ true } playing />
      		}
			  </Card>
      	</Col>
  </Row>
    );
};

export default BuildBMO;
