import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom"; 
import './App.css';
import {UserContext,ConfigContext} from './context.js';
import {createBrowserHistory} from 'history';
import axios from 'axios';
import {SSO} from './config.js';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import './App.css';
/*----====|| PAGES || ====----*/
import Login from './pages/login.js';
import SSOPage from './pages/sso.js';
import Mission from './pages/mission.js';
import Experiment from './pages/experiment.js';
import Build from './pages/build.js';
import Run from './pages/run.js';
import Data from './pages/data.js';
import Analyze from './pages/analyze.js';
import Play from './pages/play.js';
import Help from './pages/help.js';
import Schools from './pages/schools.js';
import Email from './pages/email.js';
import Register from './pages/register.js';
import Credits from './pages/credits.js';
import BMO3D from './pages/bmo3d.js';
import Admin from './pages/admin.js';
import AdminLaunch from './pages/adminLaunch.js';
import AdminConfig from './pages/adminConfig.js';
import AdminPromo from './pages/adminPromo.js';

// if you receive a request w/ hostname=bmoinspace.com and a promo_code=adsfa param, then treat it like kit.questforspace.com/bmo?promo_code=adsfa 

function App() {
  const history = createBrowserHistory();
  const msalInstance = new PublicClientApplication(SSO);
  //const { instance, accounts } = useMsal();
  const [objConfig, fnUpdateConfig] = useState({});
  const [objUser, fnUpdateUser] = useState({
    "jwt":''
   ,"username": ''
   ,"experiments":[]
   ,"experiment":{}
   ,"badges":[]
 });

 useEffect(() => {  
  axios.get('./config.json')
    .then(objResponse => {
      fnUpdateConfig(objResponse.data);
    }).catch(err=>{});
  if(!objUser.jwt && !history.location.search){
    console.log('no token found looking for localstore',history.location);
    let strUser=localStorage.getItem('user');
    let go=true;
    let objStoredUser;
    try{ objStoredUser = JSON.parse(strUser) ;}catch(e){ go=false; }
    if(go && objStoredUser && objStoredUser.jwt){
      fnUpdateUser({...objStoredUser});
    }
  }
  // eslint-disable-next-line
},[]);

  return (
    <Router>
      <ConfigContext.Provider value={objConfig}>
      <UserContext.Provider value={{...objUser,fnUpdate:fnUpdateUser}} >
      <MsalProvider instance={msalInstance}>
        <Route path="/" history={history} exact component={Login} />
        <Route path="/login" history={history} exact component={Login} />
        <Route path="/sso" history={history} exact component={SSOPage} />
        <Route path="/mission" history={history} exact component={Mission} />
        <Route path="/experiment" history={history} exact component={Experiment} />
        <Route path="/build" history={history} exact component={Build} />
        <Route path="/run" history={history} exact component={Run} />
        <Route path="/data" history={history} exact component={Data} />
        <Route path="/analyze" history={history} exact component={Analyze} />
        <Route path="/play" history={history} exact component={Play} />
        <Route path="/help" history={history} exact component={Help} />
        <Route path="/credits" history={history} exact component={Credits} />
        <Route path="/admin" history={history} exact component={Admin} />
        <Route path="/adminLaunch" history={history} exact component={AdminLaunch} />
        <Route path="/adminConfig" history={history} exact component={AdminConfig} />
        <Route path="/adminPromo" history={history} exact component={AdminPromo} />
        <Route path="/schools" history={history} exact component={Schools} />
        <Route path="/email" history={history} exact component={Email} />
        <Route path="/verify" history={history} exact component={Email} />
        <Route path="/register" history={history} component={Register} />
        <Route path="/bmo" history={history} component={BMO3D} />
      </MsalProvider>
      </UserContext.Provider>
      </ConfigContext.Provider>
    </Router>
  );
}

export default App;