/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import { Menu } from 'antd';

/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
//const { Content } = Layout;

/*----====|| COMPONENT START || ====----*/
const ExperimentNav = (props) => {
    const objUser = useContext(UserContext);
    //const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/

/*----====|| STATES || ====----*/
    //const [objTmp, fnUpdate] = useState({});
/*----====|| LOGIC || ====----*/
const fnEnableStep=function(intPhase,intStep){
    //step one
    if(intPhase === 0 && intStep === 0){return true;}
    //first step in a phase after phase 1
    if(intPhase > 0 && intStep === 0){
        //if the possibility exists of skipping 2 phases without steps this can be a backwards for loop to find last phase with steps
        let objLastPhase=objUser.experiment.sequence.phases[intPhase-1];
        if(!objLastPhase.steps.length){ objLastPhase = objUser.experiment.sequence.phases[intPhase-2]}
        const objLastStep=objLastPhase.steps[objLastPhase.steps.length-1];
        if(objLastStep.complete){ return true;}
    }
    //previous step found
    if(intStep > 0){
        const objLastStep=objUser.experiment.sequence.phases[intPhase].steps[intStep-1];
        if(objLastStep.complete){ return true;}
    }
}
/*----====|| HOOKS || ====----*/
//useEffect(() => {},[objUser.experiment]);
/*----====|| RENDER || ====----*/
return(
    <>
        <div
            style={{fontWeight:"Bold", margin:"6px",color:"rgb(101, 122, 225)"}}
        >Experiment Steps</div>
        <Menu mode="inline">
        { objUser.experiment && objUser.experiment.sequence && objUser.experiment.sequence.phases.map( (objPhase,k)=>(
            <React.Fragment key={k}>
            { objPhase.steps.length &&
                <Menu.ItemGroup key={objPhase.name} title={objPhase.name}>
                { objPhase.steps.map((objStep,kk)=>(
                  <Menu.Item key={objStep.id}
                    disabled={ !fnEnableStep(k,kk)  }
                  >
                    <Link to={ objStep.path } > 
                      { objStep.label } 
                    </Link>
                  </Menu.Item>
                ))}
                </Menu.ItemGroup>
            }
            </React.Fragment> 
        ))}
        </Menu>
    </>
);

};

export default ExperimentNav;