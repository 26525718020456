/*----====|| LIBRARY IMPORTS || ====----*/
//import React, { useContext,useEffect,useState } from 'react';
//import { Layout } from 'antd';

/*----====|| CONTEXT || ====----*/
//import {UserContext,ConfigContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
//const { Content } = Layout;

/*----====|| COMPONENT START || ====----*/
const Experiment = (props) => {
    //const objUser = useContext(UserContext);
    ////const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/

/*----====|| STATES || ====----*/
    //const [objTmp, fnUpdate] = useState({});
/*----====|| LOGIC || ====----*/

/*----====|| HOOKS || ====----*/
//useEffect(() => {},[]);
/*----====|| RENDER || ====----*/
return(
    <>
    </>
);

};

export default Experiment;