/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext,useEffect,useState } from 'react';
//import { Link } from 'react-router-dom'
import { List, Row, Col } from 'antd';
import exp from '../experimentHelper';
import _ from 'lodash';

/*----====|| CONTEXT || ====----*/
import {UserContext,ConfigContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
//const { Content } = Layout;

/*----====|| COMPONENT START || ====----*/
const RunGround = (props) => {
    const objUser = useContext(UserContext);
    const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/

/*----====|| STATES || ====----*/
    const [objTmp, fnUpdate] = useState({
        experimentConfig:null
    });
/*----====|| LOGIC || ====----*/

/*----====|| HOOKS || ====----*/
useEffect(() => {
    if(objUser.experiment && objConfig.experiments){
        const objExperimentConfig = exp.fnGetConfig(objUser.experiment,objConfig);
        console.log(objExperimentConfig);
        fnUpdate({...objTmp,experimentConfig:objExperimentConfig});
    }
},[objUser.experiment, objConfig, objTmp]);
/*----====|| RENDER || ====----*/
return(
    <List 
        dataSource={ objTmp.experimentConfig?objTmp.experimentConfig.stepCopy.run.steps:[] }
        renderItem={item => (
            <List.Item>
                <Row className="prepStep">
                    <Col span={8} align="right">
                        { item.images && item.images.map( (strImage)=>(
                            <img src={`./img/${strImage}`} alt={strImage} />
                        ))}
                    </Col>
                    <Col span={16} style={{paddingLeft:"12px"}}>
                        <div className="expOptTitle"> {item.title} </div>
                        <div > 
                            { !_.isArray(item.description) &&
                            <>{item.description}</>
                            }{ _.isArray(item.description) && item.description.map((strDescription,intIndex)=>(
                                <>{intIndex+1}. {strDescription} <br/></>
                            ))} 
                        </div>
                    </Col>
                </Row>
            </List.Item>
        )}
    />
);

};

export default RunGround;