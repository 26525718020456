import React, { useState, useContext } from 'react';
import { Form, Input, Row, Col, Button, Layout, message } from 'antd';
import NotNavMenu from '../components/notnavmenu.js';
import axios from 'axios';
import {UserContext} from '../context.js';
import {UserOutlined,LockOutlined} from '@ant-design/icons';
import {Config} from '../config.js';
const { Content, Header } = Layout;
const Search=Input.Search;

var objForm={ 
     "first":''
    ,"last":''
    ,"email":''
    ,"pw":''
    ,"confirm":''
    ,"coupon":''
  };
var arrErrors=[];
/* TODO: Terms and Conditions */

const Register = (props) => {
  const context = useContext(UserContext);
  const [objTmp, fnUpdate] = useState(
  { 
     "first":''
    ,"last":''
    ,"email":''
    ,"pw":''
    ,"confirm":''
    ,"coupon":''
  });
  const fnUpdateField=function(k,v){ fnUpdate({ ...objTmp, [k]: v}); }

  const fnSubmit=function(objForm){
    console.log('go',objForm);
    //e.preventDefault();
    //one last check over every field
    var arrKeys=Object.keys(objForm); var ok=true;
    for(var i=0; i < arrKeys.length; i++){ 
      var strError=false;
      strError=fnValidate(arrKeys[i],objForm[arrKeys[i]]); 
      if(strError){ message.error(strError); ok=false; break;}
    }
    for(i=0; i < arrKeys.length; i++){ if(objTmp[arrKeys[i]]){ ok=false; break; } }
    console.log(strError);
    if(ok && !strError)
      console.log(objForm);
      axios.post(Config.api+'/api/useradd',{"d":objForm, "jwt":undefined})
      .then(objResponse => {        
        arrErrors=objResponse.data.user.errors;
        if(arrErrors && arrErrors.length>0){
          for( var i=0; i < arrErrors.length; i++){ message.error(arrErrors[i]); }
        }else{
          context.fnUpdateField('user',{"email":objForm.email});
          props.history.push('/email');
        }
        
      }).catch(err=>{
        console.log(err);
      });
  };

  const fnValidate=function(strField,strValue){
    //console.log(objForm,strField,strValue);
    //required
    var strError=false;
    if( ['first','last','email','pw','confirm'].indexOf(strField) > -1 && strValue.length === 0){ strError='Please fill in value'; }
    else if( strField==='pw' && strValue.length < 5){ strError='A password needs to be longer than 5 characters'; }
    else if( strField==='confirm' && strValue !== objForm.pw){ strError='Password confirmation doesn\'t match' ; }
    
    if(strError){ 
      //console.log('field error');
      fnUpdateField(strField,strError);
      return strError;
    }else{ 
      //console.log('field ok');
      objForm[strField]=strValue;
      fnUpdateField(strField,false);
      return false;
    }
  };

    return (
      <Layout>
      <Header>
        <NotNavMenu history={props.history} />
      </Header>
      <Content className="contentPane">
      <Row>
          <Col span={6} offset={9} className="container" style={{padding:"20px"}}>
            <h2>Sign up {objTmp.i} </h2>
            <Form 
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={fnSubmit} 
              className="login-form"
            >
              <Form.Item name="first" label="First name*">
                  <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="" 
                  onBlur={e =>fnValidate('first',e.target.value) } />
              </Form.Item>
              <Form.Item name="last" label="Last name*">
                  <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="" 
                  onBlur={e =>fnValidate('last',e.target.value) } />
              </Form.Item>
              <Form.Item name="email" label="Email*">
                  <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="" 
                  onBlur={e =>fnValidate('email',e.target.value) } />
              </Form.Item>
              <Form.Item name="pw" label="Create Password*">
                  <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="" 
                  onBlur={e =>fnValidate('pw',e.target.value) } />
              </Form.Item>
              <Form.Item name="confirm" label="Confirm Password*">
                  <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="" 
                  onBlur={e =>fnValidate('confirm',e.target.value) } />
              </Form.Item>
              <Form.Item className="formSection" name="coupon" label="coupon">
                  <Search
                    placeholder=""
                    enterButton="Apply"
                    size="large"
                    onBlur={e =>fnValidate('coupon',e.target.value) }
                    onSearch={value => console.log(value)}
                  />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="fullWidth b" span={6}>
                  Sign up
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        </Content>
        </Layout>
    );
}

export default Register;