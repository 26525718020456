/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext,useEffect,useState } from 'react';
import { Layout,Table, Tag, Card, Button, Row, Col, List } from 'antd';
import axios from 'axios';
import Nav from '../components/nav.js';
import NewExperiment from '../components/newExperiment.js';
import exp from '../experimentHelper';
import Moment from 'moment';
import SVG from 'react-inlinesvg';
import {Config} from '../config.js';
import _ from 'lodash';
/*----====|| CONTEXT || ====----*/
import {UserContext,ConfigContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
const { Header, Content } = Layout;

/*----====|| COMPONENT START || ====----*/
const Mission = (props) => {
    const objUser = useContext(UserContext);
    const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/

const arrLaunchColumns=[
    {
         "title":'Location'
        ,"dataIndex":'location'
        ,"key":'location'
        ,"render": v => { 
            if(v==="In"){ return <> Inside ISS </> }
            if(v==="Out"){ return <> Outside ISS </> }
            else{ return v }
        }
    },{
         "title":'Time (Pacific)'
        ,"dataIndex":'nextDate'
        ,"key":'nextDate'
        ,"render": v => { 
            return Moment(v).format('LLLL');
        }
    }
];
const arrColumns=[
    {
         "title":'ID'
        ,"dataIndex":'id'
        ,"key":'id'
    },{
         "title":'Name'
        ,"dataIndex":'name'
        ,"key":'name'
    },{
         "title":'Location'
        ,"dataIndex":'location'
        ,"key":'location'
        ,"render": v => { 
            if(v==="In"){ return <> Inside ISS </> }
            if(v==="Out"){ return <> Outside ISS </> }
            if(v==="Sim"){ return <> Space Simulation </> }
                else{ return v }
        }
    },{
         "title":'Type'
        ,"dataIndex":'type'
        ,"key":'type'
    },{
         "title":'Progress'
        ,"dataIndex":'progress'
        ,"key":'status'
        ,'className':'statusTags'
        ,"render":(v)=>( <>
            { v && ['Build','Run','Data Ready','Analyze'].map( (strStep)=>(
                <Tag key={strStep} color={ v[strStep]?'success':'' } >{strStep}</Tag>
            ))}
        </>)
    },{
         "title":'Created (Pacific)'
        ,"dataIndex":'created'
        ,"key":'created'
    }
];
/*----====|| STATES || ====----*/
    const [objTmp, fnUpdate] = useState({
        showNew:false
    });
/*----====|| LOGIC || ====----*/
const fnGetBadges=function(arrExperiments){
    let arrBadges=['welcome'];
    arrExperiments.forEach( (objExperiment)=>{
        if(objExperiment.type!=='Video'){
            if(objExperiment.location!=='Ground' ){
                arrBadges.push('astro_train');
                if(objExperiment.progress.Analyze){
                    arrBadges.push('sci_off');
                    arrBadges.push('jr_astro');
                }
            }
            if(objExperiment.location==='Ground'){
                arrBadges.push('eng_train');
                if(objExperiment.progress.Analyze){
                    arrBadges.push('sci_off');
                    arrBadges.push('jr_eng');
                }
            }
        }
    } );
    return _.uniq(arrBadges);

}    
const fnProcessExperiment=function(objExperiment){
    
    const objData = JSON.parse(objExperiment.json);
    let objParsed = { ...objExperiment, ...objData }
    if(objExperiment.status==='Data Ready'){
        objParsed.sequence.phases.forEach( (objPhase)=>{
            objPhase.steps.forEach( (objStep)=>{
                if(objStep.id==='data'){
                    objStep.complete=Date.now();
                    if(objParsed.progress['Data Ready']===false){  objParsed.progress['Data Ready']=Date.now(); }
                }
            });
        });
    }
    return objParsed;
}
const fnGetExperiments=function(){
        if(objUser.jwt){
            axios.post(Config.api+'/api/explist',{"token":objUser.jwt})
	        .then(objResults => {        	          
                const arrExperiments=objResults.data.experiments.map( fnProcessExperiment );
              fnUpdate({
                ...objTmp,
                "experiments":arrExperiments,
                "launches":objResults.data.launches,
                "badges":fnGetBadges(arrExperiments)
              });
              objUser.fnUpdate({...objUser,launches:objResults.data.launches});
	        }).catch(err=>{
	    });
        }
	};

    const fnSelectExperiment=function(objExperiment){
        objUser.fnUpdate({...objUser,experiment:objExperiment});
        exp.fnLocalStore({...objUser,experiment:objExperiment});
        props.history.push(exp.fnNextStep(objExperiment,null,objConfig).next);
    } 

    const fnNewExperiment=function(){
        fnUpdate({
            ...objTmp,
            showNew:true
        });
    }
/*----====|| HOOKS || ====----*/
useEffect(() => {
    if(objUser){
        if(objUser.jwt){
            fnGetExperiments(objUser);
        }else{
            console.log('no login?',objUser);
            props.history.push('/login');
        }
    }
    // eslint-disable-next-line
},[objUser.jwt]);
/*----====|| RENDER || ====----*/
return(
    <Layout>
      <Header>
        <Nav history={props.history} />
      </Header>
      {objUser.badges && objConfig.badges &&
      <Content>
          <Row style={{paddingTop:'24px'}}>
            <Col span={11} offset={1} style={{paddingRight:"6px"}}>
                <Card size="small" title={ `Achievements ( ${objUser.badges.length} / ${objConfig.badges.length} )`  }>
                    <Row>
                        <Col span={24} className="container" id="achievementsOuter">
                            <List
                            id="achievementsInner"
                            grid={{ gutter: 16, column: 8 }}
                            dataSource={objConfig.badges}
                            renderItem={item => (
                            <List.Item className="badge">
                                    { objTmp.badges && objUser.badges.length > 0 && objTmp.badges.indexOf(item.id) > -1 &&
                                        <img alt="" src={item.img+' - Active.svg'}></img>
                                    }
                                    { objTmp.badges && objUser.badges.length > 0 && objTmp.badges.indexOf(item.id) === -1 &&
                                        <img alt="" src={item.img+' - Inactive.svg'}></img>
                                    }
                                    <div className="badgeTitle"> {item.title} </div>
                                    <div className="badgeDesc"> {item.desc} </div>
                                    <SVG className="badgeIcon icon" src="./img/icons/round-check_circle-24px.svg"></SVG>
                            </List.Item>
                            )}
                        />
                        </Col>
                    </Row>
                </Card>
            </Col>  
            <Col span={11} style={{paddingLeft:"6px"}}>
              <Card size="small" title="Next Launch" style={{minHeight:'279px'}}>
                    <Table 
                        columns={arrLaunchColumns} 
                        dataSource={objTmp.launches} 
                        rowKey="location" 
                        className="border" 
                        pagination={false}
    				/>
              </Card>
            </Col>
          </Row>
          <Row style={{paddingTop:'12px'}}>
              <Col span={22} offset={1}>
              <Card
                title="Experiments"
                extra={
                    <Button
                        onClick={ fnNewExperiment }
                    >
                        New Experiment
                    </Button>
                }
                >
                    <Table
                        columns={arrColumns}
                        dataSource={objTmp.experiments}
                        rowKey="id"
                        onRow={ (objRecord)=>{
                            return{
                                onClick:()=>{ fnSelectExperiment(objRecord) }
                            }
                        }}
                    />
                </Card>
              </Col>
          </Row>
          <NewExperiment 
            history={props.history}
            modal={{
                visible: objTmp.showNew  ,
                onCancel:()=>fnUpdate({...objTmp,showNew:false})
            }}
            />
      </Content>
      }
    </Layout>
);

};

export default Mission;