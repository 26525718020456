import React, { useContext } from 'react';
import { Form, Input, Row, Col, Button, Layout, message, Card } from 'antd';
import {UserContext} from '../context.js';
import Nav from '../components/nav.js';

import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import {CodeOutlined} from '@ant-design/icons';
import {Config} from '../config.js';

const { Header,Content } = Layout;

/* TODO: Add buy credits capability */

const Credits = (props) => {
  const objUser = useContext(UserContext);
  //const objConfig = useContext(ConfigContext);
  
var objForm={ "coupon":'' };

const fnClaimCode=function(e){
	e.preventDefault();
    if(objForm.coupon.length === 19){
      console.log('claim it',objForm.coupon );
      axios.post(Config.api+'/api/claim',{"d":objForm.coupon,"token":objUser.jwt})
      .then(res => {        
        //console.log('claimed code:',res);
        if(res.data.errors && res.data.errors.length > 0){
        	for(var i=0;i < res.data.errors.length; i++){
        		message.error( res.data.errors[i] );
        	}
        }else{
	        objUser.fnUpdate({...objUser,user:{...objUser.user,credits:res.data.credits}});
	        message.info('Coupon code accepted, credits applied.');
	        props.history.push('/mission');
        }

      }).catch(err=>{
        console.log(err);
      });
    }else{ message.error( 'Wrong coupon length, did you include the dashes? ####-####-####-####' ); }
};
const fnToken2 = function(objToken){ fnToken(objToken,2)} ;
const fnToken5 = function(objToken){ fnToken(objToken,5)} ;
const fnToken = (objToken,intCredits) => {
    if(!intCredits){ intCredits=1; }
    console.log(objToken);
    /*
    fetch('/save-stripe-token', {
      method: 'POST',
      body: JSON.stringify(token),
    }).then(response => {
      response.json().then(data => {
        alert(`We are in business, ${data.email}`);
      });
    });
    */
    axios.post(Config.api+'/api/purchase',{"token":objUser.jwt,"stoken":objToken,"credits":intCredits})
      .then(res => { 
        //console.log('purchased code:',res);
        objUser.fnUpdate({...objUser,user:{...objUser.user,credits:res.data.credits}});
        console.log(res.data.credits);
        props.history.push('/mission');
      }).catch(err=>{ console.log(err); });
  }
    return (
      <Layout style={{height:"100%",overflowY:"hidden"}}>
	<Header>
	  <Nav/>
	</Header>
      <Content style={{paddingTop:"24px"}}>
      <Row>
          <Col span={10} offset={2} style={{paddingRight:"6px"}}>
          <Card title="Coupons" size="small">
            <h2>Enter coupon code</h2>
            <Form onSubmit={fnClaimCode} className="login-form">
              <Form.Item>
                  <div className="inputLabel">Coupon code*</div>
                  <Input 
                  	prefix={<CodeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} 
                  	placeholder="" 
                  	onChange={e =>objForm.coupon=e.target.value }
                  	/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="fullWidth b" span={6}>
                  Redeem coupon
                </Button>
              </Form.Item>
            </Form>
            </Card>
          </Col>
          <Col span={10}  className="centerContent" style={{paddingLeft:"6px"}}>
            <Card title="Purchase Credits" size="small">
            <StripeCheckout
              token={fnToken}
              stripeKey="pk_test_3REnEfxgxaQnXLoGHGOwjAWf"
              label="Purchase 1 credit for $49.99"
              name="Purchase 1 credit"
              amount={4999} 
              currency="USD"
            /><br/>

            <StripeCheckout
              token={fnToken2}
              stripeKey="pk_test_3REnEfxgxaQnXLoGHGOwjAWf"
              label="Purchase 2 credits for $75.00"
              name="Purchase 2 credits"
              amount={7500} 
              currency="USD"
            /><br/>

            <StripeCheckout
              token={fnToken5}
              stripeKey="pk_test_3REnEfxgxaQnXLoGHGOwjAWf"
              label="Purchase 5 credits for $150.00"
              name="Purchase 5 credits"
              amount={15000} 
              currency="USD"
            />
            </Card>
          </Col>
        </Row>
        </Content>
        </Layout>
    );
}

export default Credits;