import React, { useContext } from 'react';
import { Form, Input, Row, Col, Button, Layout,Modal } from 'antd';
import { Link } from 'react-router-dom'
import axios from 'axios';
import {UserOutlined,LockOutlined,CheckSquareFilled} from '@ant-design/icons';
import NotNavMenu from '../components/notnavmenu.js';
import {UserContext} from '../context.js';
/*----====|| INSTANCE CONSTANTS || ====----*/
const { Header, Content } = Layout;

const Schools = (props) => {
  const context = useContext(UserContext);
  var objForm={
     "un":''
    ,"pw":''
  };

  const fnLogin = (e) => { 
    e.preventDefault();
    if(objForm.un.length > 0 && objForm.pw.length > 0){
      axios.post(context.api+'/api/login',objForm)
      .then(res => {        
        console.log(res);
        var arrBadges = ["welcome"];
        if(res.data.user.json){ var objJson=JSON.parse(res.data.user.json); }
        if(objJson && objJson.badges && objJson.badges.length > 1){ arrBadges=objJson.badges; }
        var objUser={"username":res.data.user.first+' '+res.data.user.last,"user":res.data.user, "badges":arrBadges ,"jwt":res.data.token,"role":res.data.user.role};
        //objUser.quota=context.fnGetQuota(res.data.token);
        context.fnUpdate(objUser);
        localStorage.setItem('user', JSON.stringify(objUser));
        props.history.push('/mission');
      }).catch(err=>{
        console.log(err);
      });
    }
  }

  return (
    <Layout>
      <Header>
        <NotNavMenu history={props.history} />
      </Header>
      <Content className="contentPane">
      <Row className="blueRow"  style={{background: 'url("./img/starbg.svg")'}} align="middle">
        <Col className="hero-msg" span={24}  > 
          <h1>School Partnerships</h1>
          <div className="txt">We partner with schools across the country to provide <br/> interactive STEM focused projects</div>
          <Button href="#contact" type="primary"> Contact Us</Button>
        </Col>
      </Row>
      <Row>
        <Col className="ngss">
          <CheckSquareFilled /> Aligned with Next Generation Science Standards (NGSS) 
        </Col>
      </Row>
        <Row type="flex" justify="space-around" align="middle" className="partnerships">
          <Col span={12}>
            <h1> Partnering with schools <br/> since 2010 </h1>
            <Row type="flex" justify="space-around">
              <Col span={6} className="raisedBox">
                <h2>37</h2>
                <div>School partnerships</div>
              </Col>
              <Col span={6} className="raisedBox">
                <h2>140</h2>
                <div>Experiments launched</div>
              </Col>
              <Col span={6} className="raisedBox">
                <h2>100+</h2>
                <div>Students impacted</div>
              </Col>
            </Row>
          </Col>
          <Col span={12} align="middle">
            <img alt="didnt load" src="../img/Landing2.jpg" />
          </Col>
        </Row>
        <Row className="starBack" style={{background: 'url("./img/starbg.svg")'}}>
          <Col className="landingPanelContent">
            <h1>Partner with Quest for Space</h1>
            <div className="txt">
            Reach out today and learn more<br/>
            about how we can work with your school district
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12} className="landingContent">
            <h1>Set your students up <br/>
              for success
            </h1>
          </Col>
          <Col span={12} className="landingContent">
            <h3>STEM Skills</h3>
            Help students learn fundamental programming skills and science concepts.
            <ul>
              <li>Research</li>
              <li>Engineering</li>
              <li>Development</li>
            </ul>
            <h3>Soft Skills</h3>
            <ul>
              <li>Teamwork</li>
              <li>Communication</li>
              <li>Project management</li>
            </ul>
          </Col>
        </Row>
        <Row>
        </Row>
        <Row className="starBack" style={{background: 'url("./img/starbg.svg")'}}>
          <Col className="landingPanelContent" align="middle">
            <h1>Start your first mision!</h1>
            <div className="txt">
            signup today to start exploring space.
            </div>
            <br/><br/>
            <Button href="/register" type="primary"> Signup</Button>
          </Col>
        </Row>
        <Row id="contact">
          <Col span={12} className="landingContent">
          Contact us: <a href="mailto:Info@questforspace.com">Info@questforspace.com</a>
          </Col>
          <Col span={12} className="landingContent">
          Partnerships: <a href="/schools">School partnerships</a>
          </Col>
        </Row>
        <Modal
            title="Login"
            visible={context.login}
            onCancel={ () => context.fnUpdateField('login',false) }
            footer={null}
          >
            <Form onSubmit={fnLogin} className="login-form">
              <Form.Item>
                  <div className="inputLabel">Username*</div>
                  <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="email" onChange={ e =>objForm.un=e.target.value } />
              </Form.Item>
              <Form.Item>
                  <div className="inputLabel">Password*</div>
                  <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="password" onChange={ e =>objForm.pw=e.target.value } />

              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="fullWidth b" span={6} onClick={fnLogin} >
                  Log in
                </Button>
              </Form.Item>
              <Row>
                <Col span={24} className="centerContent">
                  <Link to="/password" className="linkText" >Forgot password?</Link>
                </Col>
              </Row>
              
            </Form>
        </Modal>
    </Content>
    </Layout>
    );
};

export default Schools;
