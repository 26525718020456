/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext,useEffect,useState } from 'react';
import { Layout, Input, Select, DatePicker, Button,Row,Col, Card, Table, Form } from 'antd';
import axios from 'axios';
import Nav from '../components/nav.js';
import {Config} from '../config.js';
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
const { Content, Header } = Layout;
const { Option } = Select;
/*----====|| COMPONENT START || ====----*/
const Build = (props) => {
    const objUser = useContext(UserContext);
    //const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/
const arrLaunchColumns=[
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },{
      title: 'Location',
      dataIndex: 'location',
      key: 'location'
    },{
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },{
      title: 'Uploaded',
      dataIndex: 'upload',
      key: 'upload'
    },{
      title: 'Downloaded',
      dataIndex: 'download',
      key: 'download'
    }
];
/*----====|| STATES || ====----*/
    const [objTmp, fnUpdate] = useState({
        launches:[],
        newDate:'',
        newLocation:'In', 
        modalNew:false
    });
/*----====|| LOGIC || ====----*/
const fnGetLaunches=function(objUpdate){
    if(!objUpdate){ objUpdate={}; }
    axios.post(Config.api+'/api/launchlist',{"token":objUser.jwt})
        .then(objResults => {        	          
          if(objResults !== null){
              fnUpdate( {
                ...objTmp,
                ...objUpdate,
                launches:objResults.data.launches
            } );
          }
        }).catch(err=>{});
}
const fnSaveDate=function(v){
    axios.post(Config.api+'/api/launchadd',{
        token:objUser.jwt,
        date:v.date.format("YYYY-MM-DD 00:00:00"),
        location:v.location,
        name:v.name
    }).then(objResults => {        	          
		fnGetLaunches();
	}).catch(err=>{});
}
/*----====|| HOOKS || ====----*/
useEffect(() => {
    fnGetLaunches();
// eslint-disable-next-line
},[]);
/*----====|| RENDER || ====----*/
return(
    <Layout>
        <Header>
            <Nav/>
        </Header>
        <Content style={{padding:"12px"}}>
            <Card title="Add Launch">
                <Row>
                    <Col span={12}>
                        <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        name="launch"
                        onFinish={fnSaveDate}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Input launch name' }]}
                        >
                            <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item
                            label="Location"
                            name="location"
                            rules={[{ required: false, message: 'Select location' }]}
                        >
                            <Select defaultValue="In" >
                                <Option value="In">Inside ISS</Option>
                                <Option value="Sim">Simulated</Option>
                                <Option value="Out">Space Outside ISS</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Date"
                            name="date"
                            rules={[{ required: true, message: 'Select date' }]}
                        >
                            <DatePicker /> 
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                            Set Launch Date
                            </Button>
                        </Form.Item>
                    </Form>
                    </Col>
                    <Col span={12}>
                    {	objTmp.launches &&
                        <Table rowKey="id" dataSource={objTmp.launches} columns={arrLaunchColumns} />
                    }
                    </Col>
                </Row>
            </Card>
        </Content>
    </Layout>
);

};

export default Build;