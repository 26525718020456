import React, { useContext,useState,useEffect } from 'react';
import { Input, Row, Col, Button ,Card,Select ,InputNumber, Tooltip } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { DeleteFilled } from '@ant-design/icons';
import {UserContext} from '../context.js';
import _ from 'lodash';
const Option = Select.Option;
const InputGroup = Input.Group;
const BuildFF = (props) => {
const objUser = useContext(UserContext);
const [objTmp, fnUpdate] = useState({
		 "modalTemplate":false
		,"modalSure":false
		,"modalCode":false
		,"newExperiment":false
		,"steps":[]
		,"template":''
		,"seconds":0
		,"heating":0
		,"lastAction":''
		,"lastDetail":''
		,"topLED":0
		,"leftLED":0
		,"rightLED":0
	});
	const DragHandle = sortableHandle(() => <div className="handleMe">::</div>);
	const SortableContainer = sortableContainer(({children}) => {
		return <ul>{children}</ul>;
	});
	const SortableItem = sortableElement(({value:objStep, sortIndex:intIndex}) => (
		<Row className={objStep.action === 'showMessage' ? 'stepRowMsg' : 'stepRow'} key={objStep.id + objStep.action}>
			<Col span={1} className="stepHandle" align="center">
					<DragHandle />
				</Col>
				<Col span={1} className="stepIndex" align="center">
					{intIndex+1}
				</Col>
			<Col span={21}>
				{fnGetStep(objStep,intIndex)}
			</Col><Col span={1} align="right" className="stepActions">
				<Tooltip title="remove step">
					<DeleteFilled onClick={ ()=>fnRemoveStep(intIndex) } />
				</Tooltip>
			</Col>
		</Row>
	));
/*

https://docs.google.com/document/d/1kQujGDeaBLycwPx38ZxZ2zP5a_u0MlrvJaWKN6LhqQU/edit

erroFluids: Ferro Fluids Experiment (Type 1)

===============
GUI Exp Builder
===============
User Command        Arg#1           Arg#2   Arg#3   Arg#4   Notes
------------        -----           -----   -----   -----   ----------
Wait                #secs                           same as current Wait
Turn On Magnet      0-17            North   0-100   #secs
                                    South     "       "
Move FerroFluids    Clockwise
                    Counter-Clockwise
                    Top to Bottom
                    Bottom to Top
                    in Spiral
Set Backlighting    OFF
                    RED
                    ORANGE
                    YELLOW
                    GREEN
                    AQUA
                    BLUE
                    PURPLE
                    WHITE
                    MORPH
                    EXPLOSION
                    SWEEP_BOTTOM_TOP
                    SWEEP_TOP_BOTTOM
                    SWEEP_LEFT_RIGHT
                    SWEEP_RIGHT_LEFT
                    CHASE
                    SNAKE
===============
Code Gen
===============
0. Example Pseudo-logic
    Set Backlighting to Yellow
    Wait for 3 seconds
    Turn on magnet #17, north polarity, 60% power for 2 secs
    Set Backlighting to SNAKE
    Turn on magnet #0, south polarity, 100% power for 3 secs
    Move Filings Clockwise
    Wait for 7 seconds
    Move Filings in Spiral
    Wait for 7 seconds
1. Standard Header/Init of script
    #!/usr/bin/python3
    from ffcontrol import *
    exp_ff = FerroFluidControl()
    exp_ff.recording_start("<EXP_id_3",300) # Turn on recording, 300 sec max
    time.sleep(1)                           # Wait 1 sec for initialization
2. User Command Code
    exp_ff_backlight_pattern("YELLOW")
    time.sleep(3)
    exp_ff.magnet(17,"N",60)
    time.sleep(2)
    exp_ff.magnet(17,"N",0)
    exp_ff_backlight_pattern("SNAKE")
    exp_ff.magnet(0,"N",100)
    time.sleep(3)
    exp_ff.magnet(0,"N",0)
    exp_ff.magnet_routine_Perimeter_CW()
    time.sleep(7)
    exp_ff.magnet_routine_Spiral()
    time.sleep(7)
3. Standard Footer/Clean-up of script
    exp_ff.experiment_stop()    # Turn off magnets
    exp_ff.recording_stop()     # Turn off video recording
4. Filenames/Directories
    Filename: EXP_<id>_1.sh
    Queue output directory: out_ff or out_ff_sim
5. Locations in UI: Space (inside ISS) and Simulation
6. Validation/Constraints
    Magnet On Time: max 15 secs (builtin cutoff)
    Duty Cycle: each indiv magnet should only be on for 33% of the time...but this is hard to calculate and
        there is builtin throttling to prevent overheating. Example, for a continuous 30 sec interval, each magnet
        e.g. the "Top" magnet should not be on for more than 10sec total w/in that interval. In other words, if it's
        on for 10sec, then off, it should
    Calculating Total Runtime = all the Waits + (have to check on Move FerroFluids routines)
    Max Total Runtime: 120 sec max


*/

const arrMoves=[
	 {"seconds":54,"id":'magnet_routine_Perimeter_CW',"name":'Move Clockwise'}
	,{"seconds":54,"id":'magnet_routine_Perimeter_CCW',"name":'Move Counter-Clockwise'}
	,{"seconds":20,"id":'magnet_routine_TopBottom',"name":'Move Top to Bottom'}
	,{"seconds":20,"id":'magnet_routine_BottomTop',"name":'Move Bottom to Top'}
	,{"seconds":20,"id":'magnet_routine_LeftRight',"name":'Move Left to Right'}
	,{"seconds":20,"id":'magnet_routine_RightLeft',"name":'Move Right to Left'}
	,{"seconds":144,"id":'magnet_routine_Spiral',"name":'Move in a Spiral'}
];

const arrBacklights=[
	 {"name":'OFF',"id":'OFF'}
    ,{"name":'RED',"id":'RED'}
    ,{"name":'ORANGE',"id":'ORANGE'}
    ,{"name":'YELLOW',"id":'YELLOW'}
    ,{"name":'GREEN',"id":'GREEN'}
    ,{"name":'AQUA',"id":'AQUA'}
    ,{"name":'BLUE',"id":'BLUE'}
    ,{"name":'PURPLE',"id":'PURPLE'}
    ,{"name":'WHITE',"id":'WHITE'}
    ,{"name":'MORPH',"id":'MORPH'}
    ,{"name":'EXPLOSION',"id":'EXPLOSION'}
    ,{"name":'SWEEP_BOTTOM_TOP',"id":'SWEEP_BOTTOM_TOP'}
    ,{"name":'SWEEP_TOP_BOTTOM',"id":'SWEEP_TOP_BOTTOM'}
    ,{"name":'SWEEP_LEFT_RIGHT',"id":'SWEEP_LEFT_RIGHT'}
    ,{"name":'SWEEP_RIGHT_LEFT',"id":'SWEEP_RIGHT_LEFT'}
    ,{"name":'RANDOM',"id":'RANDOM'}
    ,{"name":'SNAKE',"id":'SNAKE'}
];

const arrMagnets=[
	 {"id":0,"name":'0'}
	,{"id":1,"name":'1'}
	,{"id":2,"name":'2'}
	,{"id":3,"name":'3'}
	,{"id":4,"name":'4'}
	,{"id":5,"name":'5'}
	,{"id":6,"name":'6'}
	,{"id":7,"name":'7'}
	,{"id":8,"name":'8'}
	,{"id":9,"name":'9'}
	,{"id":10,"name":'10'}
	,{"id":11,"name":'11'}
	,{"id":12,"name":'12'}
	,{"id":13,"name":'13'}
	,{"id":14,"name":'14'}
	,{"id":15,"name":'15'}
	,{"id":16,"name":'16'}
	,{"id":17,"name":'17'}
];

const arrActions=[
	 {"id":'delay',"name":'Wait', "default":1}
	,{"id":'magnet',"name":'Control Magnet', "default":arrMagnets[0].id}
	,{"id":'move',"name":'Routines', "default":arrMoves[0].id}
	,{"id":'backlight',"name":'Change Backlight', "default":arrBacklights[0].id}
];


const fnAddStep=function(){
	var intId=1
	if(objTmp.steps.length > 0){ intId=objTmp.steps.length+1; }
	var objStep={"action":'delay',"detail":1,"id":intId,"duration":1};
	var arrSteps=[];
	if(objTmp.steps.length < 1){ 
		arrSteps=[objStep];  
	}else{ 
		arrSteps=objTmp.steps;
		arrSteps.push(objStep);
	}
	//fnUpdateField('steps',arrSteps);
	fnUpdate({ ...objTmp, "steps": arrSteps, "seconds": fnCountSeconds(arrSteps) ,"newExperiment":false} );
};

const fnCountSeconds=function(arrSteps){
	var intSeconds=0;
	var arrErrors=[];
	for(var i=0; i < arrSteps.length; i++){
		//console.log(arrSteps[i]);
		if(arrSteps[i].action==='delay'){ 
			var intDetail=parseInt(arrSteps[i].detail);
			intSeconds=intSeconds+ intDetail;}
		if(arrSteps[i].action==='showMessage'){ intSeconds = intSeconds + arrSteps[i].duration; }
		if(arrSteps[i].action==='move'){ intSeconds = intSeconds + _.find(arrMoves,{"id":arrSteps[i].detail}).seconds; }
		//if(arrSteps[i].action==='magnet'){ intSeconds = intSeconds + arrSteps[i].duration; }
	}
	return [intSeconds,arrErrors];
}

const fnUpdateStep=function(strField,strValue,intIndex){
	//console.log(strField,strValue,intIndex);
	var arrSteps=objTmp.steps;
	var objStep=arrSteps[intIndex];
	objStep[strField]=strValue;
	arrSteps[intIndex]=objStep;
	//var arrSeconds=fnCountSeconds(arrSteps);
	var strAction=objTmp.lastAction;
	var strDetail='';
	//=======|| THIS SETS DEFAULTS PER ACTION WHEN CHOSEN OR CHANGED ||========\\
	if(strField==='action'){ 
		var objAction = _.find(arrActions,{"id":strValue});
		if( typeof objAction.default !== 'undefined'){ 
			strDetail = objAction.default;
			objStep.detail=strDetail; 
		}
		if( strValue === 'magnet' ){ 
			objStep.polarity='N';
			objStep.power=0; 
		}
	}
	else{ strAction=objStep.action; }
	if( strField==='detail' ){ strDetail=strValue; }
	if( strField==='duration'){ objStep.duration=strValue; }
	if( strField==='power'){ objStep.power=parseInt(strValue); }
	if( strField==='polarity'){ objStep.polarity=strValue; }
	//console.log(arrSteps);
	//get the seconds
	var intSeconds=fnCountSeconds(arrSteps);
	fnUpdate({...objTmp, "steps":arrSteps,"seconds":intSeconds,"lastAction":strAction, "lastDetail":strDetail });
	props.callback( arrSteps )
};


const fnRemoveStep=function(intIndex){
	var arrSteps=objTmp.steps;
	arrSteps.splice(intIndex,1);
	fnUpdate({...objTmp,"steps":arrSteps,"lastAction":null});
};


var fnGetStep=function(objStep,intIndex){
	//console.log(objStep,intIndex);
	if(objStep){
		/* choose action */
		const jsxBefore = (
			<Select defaultValue={objStep.action} style={{ width:200 }} onChange={ v => fnUpdateStep('action',v,intIndex) } >
				{ arrActions.map( (v,k) => (
					<Option key={v.id} value={v.id} >{v.name}</Option>
				))}
			</Select>
		);
		/* action particulars  */
		var jsxAfter = (
				<Input defaultValue={objStep.detail} style={{ "width":'150px' }} type="number" onChange={ e => fnUpdateStep('detail',e.target.value,intIndex)} />
			);
		if( objStep.action === 'magnet' ){
			jsxAfter = (
				<>
				<Select defaultValue={objStep.detail} style={{ width:75 }} onChange={ v => fnUpdateStep('detail',v,intIndex) } >
					{ arrMagnets.map( (v,k) => (
						<Option key={v.id} value={v.id} >{v.name}</Option>
					))}
				</Select>
				<Select defaultValue={objStep.polarity} style={{ "width":'80px' }} onChange={ v => fnUpdateStep('polarity',v,intIndex) }>
					<Option value="N" key="n">North</Option>
					<Option value="S" key="s">South</Option>
				</Select>
				<InputNumber style={{ "width":'80px' }} key="powerInput" min={0} max={100} defaultValue={objStep.power} onChange={ v => fnUpdateStep('power',v,intIndex) } />
				
				<br/>
				<div className="codeFormLabel">
					<div style={{"width":'200px'}}> &nbsp;</div>
					<div style={{"width":'75px'}}> magnet </div>
					<div style={{"width":'80px'}}> polarity </div>
					<div style={{"width":'80px'}}> power </div>
				</div>
				</>
			);
					
		}
		if( objStep.action === 'move' ){
			jsxAfter = (
				<Select style={{"width":'300px'}} defaultValue={objStep.detail} onChange={ v => fnUpdateStep('detail',v,intIndex) }>
					{ arrMoves.map( (v,k) => (
						<Option key={v.id} value={v.id} >{v.name}</Option>
					))}
				</Select>
			);
		}
		if( objStep.action === 'backlight' ){
			jsxAfter = (
				<Select style={{"width":'250px'}} defaultValue={objStep.detail} onChange={ v => fnUpdateStep('detail',v,intIndex) }>
					{ arrBacklights.map( (v,k) => (
						<Option key={v.id} value={v.id} >{v.name}</Option>
					))}
				</Select>
			);
		}


		
			return (
					<InputGroup compact>
						{jsxBefore}
						{jsxAfter}
					</InputGroup>
			);
	}
}


const fnLoadSteps = function(){
	if(objTmp.steps.length === 0 && objUser.experiment.steps && objUser.experiment.steps.length > 0){
		//fnUpdateField('steps',context.experiment.steps);
		fnUpdate({ ...objTmp, "steps": objUser.experiment.steps, "newExperiment":false});
	}
};

const fnResort=function({oldIndex,newIndex}){
    let arrSteps=objTmp.steps;
	let objStep = arrSteps[oldIndex];
    arrSteps.splice(oldIndex,1);
    arrSteps.splice(newIndex,0,objStep);
	fnUpdate({...objTmp,steps:arrSteps});
    props.callback( arrSteps )
}

/* eslint-disable-next-line */
useEffect(() => { fnLoadSteps(); },[]);
  
  return (

  <Row>
      	<Col span={12} >
      		<Card size="small" title="Experiment Configuration" 
				extra={<Button type="primary" size="small" onClick={ () => fnAddStep()  } >Add step</Button>} 
				actions={[
					<div className={ (objTmp.seconds < 301 ? 'blueboxSubText' : 'codeHighlight') }> {objTmp.seconds} of 300 seconds total </div>,
					<Button size="small" type="primary" onClick={ () => fnAddStep()  } >Add step</Button>]}
			>
				{ objTmp.steps.length === 0 &&
      			<div className="centerContent codeStep">
      				{/*<div className="addStepIcon" onClick={ () => fnAddStep() } >+</div>*/}
      				Add steps to create your <br/>
      				experiment code
      			</div>
      		}
				{ /* objTmp.steps.map( (v,k) => fnGetStep(v,k) ) */}
				<SortableContainer 
					onSortEnd={fnResort} 
					useDragHandle
				>
					{objTmp.steps.map( (objStep,intIndex) => (
						<SortableItem 
							key={`item-${objStep.action}:${objStep.detail}`} 
							index={intIndex} 
							sortIndex={intIndex}
							value={objStep} 
							/> 
						  ))}
						</SortableContainer>
			</Card>
      	</Col>
      	<Col span={12}>				
		  	<Card size="small" title="Experiment Configuration Reference">
			  <img src="./img/FFPreview.png" alt="preview" style={{"maxWidth":'100%',"maxHeight":'100%'}} />
      		{ objTmp.lastAction === 'showMessage' &&
      			<div id="videoPreviewMessage">{objTmp.lastDetail}</div>
      		}
			  </Card>
      	</Col>
  </Row>
    );
};

export default BuildFF;
