/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext } from 'react';
import { Row, Col } from 'antd';

import ExperimentLayout from '../components/experimentLayout';

import RunGround from '../components/runGround';
import RunSpace from '../components/runSpace';
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';


/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
//const { Content } = Layout;

/*----====|| COMPONENT START || ====----*/
const Run = (props) => {
    const objUser = useContext(UserContext);
    //const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/

/*----====|| STATES || ====----*/
    //const [objTmp, fnUpdate] = useState({});
/*----====|| LOGIC || ====----*/


/*----====|| HOOKS || ====----*/

/*----====|| RENDER || ====----*/
return(
    <>
    {objUser.experiment &&
        <ExperimentLayout history={props.history} step="run" 
        next={ (objUser.experiment.location==='Ground'?true:false) || (objUser.experiment.location!=='Ground' && ['Launching','Data Ready'].indexOf(objUser.experiment.status) > -1)  }>
            { objUser.experiment && objUser.experiment.location && objUser.experiment.location === 'Ground' &&
            <Row>
                <Col>
                    <RunGround data={objUser.experiment} />
                </Col>
            </Row>
        }{ objUser.experiment && objUser.experiment.location && objUser.experiment.location !== 'Ground' &&
            <Row>
                <Col offset={6} span={12} align="center">
                    <RunSpace data={objUser.experiment} history={props.history}/>
                </Col>
            </Row>
        }   
        </ExperimentLayout>
    }
    </>
);

};

export default Run;