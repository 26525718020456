import React, { useContext,useState,useEffect } from 'react';
import { Input, Row, Col, Card, Button, Layout,Select ,InputNumber, Tooltip } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { DeleteFilled } from '@ant-design/icons';
import {UserContext,ConfigContext} from '../context.js';
import exp from '../experimentHelper';
import _ from 'lodash';

const Option = Select.Option;
const InputGroup = Input.Group;
const { TextArea } = Input;
const BuildFilings = (props) => {
	const objUser = useContext(UserContext);
    const objConfig = useContext(ConfigContext);
const [objTmp, fnUpdate] = useState({
		 "modalTemplate":false
		,"modalSure":false
		,"modalCode":false
		,"newExperiment":false
		,"steps":[]
		,"template":''
		,"seconds":0
		,"heating":0
		,"lastAction":''
		,"lastDetail":''
	});

	const DragHandle = sortableHandle(() => <div className="handleMe">::</div>);
	const SortableContainer = sortableContainer(({children}) => {
		return <ul>{children}</ul>;
	});
/*

https://docs.google.com/document/d/1kQujGDeaBLycwPx38ZxZ2zP5a_u0MlrvJaWKN6LhqQU/edit

FerroFluids: Iron Filing Experiment (Type 3)

===============
GUI Exp Builder
===============
User Command        Arg#1           Arg#2   Arg#3   Arg#4   Notes
------------        -----           -----   -----   -----   ----------
Wait                #secs                           same as current Wait
Vibrate                                             show text to clarify duration "for 0.5 seconds"
Turn On Magnet      Top             North   0-100   #secs
                                    South     "
                    Bottom            "       "
                    Left              "       "
                    Right             "       "
Move Filings        Left to Right
                    Right to Left
                    Top to Bottom
                    Bottom to Top
                    Flying Around
Show Message        <text>
===============
Code Gen
===============
0. Example Pseudo-logic
    Show Message "Hello from the ISS"
    Turn on top magnet, north polarity 60% power for 2 secs
    Turn on left magnet, south polarity 100% power for 3 secs
    Vibrate casing for .5 sec (no choice on timing)
    Move Filings left to right
    Wait for 5 seconds
    Move Filings Flying Around
    Wait for 7 seconds
    Show Message "Bye"
1. Standard Header/Init of script
    #!/usr/bin/python3
    from ifcontrol import *
    exp_if = IronFillingControl()
    exp_if.recording_start("<EXP_id_3",300) # Turn on recording, 300 sec max
    time.sleep(1)                           # Wait 1 sec for initialization
2. User Command Code
    exp_if.message("Hello from the ISS")
    exp_if.magnet("T","N",60)
    time.sleep(2)
    exp_if.magnet("T","N",0)
    exp_if.magnet("L","S",100)
    time.sleep(3)
    exp_if.magnet("L","S",0)
    exp_if.vibrate()
    exp_if.magnet_routine_LeftRight()
    time.sleep(5)
    exp_if.magnet_routine_Explosion()
    time.sleep(7)
    exp_if.message("Bye")
3. Standard Footer/Clean-up of script
    exp_if.experiment_stop()    # Turn off magnets
    exp_if.recording_stop()     # Turn off video recording
4. Filenames/Directories
    Filename: EXP_<id>_3.sh
    Queue output directory: out_ff or out_ff_sim
5. Locations in UI: Space (inside ISS) and Simulation
6. Validation/Constraints
    Magnet On Time: max 10 secs (builtin cutoff)
    Duty Cycle: each indiv magnet should only be on for 33% of the time...but this is hard to calculate and
        there is builtin throttling to prevent overheating. Example, for a continuous 30 sec interval, each magnet
        e.g. the "Top" magnet should not be on for more than 10sec total w/in that interval. In other words, if it's
        on for 10sec, then off, it should
    Calculating Total Runtime = all the Waits + .5 sec per vibrate (have to check on Move Filings routines)
*/


const arrMoves=[
	 {"seconds":5,"id":'magnet_routine_LeftRight',"name":'Move Left to Right'}
	,{"seconds":5,"id":'magnet_routine_RightLeft',"name":'Move Right to Left'}
	,{"seconds":5,"id":'magnet_routine_TopBottom',"name":'Move Top to Bottom'}
	,{"seconds":5,"id":'magnet_routine_BottomTop',"name":'Move Bottom to Top'}
	,{"seconds":20,"id":'magnet_routine_Explosion',"name":'Move All Around'}
];

const arrMagnets=[
	 {"id":'T',"name":'T'}
	,{"id":'B',"name":'B'}
	,{"id":'L',"name":'L'}
	,{"id":'R',"name":'R'}
];

const arrActions=[
	 {"id":'delay',"name":'Wait', "default":1 }
	,{"id":'magnet',"name":'Control Magnet', "default":arrMagnets[0].id}
	,{"id":'move',"name":'Routines', "default":arrMoves[0].id}
	,{"id":'vibrate',"name":'Vibrate for 0.5 Seconds', "default":null}
];


const fnAddStep=function(){
	var intId=1
	if(objTmp.steps.length > 0){ intId=objTmp.steps.length+1; }
	var objStep={"action":'delay',"detail":1,"id":intId,"duration":1};
	var arrSteps=[];
	if(objTmp.steps.length < 1){ 
		arrSteps=[objStep];  
	}else{ 
		arrSteps=objTmp.steps;
		arrSteps.push(objStep);
	}
	//fnUpdateField('steps',arrSteps);
	fnUpdate({ ...objTmp, "steps": arrSteps, "seconds": fnCountSeconds(arrSteps) ,"newExperiment":false} );
};

const fnCountSeconds=function(arrSteps){
	var intSeconds=0;
	for(var i=0; i < arrSteps.length; i++){
		//console.log(arrSteps[i]);
		if(arrSteps[i].action==='delay'){ 
			var intDetail=parseInt(arrSteps[i].detail);
			intSeconds=intSeconds+ intDetail;}
		if(arrSteps[i].action==='move'){ intSeconds = intSeconds + _.find(arrMoves,{"id":arrSteps[i].detail}).seconds; }
		if(arrSteps[i].action==='vibrate'){ intSeconds = intSeconds + 1; }
	}
	return intSeconds;
}

/*
const fnCleanMessage=function(strText){
  var arrFun=['cock', 'pussy', 'fuck', 'fucker', 'motherfucker', 'shit', 'dick', 'cunt', 'bitch', 'slut', 'cuck'];
  //first remove any back slashes
  strText = strText.replace(String.fromCharCode(92), "");
  var arrWords=strText.split(' ');
  for(var i=0;i<arrWords.length;i++){
    for(var ii=0; ii< arrFun.length; ii++){
      if(arrWords[i].toUpperCase() === arrFun[ii].toUpperCase()){ arrWords[i]='***'; }
    }
  }
  return arrWords.join(' ');
}
*/

const fnUpdateStep=function(strField,strValue,intIndex){
	//console.log(strField,strValue,intIndex);
	var arrSteps=objTmp.steps;
	var objStep=arrSteps[intIndex];
	//=======|| THIS SETS DEFAULTS PER ACTION WHEN CHOSEN OR CHANGED ||========\\
	if(strField==='action'){ 
		var objAction = _.find(arrActions,{"id":strValue});
		if(objAction.default){ objStep.detail=objAction.default; }
		if( strValue === 'magnet' ){ 
			objStep.polarity='N';
			objStep.power=0; 
		}
	}
	objStep[strField]=strValue;
	arrSteps[intIndex]=objStep;
	//var arrSeconds=fnCountSeconds(arrSteps);
	var strAction=objTmp.lastAction;
	var strDetail='';

	//get the seconds
	var intSeconds=fnCountSeconds(arrSteps);
	fnUpdate({...objTmp, "steps":arrSteps,"seconds":intSeconds,"lastAction":strAction, "lastDetail":strDetail });
	props.callback( arrSteps )
};

/*
const fnUpdateLed=function(strLed,intValue,intIndex){
	objTmp[strLed]=intValue;
	objTmp.steps[intIndex].detail=objTmp.topLED+' '+objTmp.leftLED+' '+objTmp.rightLED;
	fnUpdate({...objTmp});
}
*/

const fnRemoveStep=function(intIndex){
	var arrSteps=objTmp.steps;
	arrSteps.splice(intIndex,1);
	fnUpdate({...objTmp,"steps":arrSteps,"lastAction":null});
};


const fnResort=function({oldIndex,newIndex}){
    let arrSteps=objTmp.steps;
	let objStep = arrSteps[oldIndex];
    arrSteps.splice(oldIndex,1);
    arrSteps.splice(newIndex,0,objStep);
	fnUpdate({...objTmp,steps:arrSteps});
    props.callback( arrSteps )
}
const SortableItem = sortableElement(({value:objStep, sortIndex:intIndex}) => (
	<Row className={objStep.action === 'showMessage' ? 'stepRowMsg' : 'stepRow'} key={objStep.id + objStep.action}>
		<Col span={1} className="stepHandle" align="center">
                <DragHandle />
            </Col>
            <Col span={1} className="stepIndex" align="center">
                {intIndex+1}
            </Col>
		<Col span={21}>
			{fnGetStep(objStep,intIndex)}
		</Col><Col span={1} align="right" className="stepActions">
            <Tooltip title="remove step">
                <DeleteFilled onClick={ ()=>fnRemoveStep(intIndex) } />
            </Tooltip>
        </Col>
	</Row>
));
var fnGetStep=function(objStep,intIndex){
	//console.log(objStep,intIndex);
	if(objStep){
		const jsxBefore = (
			<Select defaultValue={objStep.action} style={{ "width":'200px' }} onChange={ v => fnUpdateStep('action',v,intIndex) } >
				{ arrActions.map( (v,k) => (
					<Option key={v.id} value={v.id} >{v.name}</Option>
				))}
			</Select>
		);
			var jsxAfter = (
				<Input defaultValue={objStep.detail} style={{ "width":'150px' }} type="number" onChange={ e => fnUpdateStep('detail',e.target.value,intIndex)} />
			);
		if( objStep.action === 'vibrate' ){ jsxAfter = ''; }
		if( objStep.action === 'move' ){
			jsxAfter = (
				<Select defaultValue={objStep.detail} onChange={ v => fnUpdateStep('detail',v,intIndex) }>
					{ arrMoves.map( (v,k) => (
						<Option key={v.id} value={v.id} >{v.name}</Option>
					))}
				</Select>
			);
		}
		if( objStep.action === 'magnet' ){
			jsxAfter = (
				<>
				<Select style={{ "width":'90px' }} defaultValue={objStep.detail} onChange={ v => fnUpdateStep('detail',v,intIndex) }>
					{ arrMagnets.map( (v,k) => (
						<Option key={v.id} value={v.id} >{v.name}</Option>
					))}
				</Select>
				<Select defaultValue={objStep.polarity} style={{ "width":'80px' }} onChange={ v => fnUpdateStep('polarity',v,intIndex) }>
					<Option value="N" key="n">North</Option>
					<Option value="S" key="s">South</Option>
				</Select>
				<InputNumber style={{ "width":'80px' }} key="powerInput" min={0} max={100} defaultValue={objStep.power} onChange={ v => fnUpdateStep('power',v,intIndex) } />
				
				<br/>
				<div className="codeFormLabel">
					<div style={{"width":'200px'}}> &nbsp;</div>
					<div style={{"width":'90px'}}> magnet </div>
					<div style={{"width":'80px'}}> polarity </div>
					<div style={{"width":'80px'}}> power </div>
				</div>
				</>
			);
		}
		if( objStep.action === 'showMessage' ){
			jsxAfter = (
				<>
					
					<InputNumber min={1} max={15} defaultValue={3} onChange={ v => fnUpdateStep('duration',v,intIndex) } />
					<TextArea rows={4} style={{ "width":'200px',"resize":'none' }} value={objStep.detail} onChange={ v => fnUpdateStep('detail',v.target.value,intIndex) } />
				<div className="codeFormLabelMsg">
					<div style={{"width":'200px'}}> &nbsp;</div>
					<div style={{"width":'90px'}}> seconds </div>
					<div style={{"width":'200px'}}> message </div>
				</div>
				</>
			);
		}		
			return (
				<InputGroup compact>
					{jsxBefore}
					{jsxAfter}
				</InputGroup>
			);
	}
}

const fnLoadSteps = function(){
	if(objTmp.steps.length === 0 && objUser.experiment.steps && objUser.experiment.steps.length > 0){
		//fnUpdateField('steps',context.experiment.steps);
		fnUpdate({ ...objTmp, "steps": objUser.experiment.steps, "newExperiment":false});
	}
};

/*
const fnValidateSteps=function(){
	var ok=false;
	var intSeconds=0;
	if(objTmp.steps && objTmp.steps.length > 0 && objTmp.steps[0].detail !== ''){ ok=true; }
	else{ message.warn('You need to configure some steps to continue'); return false;}
	if(ok){
		for(var i=0;i<objTmp.steps.length;i++){

			if(objTmp.steps[i].action === 'showMessage' && !toString(objTmp.steps[i].detail).trim() ){
				message.warn('You cant have any empty messages configured');
				return false;
			}
		}
	}
	fnUpdate({...objTmp,"seconds":intSeconds});
	if(objTmp.seconds > 300){ message.warn('Your experiment is too long. It needs to be shorter than 300 seconds.'); return false;}
	return ok;
}
*/
// eslint-disable-next-line
useEffect(() => { fnLoadSteps(); },[]);
useEffect( ()=>{
	if(objUser.experiment && objConfig.experiment && !objTmp.experimentConfig){
	  const objExperimentConfig = exp.fnGetConfig(objUser.experiment,objConfig);
	  fnUpdate({...objTmp,experimentConfig:objExperimentConfig});
	}
  },[objConfig, objTmp, objUser.experiment] )
  return (
            <Layout>
            { objUser.experiment && objUser.experiment.type &&
            	
                          <Row>
            	              	<Col span={12} style={{paddingRight:"6px"}}>
            	              		<Card
									  title="Configure Experiment"
									  size="small"
									  extra={<Button type="primary" size="small" onClick={ () => fnAddStep()  } >Add step</Button>} 
									  actions={[
										<div className={ (objTmp.seconds < 301 ? 'blueboxSubText' : 'codeHighlight') }> {objTmp.seconds} of 300 seconds total </div>,
									  <Button size="small" type="primary" onClick={ () => fnAddStep()  } >Add step</Button>]}
									>
									  { objTmp.steps.length === 0 &&
            	              			<div className="centerContent codeStep">
            	              				{/*<div className="addStepIcon" onClick={ () => fnAddStep() } >+</div>*/}
            	              				Add steps to create your <br/>
            	              				experiment code
            	              			</div>
            	              		}
            	              		<SortableContainer 
				
				onSortEnd={fnResort} 
				useDragHandle
			>
									  {objTmp.steps.map( (objStep,intIndex) => (
										<SortableItem 
										key={`item-${objStep.action}:${objStep.detail}`} 
										index={intIndex} 
										sortIndex={intIndex}
										value={objStep} 
										/> 
									  ))}
									  </SortableContainer>
									  </Card>           	              	</Col>
            	              	<Col span={12} style={{paddingLeft:"6px"}}>
            	              		<Card title="Magnet Reference" size="small" >
										<img src="./img/FilingsPreview.png" alt="preview" style={{"maxWidth":'100%',"maxHeight":'100%'}} /> 
									</Card>
            	              	</Col>
                          </Row>

            }
            
        </Layout>

    );
};

export default BuildFilings
