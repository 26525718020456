//experiment type => action => object => code
const codeMap={
    heatSpace:{
        lowLights:['experiment.','{','}'],
        init:[
            { 
                comment:"import the custom experiment hardware library",
                code:`#include <ExperimentControl.h>`
            },{
                code:`volatile bool ExperimentControl::IRQ_Flag;`
            },{
                comment:`create an instance of the library as "experiment"`,
                code:`ExperimentControl experiment;`
            },{
                comment:`code to be run once`,
                code:`void setup()`
            },{
                className:'codeLowLight',
                code:`{`
            },{
                code:`experiment.InitPlatform();`
            },{
                comment:`Begin experiment specific steps`
            }
        ],
        on:{
            fan:{
                comment:"turn on fan ",
                code: "experiment.FanOn();"
            },
            resistor:{
                comment:"turn on resistor which will generate heat",
                code: "experiment.ConductionHeaterOn();"
            },
            bulb:{
                comment:"turn on bulb which will generate heat",
                code: "experiment.BulbHeaterOn();"
            }
        },
        off:{
            fan:{
                comment:"turn off fan",
                code:"FanOff();"
            },
            resistor:{
                comment:"turn off resistor which will allow cooling",
                code: "experiment.ConductionHeaterOff();"
            },
            bulb:{
                comment:"turn on bulb which will generate heat",
                code: "experiment.BulbHeaterOff();"
            }
        },
        delay:{
            comment:"wait for defined number of seconds",
            code: (objStep)=>( `experiment.delay_sec( ${objStep.detail} );`)
        },
        convection:{
            on:{
                resistor:{
                    comment:"turn on resistor which will generate heat",
                    code: "experiment.ConvectionHeaterOn();"
                }
            },
            off:{
                resistor:{
                    comment:"turn on resistor which will allow cooling",
                    code: "experiment.ConvectionHeaterOff();"
                }
            }
        },
        end:[
            {
                comment:"End the experiment",
                code: "experiment.EndExperiment();"
            },{
                className:'codeLowLight',
                code:'}'
            },{
                comment:`nothing is run continuously but Arduino code always requires a loop to be defined`,
                code:'void loop(){}'
            }
        ]
    },
    heatGround:{
        lowLights:['experiment.','{','}'],
        init:[
            { 
                comment:"import the custom experiment hardware library, remove the '2' for older kits",
                code:`#include <StarterKit2Control.h>`
            },{
                code:`volatile bool StarterKit2Control::IRQ_Flag;`
            },{
                comment: `remove this line for older hardware kits`,
                code:`volatile byte StarterKit2Control::_screen_Number;`
            },{
                comment:`create an instance of the library as "experiment, remove the '2' for older kits"`,
                code:`StarterKit2Control experiment;`
            },{
                comment:`code to be run once`,
                code:`void setup()`
            },{
                className:'codeLowLight',
                code:`{`
            },{
                code:`experiment.InitPlatform();`
            },{
                comment:`Wait for the start button to be pressed. Sensor data is displayed while we wait`,
                code:`while( ! experiment.StartButton() ){;}`
            },{
                comment:`Start collecting data and save it to the SD card`,
                code:`experiment.StartExperiment();`
            },{
                comment:`Begin experiment specific steps`
            }
        ],
        on:{
            fan:{
                comment:"turn on fan ",
                code: "experiment.FanOn();"
            },
            resistor:{
                comment:"turn on heater. Use the resistor",
                code: "experiment.HeaterOn();"
            },
            bulb:{
                comment:"turn on heater. Use the bulb",
                code: "experiment.HeaterOn();"
            }
        },
        off:{
            fan:{
                comment:"turn off fan",
                code:"FanOff();"
            },
            resistor:{
                comment:"turn off heater",
                code: "experiment.HeaterOff();"
            },
            bulb:{
                comment:"turn off heater.",
                code: "experiment.HeaterOff();"
            }
        },
        delay:{
            comment:"wait for defined number of seconds",
            code: (objStep)=>( `experiment.delay_sec( ${objStep.detail} );`)
        },
        convection:{},
        conduction:{},
        radiation:{},
        end:[
            {
                comment:"End the experiment",
                code: "experiment.EndExperiment();"
            },{
                className:'codeLowLight',
                code:'}'
            },{
                comment:`nothing is run continuously but Arduino code always requires a loop to be defined`,
                code:'void loop(){}'
            }
        ]
    },
    BMO:{
        
    }
};
export default codeMap;
