import React, { useContext,useEffect,useState } from 'react';
import axios from 'axios';
import { Layout,Row, Col,Table,Divider,Statistic,Button,Input,Modal,Form,message, notification, Tooltip, InputNumber } from 'antd';
import { UserOutlined,LockOutlined } from '@ant-design/icons';
import _ from 'lodash';
import Nav from '../components/nav.js';
import {Config} from '../config.js';
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';

const { Content, Header } = Layout;
const { Search } = Input;
var arrErrors=[];
const Admin = (props) => {
	const objUser = useContext(UserContext);
	
	const objFooter=function(){
		return(
				
				<span>
			      <Tooltip title="Creates new user"><Button onClick={ () => fnUpdate({...objTmp,"modalNew":true}) } >New User</Button></Tooltip>
			      { objTmp.selected && objTmp.selected.length > 0 &&
			      	<>
			      	  <Divider type="vertical" />
				      	<Tooltip title="Activates one or more existing users in case they failed to activate their account, so they can log in"><Button onClick={ () => fnBulk('Activate') } >Activate</Button></Tooltip>
				      <Divider type="vertical" />
				      	<Tooltip title="Resets one or more users' passwords by emailing them a link for self-reset."><Button onClick={ () => fnBulk('ResetPW') }>Reset PW</Button></Tooltip>
				      <Divider type="vertical" />
				      	<Tooltip title="Deletes all experiments and data for one or more users."><Button onClick={ () => fnBulk('ResetExperiments') }>Reset Experiments</Button></Tooltip>
				      <Divider type="vertical" />
				      	<Tooltip title="Deletes the launch credits used by a user, resetting their available credits back to their total coupon credits."><Button onClick={ () => fnBulk('ResetCredit') }>Reset Used Credits</Button></Tooltip>
				      <Divider type="vertical" />
				      	<Tooltip title="Deletes one or more users and all their data"><Button onClick={ () => fnBulk('Delete') }>Delete</Button></Tooltip>
			      	</>
					  // eslint-disable-next-line
			      }{ !objTmp.selected || objTmp.selected.length < 1 &&
			      	<>
			      	  <Divider type="vertical" />
				      	<Tooltip title="Activates one or more existing users in case they failed to activate their account, so they can log in"><Button disabled onClick={ () => fnBulk('Activate') } >Activate</Button></Tooltip>
				      <Divider type="vertical" />
				      	<Tooltip title="Resets one or more users' passwords by emailing them a link for self-reset."><Button disabled onClick={ () => fnBulk('ResetPW') }>Reset PW</Button></Tooltip>
				      <Divider type="vertical" />
				      	<Tooltip title="Deletes all experiments and data for one or more users."><Button disabled onClick={ () => fnBulk('ResetExperiments') }>Reset Experiments</Button></Tooltip>
				      <Divider type="vertical" />
				      	<Tooltip title="Deletes the launch credits used by a user, resetting their available credits back to their total coupon credits."><Button disabled onClick={ () => fnBulk('ResetCredit') }>Reset Used Credits</Button></Tooltip>
				      <Divider type="vertical" />
				      	<Tooltip title="Deletes one or more users and all their data"><Button disabled onClick={ () => fnBulk('Delete') }>Delete</Button></Tooltip>
			      	</>
			      }
			      
			    </span>
		    )
	};

	const arrColumns=[
		{
		  title: 'ID',
		  dataIndex: 'id',
		  key: 'id'
		},{
		  title: 'First',
		  dataIndex: 'first',
		  key: 'first'
		},{
		  title: 'Last',
		  dataIndex: 'last',
		  key: 'last'
		},{
		  title: 'Email',
		  dataIndex: 'email',
		  key: 'email'
		},{
		  title: 'Organization',
		  dataIndex: 'org',
		  key: 'org'
		},{
		  title: 'Admin',
		  dataIndex: 'role',
		  key: 'role'
		},{
		  title: 'Credits Used / Total',
		  dataIndex: 'credits',
		  key: 'credits'
		},{
		  title: 'Activated',
		  dataIndex: 'active',
		  key: 'active'
		},{
		  title: 'Action',
		  key: 'action',
		  render: (text, record) => (
		      <>
		      <Tooltip title="Add Credits">
		      	<Button shape="circle" onClick={ ()=>{ fnUpdate({...objTmp,"modalCredits":true}); fnUpdateForm({...objForm,"email":record.email})  } } >+</Button>
		      </Tooltip>
		      &nbsp;
		      <Tooltip title="Set password to admin-supplied password">
		      	<Button size="small" onClick={ ()=>{ fnUpdate({...objTmp,"modalPassword":true}); fnUpdateForm({...objForm,"email":record.email})  } } >Change Password</Button>
		      </Tooltip>
				&nbsp;
		      <Button size="small" id={record.id} onClick={ ()=>{fnImpersonate(record.id)} } >Impersonate</Button>
		     
		      </>
		  ),
		}
	];

	const [objTmp, fnUpdate] = useState({ "users":[],"searchUsers":[],"selected":[],"stats":[],"launches":[],"newDate":'',"newLocation":'In', "modalNew":false
		,"first":''
	    ,"last":''
	    ,"email":''
	    ,"pw":''
	    ,"confirm":''
	    ,"searchText":''
	});
	const [objForm, fnUpdateForm] = useState({
		"first":''
	    ,"last":''
	    ,"email":''
	    ,"pw":''
	    ,"confirm":''
	    ,"credits":4
	});
	const rowSelection = {
	  onChange: (selectedRowKeys, selectedRows) => {
	    //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
	    fnUpdate( {...objTmp,"selected":selectedRowKeys });
	  }
	};

	const fnImpersonate = function(v){
		//console.log('impersonate',e.target);
		axios.post(Config.api+'/api/impersonate',{"un":v ,"token":objUser.jwt})
	    .then(objResponse => {        	          
	      	  console.log(objResponse);
			  let arrBadges = ["welcome"];
			  if(objResponse.data.user.json){ var objJson=JSON.parse(objResponse.data.user.json); }
			  if(objJson && objJson.badges && objJson.badges.length > 1){ arrBadges=objJson.badges; }
			  let objUserData={"username":objResponse.data.user.first+' '+objResponse.data.user.last,"user":objResponse.data.user, "badges":arrBadges ,"jwt":objResponse.data.token,"role":objResponse.data.user.role};
			  objUser.fnUpdate(objUserData);
			  localStorage.removeItem('user');
			  localStorage.setItem('user', JSON.stringify(objUserData));
			  props.history.push('/mission');
	      
	    }).catch(err=>{});
	};

	const fnBulk=function(strAction){
		//console.log(strAction,objTmp.selected);
		axios.post(Config.api+'/api/bulkadmin',{"token":objUser.jwt,"action":strAction,"targets":objTmp.selected})
	    .then(objResults => {        	          
	      if(objResults !== null){
	      	//console.log(objResults);
	      	//fnUpdate( {...objTmp,"users":objResults.data} );
	      	notification.open({
			    message: 'Admin action received',
			    description:
			      objResults.data,
			    
			  });
	      }
	      fnInit();
	    }).catch(err=>{
	   });
	};

	const fnSetPassword=function(e){
		//e.preventDefault();
		//console.log(objForm.email, objForm.pw, objForm.confirm);
		if(objForm.pw.length > 3 && objForm.pw === objForm.confirm){
			//do it
			axios.post(Config.api+'/api/adminsetpw',{"d":objForm, "token":objUser.jwt}).then(
				objResults=>{
					if(objResults.data === 'password reset'){
						notification.open({
						    message: 'Password reset successfully',
						    description:
						      'The password was reset, activation codes removed and user set to active'
						  });
					}
				}
			);
		}else{
			message.info('The password must be longer than 5 characters and be confirmed');
		}
	}

	const fnValidate=function(strField,strValue){
		var strError=false;
	    if( ['first','last','email','pw','confirm'].indexOf(strField) > -1 && strValue.length === 0){ strError='Please fill in value'; }
	    else if( strField==='pw' && strValue.length < 5){ strError='A password needs to be longer than 5 characters'; }
	    else if( strField==='confirm' && strValue !== objForm.pw){ strError='Password confirmation doesn\'t match' ; }
	    
	    if(strError){ 
	      //console.log('field error');
	      fnUpdate({...objTmp,strField:strError});
	      console.log("fnValidate",strField,strValue,strError);
	      return strError;
	    }else{ 
	      console.log('field ok');
	      objForm[strField]=strValue;
	      fnUpdate({...objTmp,strField:false});
	      return false;
	    }
	}
	const fnSubmitNew=function(e){
		//e.preventDefault();
	    //one last check over every field
	    var arrKeys=Object.keys(objForm); var ok=true;
	    for(var i=0; i < arrKeys.length; i++){ 
	      var strError=false;
	      strError=fnValidate(arrKeys[i],objForm[arrKeys[i]]); 
	      
	      if(strError){ message.error(strError + ' : ' + arrKeys[i]); ok=false; break;}
	    }
	    for(i=0; i < arrKeys.length; i++){ if(objTmp[arrKeys[i]]){ ok=false; break; } }
	    console.log(objTmp,strError,ok,arrErrors);
	    if(ok && !strError)
	      //console.log(objForm);
	      axios.post(Config.api+'/api/useraddactive',{"d":objForm, "token":objUser.jwt})
	      .then(objResponse => {        
	        //console.log('fnSubmit useradd: ',objResponse);
	        arrErrors=objResponse.data.user.errors;
	        //console.log('errors?',arrErrors);
	        if(arrErrors && arrErrors.length>0){
	          for( var i=0; i < arrErrors.length; i++){ message.error(arrErrors[i]); }
	        }
	    	if(!arrErrors || arrErrors.length===0){
	        	console.log('ok');
	          message.info('new user created for: '+objForm.email);
	          fnUpdate({...objTmp,"modalNew":false});
	        }
	        fnInit();
	        
	      }).catch(err=>{
	        console.log(err);
	      });
	}


	const fnFilterUsers=function(strSearch){
		fnUpdate({...objTmp,"searchUsers":fnFilterUsersArray(objTmp.users,strSearch),"searchText":strSearch});
	}
	const fnFilterUsersArray=function(arrInput,strSearch){
		console.log('filter',arrInput,strSearch);
		var arrUsers = arrInput;
		if(strSearch && strSearch.length > 0){
			arrUsers = _.filter(arrUsers, function(o) { 
				return o.first.includes(strSearch) || o.last.includes(strSearch) || o.email.includes(strSearch); 
			});
		}
		return arrUsers;
	}

	const fnAddCredits=function(e){
		//console.log('fnAddCredits',objForm.email, objForm.credits);
		
		axios.post(Config.api+'/api/addcredits',{"d":objForm, "token":objUser.jwt}).then(objResponse=>{
			if(objResponse.data === 'credits added'){
				notification.open({
				    message: 'Added '+ objForm.credits + ' credits successfully',
				    description:
				     'The credits were added to a new coupon and associated to the user'
				  });
				fnUpdate({...objTmp,"modalCredits":false});
			}
		});
	};

	const fnInit=function(){
		axios.post(Config.api+'/api/userlist',{"token":objUser.jwt})
		        .then(objResults => {        	          
		          if(objResults !== null){
		          	fnUpdate({...objTmp,"users":objResults.data.users,"searchUsers":fnFilterUsersArray(objResults.data.users,objTmp.searchText), "stats":objResults.data.stats});
		          }
		        }).catch(err=>{
		       });
	}

		useEffect(() => {
		    fnInit();
			// eslint-disable-next-line
	 	},[objUser.user]);

  return (
	<Layout>
	<Header>
		<Nav/>
	</Header>
	  <Content style={{padding:"12px"}}>
      	<div className="container">
      		{ objTmp.stats[0] &&
      		<Row>
      			<Col span={6}> <Statistic title="Active Users" value={objTmp.stats[0].users} /> </Col>
      			<Col span={6}> <Statistic title="Experiments" value={objTmp.stats[0].experiments} /> </Col>
      			<Col span={6}> <Statistic title="Claimed Coupons" value={objTmp.stats[0].claimed} /> </Col>
      			<Col span={6}> <Statistic title="Unclaimed Coupons" value={objTmp.stats[0].unclaimed} /> </Col>
      		</Row>
      	}
      	</div>
      	<div className="container"> 
      		<Search placeholder="Find a user" onSearch={strValue => fnFilterUsers(strValue) } enterButton />
      	{	objTmp.users.length && objTmp.users.length > 0 &&
      		
      		<Table rowKey="id" dataSource={objTmp.searchUsers} columns={arrColumns} rowSelection={rowSelection} footer={objFooter} pagination={{ defaultPageSize:8, showSizeChanger: true, pageSizeOptions: ['8', '20', '50', '100']}}/>
      	}
      	</div>
      	<Modal
            title="New User"
            visible={objTmp.modalNew}
            onCancel={ () => fnUpdate({...objTmp,"modalNew":false}) }
            footer={null}
          >
            <Form onFinish={fnSubmitNew} className="login-form">
              <Form.Item>
                  <div className="inputLabel">First name*</div>
                  
				  <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} placeholder="" 
                  onBlur={e =>fnValidate('first',e.target.value) } />
                  { objTmp.first !== '' &&
                    <div className="inputError"> {objTmp.first} </div>
                  }
              </Form.Item>
              <Form.Item>
                  <div className="inputLabel">Last name*</div>
                  <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} placeholder="" 
                  onBlur={e =>fnValidate('last',e.target.value) } />
                  { objTmp.last &&
                    <div className="inputError"> {objTmp.last} </div>
                  }
              </Form.Item>
              <Form.Item>
                  <div className="inputLabel">Email*</div>
                  <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} placeholder="" 
                  onBlur={e =>fnValidate('email',e.target.value) } />
                  { objTmp.email &&
                    <div className="inputError"> {objTmp.email} </div>
                  }
              </Form.Item>
              <Form.Item>
                  <div className="inputLabel">Create Password*</div>
                  <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="" 
                  onBlur={e =>fnValidate('pw',e.target.value) } />
                  { objTmp.pw &&
                    <div className="inputError"> {objTmp.pw} </div>
                  }
              </Form.Item>
              <Form.Item>
                  <div className="inputLabel">Confirm Password*</div>
                  <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="" 
                  onBlur={e =>fnValidate('confirm',e.target.value) } />
                  { objTmp.confirm &&
                    <div className="inputError"> {objTmp.confirm} </div>
                  }

              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="fullWidth b" span={6}>
                  Create User
                </Button>
              </Form.Item>
              
            </Form>
        </Modal>
        <Modal
            title="Set Password"
            visible={objTmp.modalPassword}
            onCancel={ () => fnUpdate({...objTmp,"modalPassword":false}) }
            footer={null}
          >
          <Form onFinish={fnSetPassword} className="login-form">
              <Form.Item>
                  <div className="inputLabel">Create Password*</div>
                  <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="" 
                  onBlur={e =>fnValidate('pw',e.target.value) } />
                  { objTmp.pw &&
                    <div className="inputError"> {objTmp.pw} </div>
                  }
              </Form.Item>
              <Form.Item>
                  <div className="inputLabel">Confirm Password*</div>
                  <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="" 
                  onBlur={e =>fnValidate('confirm',e.target.value) } />
                  { objTmp.confirm &&
                    <div className="inputError"> {objTmp.confirm} </div>
                  }

              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="fullWidth b" span={6}>
                  Set Password
                </Button>
              </Form.Item>
            </Form>
        </Modal>
        <Modal
            title="Add Credits"
            visible={objTmp.modalCredits}
            onCancel={ () => fnUpdate({...objTmp,"modalCredits":false}) }
            footer={null}
          >
          <Form onFinish={fnAddCredits} className="login-form">
              <Form.Item>
                  <div className="inputLabel">Add Credits for user</div>
                  <InputNumber min={1} max={100} defaultValue={4} onBlur={e =>fnUpdateForm({...objForm,"credits":e.target.value}) } />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="fullWidth b" span={6} >
                  Add Credits
                </Button>
              </Form.Item>
            </Form>
        </Modal>
      </Content>
	  </Layout>
    );
};

export default Admin;
