import React, { useEffect } from "react";
import { Row, Col, Button } from 'antd';
//import {UserContext} from '../context.js';
import { Link } from 'react-router-dom';

const NotNavMenu = (props) => {
//const context = useContext(UserContext);

useEffect(() => {
  
});

  return(
      <Row>
          <Col span={12}>
          	<Link to="/"><img id="logo"alt="Quest for Space" src="./img/QuestForSpaceWhite.svg"></img></Link>
          </Col>
          <Col span={12} className="rightContent">
            <Link to="/schools"><Button className="notnav-btn" type="link"  > Schools </Button></Link>
            <Button className="notnav-btn" type="link" href="/login"  > Login </Button>
            <Button href="/register" type="primary" style={{"fontWeight":'bold'}}> Signup</Button>
          </Col>
        </Row>
  	);
};

export default NotNavMenu;