import React, { } from 'react';
import { Collapse,Layout,Row, Col } from 'antd';
import Nav from '../components/nav.js';
const { Header,Content } = Layout;
const Panel = Collapse.Panel;

const Help = (props) => {
  return (
	<Layout style={{height:"100%",overflowY:"hidden"}}>
	<Header>
	  <Nav/>
	</Header>
	  <Content>
        <Layout>
          	<div className="container">
          	<Row>
          		<Col span={14} offset={5} >
          			<h2>FAQ</h2>
          		</Col>
          	</Row>
          	<Row>
          		<Col span={14} offset={5} >
		          <Collapse defaultActiveKey={['1']} >
				    <Panel header="1.  Is my experiment really run in space?" key="1">
				      <p>Yes!  Quest for Space has flight hardware housed in the U.S Laboratory capsule on the ISS.  Your custom experiment is uploaded to the ISS and ran.</p>
				    </Panel>
				    <Panel header="2. How does the hardware in space different than my kit?" key="2">
				      <p>The hardware in space meets all the safety requirements of NASA as well as providing monitoring and backup systems.  The flight unit runs all the same experiments as you run on your ground kit.  Data results may be slightly different due to different ambient temperatures (its hotter!) but the science is the same.</p>
				    </Panel>
				    <Panel header="3. How long does it take to get the results of my experiment back?" key="3" >
				      <p>This depends on when you submit your experiment and when the next set of experiments is uploaded.  Due to safety restrictions, NASA requires that a human operator uploads, executes, and downloads the data.  Because of this, we try to back experiments and do one upload and download per week.</p>
				    </Panel>
				    <Panel header="4. How much can I customize my experiment?" key="4" >
				      <p>On the ground, you can customize the hardware in your kit however you like, but for the space experiment, we are assuming you are setting up each experiment as described in the tutorials.  When customizing your own code, you can feel free to customize when the heater or fan turns on/off and for how as well as any delays in between.</p>
				    </Panel>
				    <Panel header="5. Does an astronaut run my experiment?" key="5" >
				      <p>Astronauts are very busy with almost every minute of every day they are up on the ISS scheduled they do not have time to run our experiments.  We have designed the system to be remotely controlled from earth.</p>
				    </Panel>
				    <Panel header="6. Why is the data from my kit different from the space data?" key="6" >
				      <p>First of all, the space data is from a micro-gravity environment so we expect to see some differences.  Second, the hardware we run in space has some differences that were required to meet NASA's strict safety requirements.  Finally, the ambient conditions are different on board the ISS. </p>
				    </Panel>
				    <Panel header="7. How much does an experiment credit cost?" key="7" disabled>
				      <p>TBD</p>
				    </Panel>
				    <Panel header="8. What payment methods do you accept?" key="8" >
				      <p>All major credit cards.</p>
				    </Panel>
				    <Panel header="9. What is your refund policy?" key="9" disabled>
				      <p>TBD</p>
				    </Panel>
				    <Panel header="10. How can I get in touch with your support team?" key="10" >
				      <p>Send us a email at: support@questforspace.com</p>
				    </Panel>
				    <Panel header="11. I'm having trouble with my SpaceKit product. What should I do?" key="11" >
				      <p>Please review the troubleshooting guide here.</p>
				    </Panel>
				    <Panel header="12. I forgot to add my promo credit. What should I do?" key="12" disabled>
				      <p>TBD</p>
				    </Panel>
				  </Collapse>
				</Col>
			</Row>
			</div>
        </Layout>
      </Content></Layout>
    );
};

export default Help;