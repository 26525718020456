/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext, useState, useEffect } from 'react';
import { Layout,PageHeader, Button, Space, Modal, Input, Form, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Nav from '../components/nav.js';
import exp from '../experimentHelper';
import ExperimentNav from '../components/experimentnav.js';
import axios from 'axios';
import {Config} from '../config.js';
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
const { Header, Content, Sider } = Layout;

/*----====|| COMPONENT START || ====----*/
const ExperimentLayout = (props) => {
    const objUser = useContext(UserContext);
    //const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/

/*----====|| STATES || ====----*/
    const [objTmp, fnUpdate] = useState({
      showEdit:false,
      showDelete:false,
      name:''
    });
/*----====|| LOGIC || ====----*/
const fnNext=function(){
  const objProgress = exp.fnNextStep(objUser.experiment,props.step);
  exp.fnSave(objUser);
  //console.log(objProgress);
  props.history.push(objProgress.next);
}
const fnRename=function(){
  var objExperiment=objUser.experiment;
  objExperiment.name=objTmp.name;
  objUser.fnUpdate({...objUser,experiment:objExperiment});
  exp.fnSave({...objUser,experiment:objExperiment});
  fnUpdate({...objTmp,showEdit:false});
};
const fnDeleteExp=function(){
  axios.post(Config.api+'/api/expdelete',{"d":objUser.experiment.id,"token":objUser.jwt})
    .then(res => {        
      notification.open({message:'Experiment deleted'});
      fnUpdate({...objTmp,showEdit:false});
      props.history.push('/mission');
    }).catch(err=>{
      console.log(err);
    });
};
/*----====|| HOOKS || ====----*/
useEffect(() => {
      if(objUser && objUser.jwt && !objUser.username){
          console.log(objUser);
          props.history.push('/login');
      }
      // eslint-disable-next-line
},[objUser.jwt]);
/*----====|| RENDER || ====----*/
return(
    <Layout style={{height:"100%",overflowY:"hidden"}}>
      <Header>
        <Nav/>
      </Header>
      <Content>
        <Layout>
          <Sider theme="light">
            <ExperimentNav/>
          </Sider>
          <Content>
            <PageHeader 
              title={<Space>
                Experiment: {objUser?.experiment?.name} ({objUser.experiment?.id})
                  <Button size="small" onClick={()=>fnUpdate({...objTmp,showEdit:true})}> edit</Button>
                  <DeleteOutlined onClick={()=>fnUpdate({...objTmp,showDelete:true})} style={{cursor:"Pointer",color:"#FB9C25"}} />
                </Space>}
              extra={
                <Button 
                  onClick={ fnNext } 
                  disabled={ !props.next }
                >
                  { ['analyze','play'].indexOf(props.step) > -1?(<>Complete Experiment</>):(<>Next</>)}
                </Button>
              }
            />
            <div style={{padding:"12px"}}>
              {props.children}
            </div>
          </Content>
        </Layout>
      </Content>
      <Modal 
        title="Edit Experiment"
        onCancel={ ()=>fnUpdate({...objTmp,showEdit:false}) }
        onOk={ fnRename }
        visible={objTmp.showEdit}>
            <Form 
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}>
              <Form.Item label="experiment name">
                <Input defaultValue={objTmp?.name?objTmp.name:objUser.experiment?.name} onChange={ (e)=>fnUpdate({...objTmp,name:e.target.value}) } ></Input>
              </Form.Item>
            </Form>
      </Modal>
      <Modal 
        title="Delete Experiment"
        onCancel={ ()=>fnUpdate({...objTmp,showDelete:false}) }
        onOk={()=>{ fnDeleteExp() } }
        visible={objTmp.showDelete}>
            Are you Sure you want to permanently delete this experiment?
      </Modal>
    </Layout>
);

};

export default ExperimentLayout;