/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import { Menu, Row, Col } from 'antd';
import NavProfile from "./navProfile.js";
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/

/*----====|| COMPONENT START || ====----*/
const Nav = (props) => {
    const objUser = useContext(UserContext);
    //const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/

/*----====|| STATES || ====----*/
    //const [objTmp, fnUpdate] = useState({});
/*----====|| LOGIC || ====----*/

/*----====|| HOOKS || ====----*/
//useEffect(() => {},[]);
/*----====|| RENDER || ====----*/
return(
      <Row>
        <Col span={4}>
          <Link to="/"><img id="logo"alt="Quest for Space" src="./img/QuestForSpaceWhite.svg"></img></Link>
        </Col>
        <Col span={12} align="center">
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1"><Link to="/mission"> Mission Control</Link></Menu.Item>
            <Menu.Item key="2"><Link to="/help">Help Center</Link></Menu.Item>
            { objUser.user && objUser.user.role === 100 &&
              <>
                <Menu.Item key="3"><Link to="/admin"> User Admin</Link></Menu.Item>
                <Menu.Item key="4"><Link to="/adminLaunch"> Launch Admin</Link></Menu.Item>
                <Menu.Item key="5"><Link to="/adminPromo">Promo Admin</Link></Menu.Item>
                <Menu.Item key="6"><Link to="/adminConfig">Config</Link></Menu.Item>
              </>
            }
          </Menu>
        </Col>
        <Col span={8} className="rightContent">
          <NavProfile history={props.history} />
        </Col>
      </Row>
);

};

export default Nav;