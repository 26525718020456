
import { useRef, Suspense, useCallback, useState, useEffect} from "react";
import { Form, Input, Row, Col, Button, Layout, message } from 'antd';
import axios from 'axios';
import {UserOutlined} from '@ant-design/icons';
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls,useGLTF, useTexture } from '@react-three/drei';
import Webcam from "react-webcam";
import NotNavMenu from '../components/notnavmenu.js';
import {Config} from '../config.js';
import { useLocation } from 'react-router-dom';
const queryString = require('query-string');
const BMO3D = (props) => {
  let location = useLocation();
  const objParams = queryString.parse(location.search);
  const [objTmp,setTmp] = useState({
    token:null,
    holdSubmit:true,
    promo:null,
    image:''
  });
  const [image,setImage]=useState('/img/Landing2.jpg');  
  const webcamRef = useRef(null);
  const { Header, Content } = Layout;
  const [refForm] = Form.useForm();

function Bmo(props){
  const ref = useRef();
  const gltf = useGLTF('/img/bmo.glb', true)
  const [colorMap] = useTexture([image]);
  colorMap.flipY=false;
  gltf.nodes.screen.material.map = colorMap;
  const [rx] = useState(() => Math.random() * 100000)
  const [ry] = useState(() => Math.random() * 100000)
  useFrame((_) => {
    ref.current.position.y = Math.sin(_.clock.elapsedTime + ry);
    ref.current.rotation.x = Math.sin(_.clock.elapsedTime + ry)/10;
    ref.current.position.x = Math.sin(_.clock.elapsedTime + rx);
    ref.current.rotation.y = Math.sin(_.clock.elapsedTime + rx)/10;
    const objLeftArm = ref.current.children[0].children.filter(objNode=>objNode.name === 'leftArm');
    const objRightArm = ref.current.children[0].children.filter(objNode=>objNode.name === 'rightArm');
    //console.log(objLeftArm);
    objLeftArm[0].rotation.x = Math.sin(_.clock.elapsedTime + ry)/5+1;
    objRightArm[0].rotation.x = Math.sin(_.clock.elapsedTime + ry)/5+1;
    //ref.current.children[0].children[10].rotation.x = Math.sin(_.clock.elapsedTime + ry)/5+1;
  });
  return(
    <primitive ref={ref} object={gltf.scene} position={[0,0,-60]} rotation={[0,0,0]}/>
  );
}

const capture = useCallback(
  () => {
    const imageSrc = webcamRef.current.getScreenshot();
    axios.post(Config.api+'/api/BMOImage',{image:imageSrc, token:objTmp.token}).then(objResults => {
      if(objResults !== null && objResults.data.success){
        //console.log(objResults.data)
        message.success('Image uploaded to server', 6);
        setImage(imageSrc);
        setTmp({...objTmp, holdSubmit:false, image:objResults.data.filename});
      }
    }).catch(err=>{
      message.error('something went wrong your image couldn\'t be uploaded', 6);
      console.log(err);
    });
  },
  [webcamRef]
);

const fnSubmit=function(objForm){
  if(!objForm.message || !objForm.email || objTmp.image === ''){ 
    message.error('Please fill in all fields and capture a webcam image to submit');
  }else{
    axios.post(Config.api+'/api/promo',{...objTmp, ...objForm, token:objTmp.token, promo:objTmp.promo})
	    .then(objResults => {        	          
	      if(objResults !== null && objResults.data.success){
	      	setImage('/img/Landing2.jpg');  
          message.success('We are preparing your image and message for space. A BMO video will be sent to you within 1-2 days.', 6);
          refForm.resetFields();
          setTmp({...objTmp, holdSubmit:true, image:''});
	      }else{
          if(objResults.data.error === 'ER_DUP_ENTRY'){
            message.error(`It appears that we already have a submission from this email`, 6);
          }
        }
	    }).catch(err=>{
        message.error('something went wrong your experiment couldn\'t be submitted', 6);
	  });
  }
};

const checkPromo = function(strPromo){
  axios.post(Config.api+'/api/promoCheck',{promo_code:strPromo, token:objTmp.token})
	  .then(objResults => {        	          
      if(objResults !== null && objResults.data.success === true){ 
        message.success('Promo code valid and applied', 6);
        setTmp({...objTmp, promo:objResults.data.id});
	    }else{
        message.error(`Promo code not found or expired`, 6);
        setTmp({...objTmp, holdSubmit:true});
      }
	  }).catch(err=>{
        message.error(`Promo code not found or expired`, 6);
        setTmp({...objTmp, holdSubmit:true});
	});
}

useEffect(()=>{
  if(!objTmp.token){
    axios.get(Config.api+'/api/getToken')
    .then(objResponse => {        
      setTmp({...objTmp, token:objResponse.data.token})
    }).catch(err=>{
      console.log('getToken to validate future requests with',err);
  });
  }
  //just in case the other key is used.
  if(!objParams.promo && objParams.promo_code){
    objParams.promo = objParams.promo_code;
  }
  if(objTmp.token && objParams.promo && !objTmp.promo){
    checkPromo(objParams.promo);
  }
  if(!objParams.promo){
    message.error(`No promo code provided. please use a promo code url`, 6);
    setTmp({...objTmp, holdSubmit:true});
  }
},[objTmp.token]);

function Scene(){
    return(
      <>
        <ambientLight intensity={0.1} />
        <pointLight position={[70, 70, 10]} intensity={3} decay={0} />
        <Suspense fallback={<>loading</>}>
          <Bmo />
        </Suspense>
      </>
    );
  }

return(
  <Layout style={{minHeight:'100%'}}>
      <Header>
        <NotNavMenu history={props.history} />
      </Header>
      <Content style={{padding:"24px", background: 'url("./img/starbg.svg")', backgroundColor: 
      '#212F6E', minHeight:'100%'}}>
      <Row style={{fontSize:"24px", color:"#fff", fontWeight:"bold", padding:"24px"}} align="center">
        Send a webcam image and message to BMO in space! We will email you a video when it reaches orbit.
      </Row>
        <Row gutter={24}>
          <Col flex="500px" align="right">
            <Row ><Col flex={1} align="center">
              <Webcam
                audio={false}
                height={300}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={400}
                onUserMediaError={(e)=>{message.error('Something went wrong trying to capture Webcam image. Check permissions. The browser needs permission to use the webcam per website.')}}
                videoConstraints={{
                  width: 410,
                  height: 310,
                  facingMode: "user"
                }}
              />
            </Col></Row>
            <Row><Col flex={1} align="center">
              <Button onClick={(e)=>{
                e.preventDefault();
                capture();
              }}>
                Capture Webcam image
              </Button>
            </Col></Row>
            <Form form={refForm} className="darkForm" style={{marginTop:"24px"}} onFinish={fnSubmit}>
              <Form.Item label="Email" name="email">
                <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="email@domain.tld" />
              </Form.Item>
              <Form.Item name="message" label="Message">
                <Input.TextArea maxLength="175" />
              </Form.Item>
                <Button type="primary" htmlType="submit" className="fullWidth b" span={6} disabled={objTmp.holdSubmit}>
                  Send to Space!
                </Button>
              
            </Form>
          </Col>
          <Col flex={1} >
            <Canvas style={{height:"600px", background:"url('/img/space_background.jpg')", backgroundSize:"cover", borderRadius:"12px"}}>
                  <Scene>

                  </Scene>
                  <OrbitControls />
            </Canvas>
          </Col>
        </Row>
      <Row className="blueRow"  style={{background: 'url("./img/starbg.svg")'}} align="middle">
        
      </Row>
      </Content>
      
  </Layout>
 
);

};

export default BMO3D;
