/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext,useEffect,useState } from 'react';
import { Button, Row, Col, Statistic, Card, notification } from 'antd';
import axios from 'axios';
import Moment from 'moment';
import exp from '../experimentHelper';
import {Config} from '../config.js';
/*----====|| CONTEXT || ====----*/
import {UserContext,ConfigContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
const { Countdown } = Statistic;
/*----====|| COMPONENT START || ====----*/
const RunSpace = (props) => {
/*----====|| DEFAULTS || ====----*/

/*----====|| STATES || ====----*/
    const objUser = useContext(UserContext);
    const objConfig = useContext(ConfigContext);    
    const [objTmp, fnUpdate] = useState({});
    
/*----====|| LOGIC || ====----*/
const fnSubmit=function(){
    var objExperiment=objUser.experiment;
    if(objExperiment.location==='In' || objExperiment.location==='Out'){ 
      if(objUser.user.credits > 0){ objUser.user.credits--; }
      else{ notification.open({message:'You don\'t have enough credits to launch and experiment into space'}); return; } 
    }
    if(objExperiment.status==='Launching'){ notification.open({message:'This experiment was already sent'}); return; }
    objExperiment.status='Launching';
    objExperiment.phase='Run';
    if(objExperiment.location==='Sim'){ objExperiment.phase='Running on Simulator'; }
    axios.post(Config.api+'/api/expsend',{"d":objExperiment,"token":objUser.jwt})
      .then(res => {        
        notification.open({message:'Experiment submitted for next launch'});
        
        var objNext=exp.fnNextStep(objExperiment,'run',objConfig);
        console.log(objNext);
        
        exp.fnSave({
          ...objUser,
          experiment:objNext.experiment
        });
        
        props.history.push('/mission');

      }).catch(err=>{
        console.log(err);
      });
  };
/*----====|| HOOKS || ====----*/
useEffect( ()=>{
  //console.log(objUser)
  if( objUser.launches ){
        for(var i=0;i < objUser.launches.length; i++){
          if(objUser.launches[i].location===objUser.experiment.location){ var objLaunch = Moment(objUser.launches[i].nextDate).toDate(); 
            fnUpdate({...objTmp,"launch":objLaunch});
            console.log(objLaunch,objTmp.launch);
          }
        }
      }else{ console.log('no launches found'); }
},[objUser.launches, objTmp, objUser.experiment.location] );
/*----====|| RENDER || ====----*/
if(props.data){
    return(
        <Card 
            title="Launch Status" 
            style={{textAlign:"left"}}
            actions={[
                props.data.status==='Launching'?(
                    <Button onClick={() => props.history.push('/mission')} >Return to Mission Control</Button>
                ):(
                    <Button key="no" size="large" style={{"marginTop":'20px',"marginRight":'20px'}} 
                        onClick={() => props.history.push('/mission')} 
                        >No, Cancel</Button>,
                      <Button key="yes" size="large" style={{"marginTop":'20px'}}  type="primary" 
                        onClick={fnSubmit}
                        >Yes, launch!</Button>
                )
            ]}
        >
                  { props.data.status !== 'Launching' && objUser.user.credits &&
                    <Row >
                        <Col span={12} offset={6} align="center">
                          <h3>
                          This experiment needs to be run by Quest for Space. <br/>
                          Are you sure want to submit experiment: <b>{props.data.name}</b> 
                          { props.data.location !== 'Sim' &&
                            <>
                                <br/>to space ?<br/>
                                This will spend one of your purchased credits.
                            </>
                          }{ props.data.location === 'Sim' &&
                            <>
                                <br/> for simulation ?<br/>
                                It doesn't require a credit, but you can't go back and change it.
                            </>
                          }
                          </h3>
                      
                          </Col>
                    </Row>
                  }{ props.data.status !== 'Launching' && !objUser.user.credits &&
                  <Row >
                  <Col span={12} offset={6} align="center">
                    <h3>
                      You don't have enough credits to run a remote experiment ( in space or simulation )<br/>
                      Please purchase credits or run a ground experiment.
                    </h3>
                
                    </Col>
              </Row>
                  }{ props.data.status === 'Launching' &&
                  <Row>
                      <Col 
                        span={12} offset={6} align="center">
                        <h3>
                        Your experiment has been submitted<br/>and is ready to be launched <br/>
                        </h3>
                        <Countdown title="Countdown to launch" value={objTmp.launch} format="D HH:mm:ss" />
                      <h3>This will update when the data has returned</h3>
                      </Col>
                  </Row>
                  }
                </Card>
    );
    
}

};

export default RunSpace;