import React, { useContext,useState,useEffect } from 'react';
import { Input, Row, Col, Button, Layout } from 'antd';
import { Link } from 'react-router-dom'
import ExperimentNav from './experimentnav.js';
import {UserContext} from '../context.js';
const { Header, Content,Sider } = Layout;
const { TextArea } = Input;

const BuildVideo = (props) => {
const context = useContext(UserContext);
const [objForm, fnUpdate] = useState({});

const fnFilterWords=function(strText){
  var arrFun=['cock', 'pussy', 'fuck', 'fucker', 'motherfucker', 'shit', 'dick', 'cunt', 'bitch', 'slut', 'cuck'];
  var arrWords=strText.split(' ');
  for(var i=0;i<arrWords.length;i++){
    for(var ii=0; ii< arrFun.length; ii++){
      if(arrWords[i].toUpperCase() === arrFun[ii].toUpperCase()){ arrWords[i]='***'; }
    }
  }
  return arrWords.join(' ');
};


  const fnUpdateField = function(k,v){ 
    fnUpdate({ ...objForm, [k]: v});
    var objUpdate=context.experiment;
    if(k==='lcd'){ objUpdate.lcd=v; }
    else{ objUpdate.questions[k]=v; }
    context.fnUpdateField(objUpdate);
  }
const fnDone = function(){
    var objNext=context.fnProgress('buildVideo');
    if(objNext && objNext.path){ props.history.push(objNext.path); }
    else{ props.history.push('/mission'); }
    
  };

  const fnClearDefault = function(){
    console.log('clear');
    if(context.experiment.lcd && context.experiment.lcd==='message'){ 
      var objExperiment = context.experiment;
      context.experiment.lcd='';
      context.fnUpdateField('experiment',objExperiment); 
    }
  }

useEffect(() => { if(!context.experiment.lcd && context.experiment.type==='Video'){ 
    console.log('its a video but message not found');
    var objExperiment=context.experiment;
    objExperiment.lcd='message';
    fnUpdateField('experiment',objExperiment); } 
  // eslint-disable-next-line
  },[]);
  return (
        <Content>
        <Layout>
          <Header className="subHeader">
            <Row>
              <Col span={12}>
                <h3>Build Experiment</h3>
              </Col>
              {/* eslint-disable-next-line */}
              { ['Sim','In'].indexOf(context.experiment.location) < 0 || context.experiment.sequence.phases[1].steps[0].complete !== false &&
                <Col span={9} className="rightContent">
                  <Button type="primary" > Save </Button>
                  <Button icon="check-circle" 
                    disabled={ !context.experiment.steps }
                    onClick={ () => fnDone() }>
                     Next
                  </Button>
                </Col>
              }
              <Col id="closeSubMenu" span={2} className="rightContent" >
                <Link to="/mission">
                Close builder
                </Link>
              </Col>
            </Row>
          </Header>
          <Layout>
            <Content className="largeContent">
                  <div> Write a message to be displayed in space on the ISS! Your message will be captured on a 30 seconds video recording and sent back to you.</div>
                  <h3>Video message</h3>
                  <br/><br/>
                  { context.experiment &&
                  <Row>
                    <Col id="videoInput" span={7} offset={5} >
                      What message do you want your video to say?
                      <TextArea 
                        value={ context.experiment.lcd } 
                        onChange={ e => fnUpdateField('lcd',fnFilterWords(e.target.value)) } 
                        onFocus={ e => fnClearDefault() }
                        />
                      { context.experiment.lcd &&
                      	<div id="blueNote">Character limit { context.experiment.lcd.length }/960 </div>
                      }
                    </Col>
                    <Col id="videoPreview" span={7}>
                      <div id="videoPreviewMessage">{context.experiment.lcd}</div>
                    </Col>
                  </Row>
              		}
            </Content>
            <Sider theme="light">
            	<ExperimentNav history={props.history}/>
            </Sider>
          </Layout>
        </Layout>
      </Content>

      
    );
};

export default BuildVideo;
