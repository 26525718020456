/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext,useEffect,useState } from 'react';
import { Card, Row, Col,notification } from 'antd';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import ExperimentLayout from '../components/experimentLayout';
import axios from 'axios';
import {Config} from '../config.js';
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';
/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
//const { Content } = Layout;

/*----====|| COMPONENT START || ====----*/
const Login = (props) => {
    const objUser = useContext(UserContext);
    //const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/
let objBarChart,objLineChart,fnCheckBar,fnCheckLine;

/*----====|| STATES || ====----*/
const [objTmp, fnUpdate] = useState({
  loaded:false,
  lineOptions:{},
  barOptions:{}
});
const objExperiments={
    "Heat":['bulb state','Heater','conv state','cond state','S_Bulb', 'S_Cond', 'S_Conv'],
    "Radiation":{
        "stats":[
           {"name":'Black',"k":'black',"color":'#415BDB'}
          ,{"name":'White',"k":'white',"color": '#FB9C25'}
          ,{"name":'Black',"k":'PortA[degC]',"color":'#415BDB'}
          ,{"name":'White',"k":'PortB[degC]',"color": '#FB9C25'}
          ,{"name":'Black',"k":'T_Black',"color":'#415BDB'}
          ,{"name":'White',"k":'T_White',"color": '#FB9C25'}
        ]
    },
    "Convection":{
        "stats":[
           {"name":'Top',"k":'top',"color":'#415BDB'}
          ,{"name":'Bottom',"k":'bottom',"color": '#FB9C25'}
          ,{"name":'Top',"k":'PortA[degC]',"color":'#415BDB'}
          ,{"name":'Bottom',"k":'PortB[degC]',"color": '#FB9C25'}
          ,{"name":'Top',"k":'T_Top',"color":'#415BDB'}
          ,{"name":'Bottom',"k":'T_Base',"color": '#FB9C25'}
          ]
    },
    "Conduction":{
        "stats":[
           {"name":'Conduction A',"k":'cond A',"color":'#415BDB'}
          ,{"name":'Conduction B',"k":'cond B',"color": '#FB9C25'}
          ,{"name":'Conduction A',"k":'PortA[degC]',"color":'#415BDB'}
          ,{"name":'Conduction B',"k":'PortB[degC]',"color": '#FB9C25'}
          ,{"name":'Conduction A',"k":'T_CondA',"color":'#415BDB'}
          ,{"name":'Conduction B',"k":'T_CondB',"color": '#FB9C25'}
          ]
    }
  };
  let lineOptions={
    "credits":false,
    "title": {
       "text": 'Heat source'
      ,"align":'left'
      ,"floating":false
    },"legend":{
       "enabled":false
    },
    "chart": {
        "height": 200,
        "type": 'area',
    },
    "yAxis":{"min":-1, "max":1},
    "series": objUser.experiment.data.states
  };
  let barOptions={
    "credits":false,
    "title": {
    "text": objUser.experiment.type
    ,"align":'left'
    ,"floating":true
    },"yAxis":{ "tickInterval":0.2 },
    "legend":{
    "verticalAlign":'top',
    "align":'right'
    },"series":objUser.experiment.data.stats
  }

/*----====|| LOGIC || ====----*/
const fnReflow=function(){ 
    if(objBarChart){ 
      objBarChart.chart.reflow(); 
      clearInterval(fnCheckBar);
    }
    if(objLineChart){ 
      objLineChart.chart.reflow(); 
      clearInterval(fnCheckLine);
    }
  }
  const fnFormatData=function(arrData){
    console.log('format it',arrData);
    var i=0; var ii=0; var intLength=arrData.length; var objStatsData=[]; var objField={}; var arrKeys=[]; 
    var objExperiment=objUser.experiment; 

    var intCool=0; var intHeat=0;
    var arrStatesData=[{
      "name":'Heating'
      ,"data":[]
      ,"color":'#FB9C25'
    },{
       "name":'Fan'
      ,"data":[]
      ,"color":'#415BDB'
    }];
    //get the columsn needed for the experiment, based on first row
    //console.log(context.experiment,arrData);
    //set up the fields
    for(i=0; i < objExperiments[objUser.experiment.type]['stats'].length; i++){
      objField=objExperiments[objUser.experiment.type]['stats'][i];
      if(arrData[0][objField.k]){
        //matched a column add the series
        objStatsData[objField.k]={"name":objField.name,"color":objField.color,"data":[],"min":null,"max":null,"total":0,"avg":0};
      }
    }
    arrKeys=Object.keys(objStatsData);
    for(i=1;i < intLength; i++){
      //for each row in the data
      for(ii=0; ii < arrKeys.length; ii++){
        //add stats data
        var intValue = parseFloat(arrData[i][arrKeys[ii]]);
        if(intValue === isNaN ){ console.log("not a number: ",arrData[i][arrKeys[ii]]); } 
        //if(context.experiment.location==='In'){ intValue = fnF2C(intValue); }
        objStatsData[arrKeys[ii]].data.push( intValue );
        if(objStatsData[arrKeys[ii]].min === null || objStatsData[arrKeys[ii]].min > intValue ){ objStatsData[arrKeys[ii]].min=intValue; }
        if(objStatsData[arrKeys[ii]].max === null || objStatsData[arrKeys[ii]].max < intValue ){ objStatsData[arrKeys[ii]].max=intValue; }
        objStatsData[arrKeys[ii]].total = objStatsData[arrKeys[ii]].total+intValue;
      }
      //add heat data
        intHeat=0;intCool=0;
        for(ii=0;ii < objExperiments.Heat.length;ii++){
          if(arrData[i][objExperiments.Heat[ii]] 
            && (arrData[i][objExperiments.Heat[ii]]==='ON' 
              || arrData[i][objExperiments.Heat[ii]]===1 
              || arrData[i][objExperiments.Heat[ii]]==='1')
            ){ intHeat++; }
        }
        //make data types acorss files consistent for this key
        if(arrData[i]['S_Fan']){ arrData[i]['Fan']=arrData[i]['S_Fan']; }
        if(arrData[i]['Fan'] && (arrData[i]['Fan']==='ON' || arrData[i]['Fan']===1 || arrData[i]['Fan']==='1' ) ){ intCool--; }
        arrStatesData[0].data.push(intHeat);
        arrStatesData[1].data.push(intCool);
    }
    //convert stats data into an array for highcharts
    console.log(objStatsData);
    objExperiment.data.stats=[];
    for(i=0;i< arrKeys.length; i++ ){ 
      objStatsData[arrKeys[i]].avg = (objStatsData[arrKeys[i]].total/objStatsData[arrKeys[i]].data.length).toFixed(2);
      objStatsData[arrKeys[i]].min = objStatsData[arrKeys[i]].min.toFixed(2);
      objStatsData[arrKeys[i]].max = objStatsData[arrKeys[i]].max.toFixed(2);
      objExperiment.data.stats.push(objStatsData[arrKeys[i]]); 
    }
    objExperiment.data.states=arrStatesData;
    objUser.fnUpdate({...objUser,experiment:objExperiment});
    notification.open({message:"Data loaded"});
    fnUpdate({...objTmp,"loaded":true});
  };
  const fnGetDataById = function(intId,strActive){
    axios.post(Config.api+'/api/result',{ "token":objUser.jwt,"d":intId }).then(objResults => {                    
        console.log('loaded results', objResults.data);
        if(objResults !== null){ 
          fnFormatData(objResults.data.data);
          fnUpdate({...objTmp,"ActiveData":strActive,"loaded":true})
        }   
      }).catch(err=>{});
  };
/*----====|| HOOKS || ====----*/
useEffect(() => {
    console.log(objUser.experiment);
    //formatted data exists
    if(objUser.experiment.data && objUser.experiment.data.stats.length > 0){ setTimeout(fnReflow,1000); }
    if(!objTmp.loaded ){
      //raw data needs to be formated
      if(objUser.experiment.data.raw && objUser.experiment.data.raw.length > 0 && objUser.experiment.data.stats.length === 0){ 
        fnUpdate({...objTmp,"ActiveData":'Uploaded'});
        fnFormatData(objUser.experiment.data.raw); 
      }
      //data needs to be loaded AND formatted
      if(objUser.experiment.results && objUser.experiment.results.length > 0){
        
        for(var i=0; i < objUser.experiment.results.length; i++){
          if(objUser.experiment.results[i].path.indexOf('_G') > 0 ){ objTmp.Ground=objUser.experiment.results[i].id; }
          else{ objTmp.Space=objUser.experiment.results[i].id; }
        }
        fnUpdate(objTmp);
        var strActive='Space';
        if( objTmp.Ground === objUser.experiment.results[objUser.experiment.results.length-1].id ){ strActive='Ground'; }
        fnGetDataById(objUser.experiment.results[objUser.experiment.results.length-1].id,strActive);
        //console.log(context.experiment.results,objTmp);
      }
    }
    // eslint-disable-next-line
  },[objUser.experiment.data.stats]);
/*----====|| RENDER || ====----*/
return(
    <ExperimentLayout history={props.history} step="analyze" next={true}>
        <HighchartsReact
          highcharts={Highcharts}
          options={lineOptions}
          ref={ e => objLineChart = e }
        />
        <HighchartsReact
          highcharts={Highcharts}
          options={barOptions}
          ref={ e => objBarChart = e }
        />
        { objUser.experiment.data.stats[0] &&
        <Card title="Data breakdown">
            
                <div className="container dataStats">
                <h3> { objUser.experiment.data.stats[0].name } sensor </h3>
                <Row>
                  <Col span={8}>
                    Minimum temperature (F)
                    <div className="int"> { objUser.experiment.data.stats[0].min } </div>
                  </Col>
                  <Col span={8}>
                    Maximum temperature (F)
                    <div className="int"> { objUser.experiment.data.stats[0].max } </div>
                  </Col>
                  <Col span={8}>
                    Average temperature (F)
                    <div className="int"> { objUser.experiment.data.stats[0].avg } </div>
                  </Col>
                </Row>
              </div><div className="container dataStats">
                <h3> { objUser.experiment.data.stats[1].name } sensor </h3>
                <Row>
                  <Col span={8}>
                    Minimum temperature (F)
                    <div className="int"> { objUser.experiment.data.stats[1].min } </div>
                  </Col>
                  <Col span={8}>
                    Maximum temperature (F)
                    <div className="int"> { objUser.experiment.data.stats[1].max } </div>
                  </Col>
                  <Col span={8}>
                    Average temperature (F)
                    <div className="int"> { objUser.experiment.data.stats[1].avg } </div>
                  </Col>
                </Row>
              </div>      
        </Card>
}
        
    </ExperimentLayout>
);

};

export default Login;