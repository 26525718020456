/*----====|| LIBRARY IMPORTS || ====----*/
import React, { useContext,useEffect,useState } from 'react';
import { Row,Col,Card } from 'antd';
import exp from '../experimentHelper';
import ExperimentLayout from '../components/experimentLayout';
import CodePreview from '../components/codePreview';
import CodeConfig from '../components/codeConfig';
import codeMap from '../configCodeMap';
import BuildBMO from '../components/buildBMO';
import BuildFF from '../components/buildFF';
import BuildFilings from '../components/buildFilings';
import BuildVideo from '../components/buildVideo';
/*----====|| CONTEXT || ====----*/
import {UserContext,ConfigContext} from '../context.js';

/*----====|| STYLE IMPORTS || ====----*/

/*----====|| INSTANCE CONSTANTS || ====----*/
//const { Content } = Layout;

/*----====|| COMPONENT START || ====----*/
const Build = (props) => {
    const objUser = useContext(UserContext);
    const objConfig = useContext(ConfigContext);
/*----====|| DEFAULTS || ====----*/

/*----====|| STATES || ====----*/
    const [objTmp, fnUpdate] = useState({
        codeMap: '',
        experimentConfig: null,
        next: false,
    });
/*----====|| LOGIC || ====----*/

const fnGetCodeMap=function(objExperiment){
    if(objExperiment.type){
        //figure out which codemap to use
        let strCodeMap;
        let strType= objExperiment.type.toLowerCase();
        if(['convection','conduction','radiation'].indexOf(strType) > -1){
            strCodeMap='heatSpace';
            if(objExperiment.location==='Ground'){ strCodeMap='heatGround'; 
        }
        }else if( strType === 'BMO'){ strCodeMap='BMO'; }
        return codeMap[strCodeMap];
    }
}
const fnSaveSteps=function(arrSteps){
    objUser.fnUpdate({
        ...objUser,
        experiment:{...objUser.experiment,steps:arrSteps}
    });
    exp.fnSave({
        ...objUser,
        experiment:{...objUser.experiment,steps:arrSteps}
    });
}

/*----====|| HOOKS || ====----*/
useEffect(() => {
    if(objUser.experiment && objConfig.experiments){
        const objExperimentConfig = exp.fnGetConfig(objUser.experiment,objConfig);
        fnUpdate({...objTmp,experimentConfig:objExperimentConfig});
    }
    // eslint-disable-next-line
},[objUser.experiment,objConfig.experiments]);

const setNextStatus = function(arrSteps){
    fnSaveSteps(arrSteps);
    // validate the steps to see if NEXT is available
    // we just need at least one complete command we should be able to handle the rest
    let ok=false;
    for(let i=0; i<arrSteps.length; i++){
        const objStep=arrSteps[i];
        if(objStep.action === 'showImage' && !objStep.detail){
            ok=false;
            break;
        }
        if(objStep.action !== 'delay'){
            ok=true;
        }
    }
    if(!ok){ console.log('not ok', arrSteps); }
    fnUpdate((prev)=>({...prev, next:ok}));
}

/*----====|| RENDER || ====----*/
return(
    <ExperimentLayout history={props.history} 
        step="build" 
        next={objTmp.next}
    >
        { objUser.experiment && objUser.experiment.type &&
        <>
        <Card title="Build experiment steps" style={{marginBottom:"12px"}}>
            {objTmp.experimentConfig &&
                <>
                    {objTmp.experimentConfig.stepCopy.build}
                </>
            }
        </Card>
        {   ['Convection','Conduction', 'Radiation'].indexOf(objUser.experiment.type) > -1 &&
            <Row>
                    <Col span={12} key="config" style={{paddingRight:"6px"}}>
                    <CodeConfig 
                        data={objUser.experiment} 
                        callback={ setNextStatus }/>
                </Col>
                <Col span={12} key="code" style={{paddingLeft:"6px"}}>
                    <CodePreview 
                        data={objUser.experiment} 
                        codeMap={fnGetCodeMap(objUser.experiment)}/>
                </Col>
            </Row>
        }{   objUser.experiment.type === 'BMO' &&
            <BuildBMO 
                data={objUser.experiment}
                callback={ setNextStatus }
            />
        }{ objUser.experiment.type === 'FF' &&
            <BuildFF
                data={objUser.experiment}
                callback={ setNextStatus }
            />
        }{ objUser.experiment.type === 'Filings' &&
            <BuildFilings
                data={objUser.experiment}
                callback={ setNextStatus }
            />
        }{ objUser.experiment.type === 'Video' &&
            <BuildVideo
                data={objUser.experiment}
                callback={ setNextStatus }
            />
            }
        </>
     }
        
    </ExperimentLayout>
);

};

export default Build;