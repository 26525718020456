import React, { useContext,useEffect,useState } from 'react';
import axios from 'axios';
import { Layout,Row, Col,Tooltip,Tag,Button, Input,List,Modal,message, Card, Switch } from 'antd';
import _ from 'lodash';
import {EditOutlined} from '@ant-design/icons';
import {Config} from '../config.js';
import Nav from '../components/nav.js';
/*----====|| CONTEXT || ====----*/
import {UserContext} from '../context.js';
const { Header,Content } = Layout;

const AdminConfig = (props) => {
	const objUser = useContext(UserContext);
	const [objTmp, fnUpdate] = useState({
		"showExperimentForm":false,
		"selectedExperiment":0,
		"changes":1
	});
	const [objConfig, setConfig] = useState({});

	const fnCrawlProperty=function(arrMap,v,objTarget,strAction,i){
		//start from the begiining of the object map
		if(typeof i === 'undefined'){
		  i=0;
		  //make some updates if this is the first time through on a dleteIfEmpty action because delete requires one less arrMap item
		  if(strAction==='deleteIfEmpty'){
			objTarget=arrMap.pop();
			strAction='delete';
		  }
		}
		//set aq default action, makes logic more readable
		if(typeof strAction === 'undefined'){ strAction='set'; }
		//reached the last positon in the map for the object, set the value
		if(arrMap.length === 0){
		  //dealing with top object, the rest of the nonsense isnt needed
		  if(strAction==='delete'){ delete objTarget[v]; }
		  return objTarget;
		}
		if(i===arrMap.length-1){ 
		  //this is the target item in the hierarchy
			let strType = typeof objTarget[arrMap[i]];
			console.log(strType,objTarget[arrMap[i]],v,strAction);
		  if(strType === 'object'){
			//if it's an array push the value
			if( Array.isArray(objTarget[arrMap[i]]) ){ 
			  switch (strAction){
				case 'push': return objTarget[arrMap[i]].push(v);
				//this expects the array to be the last map value and the value to be the index to remove
				case 'delete': return objTarget[arrMap[i]].splice(v,1);
				default: break;
			  }
			}else{
			  //object specific handling
			  switch (strAction){
				//this needs the parent property for the last map and v to be what's deleted from it
				case 'delete': delete objTarget[arrMap[i]][v]; return objTarget;
				default: break;
			  }
			}
		  }
		  //if it's a boolean toggle it or set it
		  else if(strType === 'boolean'){
			//toggle
			if( strAction === 'toggle'){ objTarget[arrMap[i]] = !objTarget[arrMap[i]];}
			return objTarget;
		  }
		  //or simply set the value given and return the object
		  if(strAction==='set'){ objTarget[arrMap[i]]=v; } 
		  return objTarget;
		}else{
		  //if a property doesnt exist,not an end property, create it as an object by default
		  if(typeof objTarget[arrMap[i]] === 'undefined'){ objTarget[arrMap[i]]={}; }
		  //object found, return is and advance the index of the map
		  else{ objTarget=objTarget[arrMap[i]]; }
		  i++;
		  return fnCrawlProperty(arrMap,v,objTarget,strAction,i); 
		}
	  }

	  
	  const fnUpdateConfig=function(arrMap,v,strAction,fnCallBack){
		if(!strAction){ strAction='set'; }
		if(typeof v === 'undefined'){ v={}; }
		fnCrawlProperty(arrMap,v,objConfig,strAction);
		//updating a top level property helps refresh of things
		console.log(objConfig);
		setConfig(objConfig);
		objTmp.changes++;
		fnUpdate({...objTmp});
		if(typeof fnCallBack === 'function'){ fnCallBack(objConfig); }
	  };
	  /**
		 * dont know if value will be an ovject or a single value, so need to reutrn based on which
		 * simply call with the top level value to start the recursive rows { fnFormatValue(v) }
		 * @param {*} v 
		 * @objConfig {object} define any overrides of rendering for keys, values
		 * row, key, value
		 */
		const fnRecursiveRender=function(v){
		  if(v){
			let strType=typeof v;
			switch(strType){
			  //if it's an object send back rows of key value pairs
			  case 'object':
				let arrKeys=Object.keys(v);
				var arrOut=[];
				//recursively iuse this function on values in case they are also objects
				for(let i=0;i<arrKeys.length;i++){
				  let jsxOut=(
					<tr style={{"border":'1px solid #999'}}>
					  <td>
						{arrKeys[i]}
					  </td>
					  <td>
						  { /*  eslint-disable-next-line */ }
						{fnRecursiveRender(v[arrKeys[i]])}
					  </td>
					</tr>
				  );
				  arrOut.push(jsxOut);
				}
				return (
					<table style={{"width":'100%'}}>
						{arrOut}
					</table>
				);
			  //return tooltipped strings in case it went so many levels that the available width got narrow
			  default: return (
				<Tooltip title={v}> <span>{v}</span> </Tooltip>
			  );
			}
		  }else{ return 'no value';}
		}

const fnSaveConfig=function(){

	axios.post(Config.api+'/api/configUpdate',{"token":objUser.jwt,"d":{...objConfig}})
		    .then(objResults => {        	          
		      message.info('Config updated');
			}).catch(err=>{});

}
const fnRestoreConfig=function(){
	axios.post(Config.api+'/api/configRestore',{"token":objUser.jwt})
		    .then(objResults => {        	          
		      message.info('Config restored');
		    }).catch(err=>{});
}

const fnGetConfig=function(){
	axios.get('/config.json')
        .then(objResponse => {        
		  setConfig( objResponse.data);
		  
        }).catch(err=>{});
}

	useEffect(() => {
	    fnGetConfig();
 	},[]);

  return (
	<Layout style={{height:"100%",overflowY:"hidden"}}>
	<Header>
	  <Nav/>
	</Header>
	  <Content>
		  { objConfig.experiments &&
			<>
			<Row>
				<Col span={24} align="right">
					<Button onClick={fnRestoreConfig}>Restore Previous Config</Button>
					<Button onClick={fnGetConfig}>Reset Changes</Button>
					<Button onClick={fnSaveConfig}>Save</Button>
				</Col>
			</Row>
			<Card size="small" title="experiments" >
			<List 
				dataSource={objConfig.experiments}
				renderItem={ (objExperiment)=>(
					<Row style={{"padding":'6px'}}>
						<Col span={10}> 
							<Switch defaultChecked={objExperiment.active} onClick={ ()=>{
								let intK=_.findIndex(objConfig.experiments,{"key":objExperiment.key});
								fnUpdateConfig(['experiments',intK,'active'],undefined,'toggle');
							}}/> {objExperiment.title}
						</Col>
						<Col span={10}>
							<List 
								dataSource={objExperiment.locations}
								renderItem={ (strLocation)=>(
									<Tag>{strLocation}</Tag>
								)}
							/>
						</Col>
						<Col span={4}>
							<Button onClick={ ()=>{ 
									let intK=_.findIndex(objConfig.experiments,{"key":objExperiment.key})
									fnUpdate({...objTmp,"showExperimentForm":true, "selectedExperiment":intK}) } }> <EditOutlined 
								
							/> </Button>
						</Col>
					</Row>
				)}
			/>
		  </Card>
		  <Card size="small" title="steps">
			<Row className="headRow">
				<Col span={2}>Step</Col>
				<Col span={2}>Path</Col>
				<Col span={4}>Label</Col>
				<Col span={16}>Description</Col>
			</Row>
			<List 
				dataSource={Object.keys(objConfig.steps)}
				renderItem={ (strStep)=>(
					<Row>
						<Col span={2}>{strStep}</Col>
						<Col span={2}>{objConfig.steps[strStep].path}</Col>
						<Col span={4}>
							<Input defaultValue={objConfig.steps[strStep].label} 
								onClick={ (e)=>{fnUpdateConfig(['steps',strStep,'label'],e.target.value,'set')} } />
						</Col>
						<Col span={12}>
							<Input defaultValue={objConfig.steps[strStep].desc} 
								onClick={ (e)=>{fnUpdateConfig(['steps',strStep,'desc'],e.target.value,'set')} } />
						</Col>
						<Col span={4}></Col>
					</Row>
				)}
			/>
		  </Card>
		  <Card size="small" title="locations">
			<Row className="headRow">
				<Col span={4}>
					Location
				</Col>
				<Col span={4}>
					Label
				</Col>
				<Col span={16}>
					Description
				</Col>
			</Row>
		  	<List 
				dataSource={objConfig.locations}
				renderItem={ (objLocation)=>(
					<Row>
						<Col span={4}>{objLocation.key}</Col>

						<Col span={4}>
							<Input defaultValue={objLocation.title} 
								onClick={ (e)=>{
									let intK=_.findIndex({"key":objLocation.key})
									fnUpdateConfig(['locations',intK,'title'],e.target.value,'set')} 
								} />
						</Col>

						<Col span={16}>
							<Input defaultValue={objLocation.desc} 
								onClick={ (e)=>{
									let intK=_.findIndex({"key":objLocation.key})
									fnUpdateConfig(['locations',intK,'desc'],e.target.value,'set')} 
								} />
						</Col>
					</Row>
				)}
			/>
		  </Card>
		  <Card size="small" title="badges">
			<Row className="headRow">
					<Col span={4}>
						Badge
					</Col>
					<Col span={4}>
						Label
					</Col>
					<Col span={16}>
						Description
					</Col>
				</Row>
			  <List 
				dataSource={objConfig.badges}
				renderItem={ (objBadge)=>(
					<Row>
						<Col span={4}>
							{objBadge.id}
						</Col>
						<Col span={4}>
							<Input defaultValue={objBadge.title} 
								onClick={ (e)=>{
									let intK=_.findIndex({"id":objBadge.id})
									fnUpdateConfig(['badges',intK,'title'],e.target.value,'set')} 
								} />
						</Col>
						<Col span={16}>
							<Input defaultValue={objBadge.desc} 
								onClick={ (e)=>{
									let intK=_.findIndex({"id":objBadge.id})
									fnUpdateConfig(['badges',intK,'desc'],e.target.value,'set')} 
								} />
						</Col>
					</Row>
				)}
			/>
		  </Card>
			</>
		  }
		{ objConfig.experiments &&
		<Modal onCancel={ ()=>{ fnUpdate({...objTmp,"showExperimentForm":false}) } } title={'Edit Experiment: '+objConfig.experiments[objTmp.selectedExperiment].key} visible={objTmp.showExperimentForm} width="90%">
		  <Row>
			  <Col span={4}>
				  title
			  </Col>
		  	  <Col span={20}>
				<Input defaultValue={objConfig.experiments[objTmp.selectedExperiment].title} 
					onClick={ (e)=>{
						fnUpdateConfig(['experiments',objTmp.selectedExperiment,'title'],e.target.value,'set')} 
					} />
			  </Col>
		  </Row>
		  <Row>
			  <Col span={4}>
				  description
			  </Col>
		  	  <Col span={20}>
				<Input defaultValue={objConfig.experiments[objTmp.selectedExperiment].desc} 
					onClick={ (e)=>{
						fnUpdateConfig(['experiments',objTmp.selectedExperiment,'desc'],e.target.value,'set')} 
					} />
			  </Col>
		  </Row>
		  <Row>
			  <Col span={4}>
				  locations
			  </Col>
		  	  <Col span={4}>
				{ objConfig.locations && objConfig.locations.map((objLocation,intLocation)=>(
					<Switch checkedChildren={objLocation.key} unCheckedChildren={objLocation.key} defaultChecked={
						objConfig.experiments[objTmp.selectedExperiment].locations.indexOf(objLocation.key) > -1
					} onChange={ ()=>{
						let intK=objConfig.experiments[objTmp.selectedExperiment].locations.indexOf(objLocation.key);
						if( intK > -1){
							fnUpdateConfig(['experiments',objTmp.selectedExperiment,'locations'],intK,'delete');
						}else{
							fnUpdateConfig(['experiments',objTmp.selectedExperiment,'locations'],objLocation.key,'push');
						}
					} }
					></Switch>
				))}
			  </Col>
		  </Row>
		  <Card title="sequence" size="small" >
		  	{ /*objConfig.experiments && Object.keys(objConfig.experiments[objTmp.selectedExperiment].sequence).map( (strSequence)=>(
				<Card size="small" title={strSequence} >
					{ objConfig.experiments[objTmp.selectedExperiment].sequence[strSequence].map( (objPhase,intPhase)=>(
						<Card size="small" extra={ <Button onClick={
							()=>{ fnUpdateConfig(['experiments',objTmp.selectedExperiment,'sequence',strSequence],intPhase,'delete') }
						}> <DeleteOutlined /> </Button> }>
							<Row>
								<Col span={6}>phase name</Col>
								<Col span={18}>
									<Input defaultValue={objPhase.name} 
									onClick={ (e)=>{
										fnUpdateConfig(['experiments',objTmp.selectedExperiment,'sequence',strSequence,intPhase,'name'],e.target.value,'set')} 
									} />
								</Col>
							</Row>
							<Card size="small" title="steps" extra={ <Button onClick={ ()=>{ fnUpdateConfig(['experiments',objTmp.selectedExperiment,'sequence',strSequence,intPhase,'steps'],'start','push') } }>Add Step</Button> }>
								{ objPhase.steps.map( (strStep,intStep)=>(
									<Row style={{"width":'100%'}}>
										<Col span={12}>
											<Select defaultValue={strStep} onChange={ (v)=>{ 
												fnUpdateConfig(['experiments',objTmp.selectedExperiment,'sequence',strSequence,intPhase,'steps',intStep],v,'set') 
											}}>
												{ Object.keys(objConfig.steps).map( (strV)=>(
													<Option key={strV}>{strV}</Option>
												))}
											</Select>
										</Col>
										<Col span={12} align="right">
											<Button onClick={
												()=>{
													fnUpdateConfig(['experiments',objTmp.selectedExperiment,'sequence',strSequence,intPhase,'steps'],intStep,'delete');
												}
											}> <DeleteOutlined/> </Button>
										</Col>
									</Row>
								))}
							</Card>
						</Card>
					))}
				</Card>
										))*/}
		  </Card>
		</Modal>
		}
      </Content>
	  </Layout>
    );
};

export default AdminConfig;