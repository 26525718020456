import { LogLevel } from "@azure/msal-browser";
import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const Config={ 
    //"api":'https://kit.questforspace.com', 
    "api":'',    
}

export const SSO={
    teams:{
        tenant: '728e9b53-3cfc-4d2a-85d2-a8638dde64de',
        clientId: '8d575674-16d4-4c01-a5ab-d9e97c6eb0cc',
        endpoints: {
            api: 'https://kit.questforspace.com:443' // <-- The Azure AD-protected API
        },
        cacheLocation: 'localStorage',
    },
    auth: {
        clientId: "8d575674-16d4-4c01-a5ab-d9e97c6eb0cc",
        authority: "https://login.microsoftonline.com/common/",
        redirectUri: Config.api + "/auth"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }	
                if(level === LogLevel.Error){ 
                    console.error(message);		
                    return; }
            }	
        }	
    }
};

export const authContext = new AuthenticationContext(SSO.teams);

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, (SSO.teams.endpoints.api, fetch, url, options));

export const withAdalLoginApi = withAdalLogin(authContext, (SSO.teams.endpoints.api));
